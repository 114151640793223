.nav {
    display: block;
    list-style: none;
    .nav-item { list-style-type: none; }

    // Horizontal menu
    &.nav-menu-h {
        
        .nav-item {
            display: inline-block;
            
            &:not(:last-child) { padding-right: 10px; }
        }

        .nav-link {
            background-color: $color-grey-light-2;
            padding: 3px 10px;

            &:hover,
            &:focus {
                background-color: $color-primary-light;
                color: $color-white;
            }
        }
    }

    &.nav-menu-v {
        padding: 25px 35px;
        background-color: $color-grey-light-3;
        font-size: 20px;

        .heading {
            margin: 0 0 5px 0;
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .nav-link {
            padding: 5px 15px;
            transition: padding-left .25s ease-in-out 0s;

            &:hover,
            &:focus { padding-left: 15px + 15px; }

            &.active {
                color: $color-primary-light;
                font-weight: 700;
            }
        }
    }
}