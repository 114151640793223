.toolbar-magazine {
    border-bottom: 1px solid $color-grey-dark-1;
    
    @include media-breakpoint-down(md) {
        display: none;
    }
    .toolbar {
        display: flex;
        justify-content: space-between;
        
        p {
            display: inline-block;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            
            &.no-image {
                padding-top: 3px;
            }
            .inactive {
                opacity: 0.4;
                cursor: inherit;
            }
        }
    }
}