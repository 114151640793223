ul {
    
    &#menu-in-list {
        
        background-color: #e4e4e4;
        padding: 15px 30px;
        margin-bottom: 30px;
        
        .category {
            color:  #212121;
            font-size: 20px;
            line-height: 22px;
            text-transform: uppercase;
            font-weight: 700;
            text-align: left;
            margin-bottom: .6rem;
        }
        
        li {
            width: 100%;
            padding: 0px 20px;
            font-size: 18px;
            line-height: 35px;
            
            &.current {
                
                a {
                    color: #ffa000;
                    font-weight: 700;
                }
            }
        }
    }
}