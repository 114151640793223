.gallery {

    .title-wrapper {
        position: relative;
        margin-bottom: 20px;

        .btn {
            position: absolute;
            top: 50%;
            right: 0;
            padding: 6px 10px;
            font-size: 12px;

            transform: translateY(-50%);
        }
    }

    figure {
        overflow-x: hidden;

        figcaption {
            margin-top: 20px;
            font-size: 13px;
            line-height: 20px;
        }
    }

    .btn.btn-wide {
        padding: 6px;
        font-size: 12px;
    }
}