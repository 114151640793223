footer {
    position: relative;
    padding: 60px 0 10px;
    background: #303136;
    line-height: 1;
    z-index: 2001;

    .title {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
    }

    .shortcuts {
        order: 2;
        @include media-breakpoint-up(sm) { order: 1; }
    }
    .our-services {
        order: 1;
        @include media-breakpoint-up(sm) { order: 2; }
    }

    // Fix class collision (remove Bootstrap4 dependencies)
    .force-float {
        .row {
            display: block;
            flex-wrap: unset;
        }

        .col-1,
        .col-2,
        .col-3 {
            flex: none;
            max-width: none;
        }
    }

    .nav {
        display: unset;
        flex-wrap: unset;
    }

    .pull-left  { float: left !important; }
    .pull-right { float: right !important; }

    // Fix class collision - End

    .mini-title {
        font-size: 14px;
        text-transform: uppercase;
        margin-top: 30px;
        color: #fff;
        cursor: pointer;
        display: block; // Changed from inline-block due to float property
        float: left;
        clear: both;
    }

    ul.menu-right, .ul-list ul {
        display: block;
        list-style: none;
        margin-top: 30px;
        margin-bottom: 0;
        padding: 0;
        overflow: hidden;

        &.hide-list {
            display: none;
        }

        li {
            + li {
                margin-top: 10px;
            }

            a {
                display: inline;
                padding: 0;
                color: #ababab;
                font-size: 14px;

                transition: color .25s ease;

                &:hover, &:focus {
                    color: #d4d4d5;
                    text-decoration: none;
                    background-color: transparent;
                }
            }
        }
    }

    .ul-list ul {
        padding-left: 15px;
        padding-right: 15px;

        &.col-2, &.col-3 {
            margin-top: 10px;
        }

        li {
            span {
                display: block;
                margin: 30px 0;
                font-size: 14px;
                text-transform: uppercase;
                color: #ffffff;
            }

            &:first-child {
                span {
                    margin-top: 0;
                }
            }
        }
    }

    .footer-line {
        border-top: 3px solid #494a4e;
        padding: 31px 0 0;
        margin-top: 85px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        &__right {
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #6B6969;
            margin-bottom: 10px;
            @media(max-width: 500px) {
                max-width: 100%;
                flex: 0 0 100%;
                justify-content: center;
            }
            &-shop {
                margin-right: 30px;
                color: #AAAAAA;
            }
            &-copyrights {
                a {
                    color: #AAAAAA;
                }
            }
        }
    }

    .col-xs-12.pull-left {
        margin-top: 30px;
    }

    .social {
        a {
            display: block;
            float: left;
            width: 26px;
            height: 26px;
            margin: 0 0 0 6px;
            border: 1px solid #ababab;
            color: #ababab;
            border-radius: 2px;
            text-align: center;
            font-size: 16px;
            overflow: hidden;
            padding: 4px;

            i { color: inherit; }

            &:hover, &:focus {
                .fab {
                    color: #d4d4d5;
                    transform: rotate(360deg);
                }
            }

            .fab {
                color: #ababab;
                line-height: 24px;
            }
        }
    }

    .footer-logo {
        display: block;
        width: 98px;
        height: 36px;
        margin: 0 0 10px;
        background: url("/templates/main/images/sprite.png") -32px 0 no-repeat;
    }

    /* Tablet-up */
    @include media-breakpoint-up(md) {
        padding: 60px 0 10px;

        .ul-list ul {
            width: 33.33333333%;
            float: left;

            &.col-2, &.col-3 {
                margin-top: 30px;
            }
        }

        .footer-line {
            padding: 23px 0 35px;
        }

        .col-xs-12.pull-left {
            margin-top: 0;
        }

        .footer-logo {
            float: left;
        }
    }

    /* Desktop-up */
    @include media-breakpoint-up(lg) {

        padding: 72px 0 10px;

        .footer-line {
            margin-top: 105px;
        }
    }
}

// Our Sites
.our-sites {
    margin-top: 30px;

    .page-group { margin-bottom: 30px; }

    .group-title {
        margin-bottom: 30px;
        color: $color-white;
        font-size: 14px;
        line-height: 1.4;
        text-transform: uppercase;
    }

    .group-list {
        padding: 0;
        list-style: none;
        list-style-type: none;
    }

    .group-item {
        padding-top: 5px;
        padding-bottom: 5px;

        &:first-child { padding-top: 0px; }
    }

    .group-link {
        color: $color-grey-light-4;
        font-size: 14px;

        &:hover { color: lighten($color-grey-light-4, 15%); }
    }

}
