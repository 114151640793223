.gallery-page {
    margin-top: -2px;
    
    .page-info {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 6;
        font-size: 16px;
        font-weight: bold;
        
        @include media-breakpoint-up(md) {
            font-size: 26px;
        }
        
        .close-page {
            display: block;
            width: 60px;
            height: 60px;
            background: #ebebeb;
            position: relative;
            
            @include media-breakpoint-up(md) {
                width: 100px;
                height: 100px;
                background: rgba(235, 235, 235, 0.5);
            }
            
            &:before, &:after {
                display: block;
                content: "";
                width: 50px;
                height: 5px;
                background: #000;
                position: absolute;
                top: 28px;
                left: 9px;
                border-radius: 5px;
                
                @include media-breakpoint-up(md) {
                    left: 30px;
                    top: 49px;
                }
            }
            
            &:before {
                transform: rotate(-45deg);
            }
            &:after {
                transform: rotate(45deg);
            }
        }
        
        .count {
            text-align: center;
            padding: 10px 0 0;
            background: rgba(255, 255, 255, 0.3);
            font-size: 20px;
        }
    }
    
    h1 {
        margin-top: 90px;
        font-size: 18px;
        line-height: 1.2;
        padding: 0;
        border: 0;
        
        @include media-breakpoint-up(md) {
            margin-top: 36px;
            font-size: 26px;
            padding-right: 160px;
        }
        
        @include media-breakpoint-up(lg) {
            margin-top: 28px;
            font-size: 38px;
        }
    }
    
    .current-image {
        margin-top: 30px;
        margin-bottom: 16px;
        border-bottom: 1px solid #d6d6d6;
        border-top: 1px solid #d6d6d6;
        padding: 40px 0 22px;
        text-align: center;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 28px;
        }
        
        @include media-breakpoint-up(lg) {
            margin-top: 40px;
        }
        
        img {
            margin-left: auto;
            margin-right: auto;
        }
        
        .title {
            font-size: 16px;
            margin-top: 24px;
            line-height: 1.2;
            
            @include media-breakpoint-up(md) {
                font-size: 16px;
                margin-top: 24px;
            }
        }
        .source {
            font-size: 16px;
            margin-top:10px;
            font-style: italic;
            line-height: 1.2;
        }
    }
    
    .prev-img, .next-img {
        display: block;
        width: 60px;
        height: 60px;
        text-decoration: none;
        @include clearfix;
        
        @include media-breakpoint-up(md) {
            position: fixed;
            top: 50%;
            margin-top: -50px;
            width: 100px;
            height: 100px;
            z-index: 5;
        }
        
        span {
            display: block;
            position: relative;
            width: 60px;
            height: 60px;
            padding: 0;
            background: #ebebeb;
            color: #000;
            text-indent: 0;

            &:before {
                position: absolute;
                left: 23px;
                top: 50%;
                display: block;
                width: 14px;
                height: 26px;
                margin-top: -13px;
                font-family: 'FontAwesome';
                font-size: 60px;
                line-height: 25px;
                text-align: center;
                transition: 0.3s;
            }
            
            @include media-breakpoint-up(md) {
                width: 100px;
                height: 100px;
                margin: 0;
                background: rgba(235, 235, 235, 0.5);

                &:before, &:after {
                    left: 43px;
                }
            }
        }
        
        &.disabled {
            opacity: 0.2;
            cursor: default;
            
            :before {
                font-size: 48px;
            }
        }
    }
    .prev-img {
        float: left;
        
        @include media-breakpoint-up(md) {
            left: 0;
        }
        
        span {
            float: left;
            
            &:before {
                content: "\f104";
            }
        }
        
        &:hover:not(.disabled) {
            span {
                &:before {
                    left: 18px;
                    
                    @include media-breakpoint-up(md) {
                        left: 38px;
                    }
                }
            }
        }
    }
    .next-img {
        float: right;
        
        @include media-breakpoint-up(md) {
            right: 0;
        }
        
        span {
            float: right;
            
            &:before {
                content: "\f105";
            }
        }
        
        &:hover:not(.disabled) {
            span {
                &:before {
                    left: 28px;
                    
                    @include media-breakpoint-up(md) {
                        left: 48px;
                    }
                }
            }
        }
    }
    
    .share-buttons {
        margin-top: 28px;
        text-align: center;
        
        @include media-breakpoint-up(md) {
            margin-top: 10px;
            text-align: left;
        }
        
        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
    }
    
    .download-raw {
        margin: 28px auto 0;
        display: block;
        position: relative;
        width: 172px;
        height: 48px;
        box-shadow: inset 0 0 0 1px #d6d6d6;
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        color: #333;
        line-height: 48px;
        
        @include media-breakpoint-up(md) {
            float: right;
            margin-top: 10px;
        }
        
        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
        
        .ico {
            display: inline-block;
            position: relative;
            width: 19px;
            height: 24px;
            margin-left: 19px;
            margin-top: -3px;
            vertical-align: middle;
            
            &:before, &:after {
                display: block;
                position: absolute;
                content: '';
                width: 19px;
                height: 24px;
                background: url("../../images/sprite.png") -165px -42px no-repeat;
                transition: 0.5s;
            }
            &:before {
                z-index: 2;
            }
            &:after {
                z-index: 1;
                background-position: -165px -69px;
                opacity: 0;
            }
        }
        
        .bd {
            display: block;
            position: absolute;
            background: #333;
            z-index: 1;
            transition: 0.5s;
            
            &.bt {
                top: 0;
                left: 0;
                height: 1px;
                width: 0;
            }
            
            &.br {
                top: 0;
                right: 0;
                width: 1px;
                height: 0;
            }
            
            &.bb {
                bottom: 0;
                right: 0;
                height: 1px;
                width: 0;
            }
            
            &.bl {
                bottom: 0;
                left: 0;
                width: 1px;
                height: 0;
            }
        }
        
        &:hover {
            .ico {
                &:before {
                    opacity: 0;
                }
                &:after {
                    opacity: 1;
                }
            }
            .bd {
                &.bt, &.bb {
                    width: 100%;
                }
                
                &.br, &.bl {
                    height: 100%;
                }
            }
        }
    }
    
    .image-list {
        margin-top: 20px;
        
        @include media-breakpoint-up(md) {
            margin-top: 38px;
        }
        
        @include media-breakpoint-up(lg) {
            margin-top: 50px;
        }
        
        .row {
            > div {
                margin-bottom: 20px;
                padding: 0 10px;
                
                &:nth-of-type(2n+1) {
                    clear: left;
                }
                
                @include media-breakpoint-up(md) {
                    &:nth-of-type(2n+1) {
                        clear: none;
                    }
                    
                    &:nth-of-type(3n+1) {
                        clear: left;
                    }
                }
                
                @include media-breakpoint-up(lg) {
                    &:nth-of-type(3n+1) {
                        clear: none;
                    }

                    &:nth-of-type(4n+1) {
                        clear: left;
                    }
                }
                
                a {
                    display: block;
                    position: relative;
                    
                    img {
                        transition: 0.3s;
                    }
                    
                    &:after {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;
                        content: '';
                        width: 100%;
                        height: 100%;
                        /*background: rgba(0, 0, 0, 0.2);*/
                        transition: 0.3s;
                    }
                    
                    &:hover, &.active {
                        
                        img {
                            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                            filter: grayscale(100%);
                            opacity: 0.8;
                        }
                        
                    }
                }
            }
        }
    }
}