.ads-bg {
    position: relative;
    padding: 62px 0;
    text-align: center;
    &:after {
        z-index: -1;
        content: "";
        pointer-events: none;
        top: 0;
        left: 15px;
        right: 15px;
        bottom: 0;
        position: absolute;
        border: 1px solid rgba(0,0,0,0.2);
        background: -moz-linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,128,128,0) 100%); /* ff3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0,128,128,0)), color-stop(100%, rgba(0,0,0,0.3))); /* safari4+,chrome */
        background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,128,128,0) 100%); /* safari5.1+,chrome10+ */
        background: -o-linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,128,128,0) 100%); /* opera 11.10+ */
        background: -ms-linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,128,128,0) 100%); /* ie10+ */
        background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,128,128,0) 100%); /* w3c */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008080', endColorstr='#000000',GradientType=0 ); /* ie6-9 */
    }
}
