.dropdown {
    font-family: inherit;

    // Toggle icons
    .toggle {
        &-bars::before {
            @extend .fa;
            @extend .fa-bars;
        }
    }

    // Change toggles when dropdown is active
    &.show { 
        & .btn-secondary {
            background-color: $color-black;
        }
        
        & .toggle {
            &-bars::before { @extend .fa-caret-up; }
        }
    }

    &-menu {
        width: 100%;
        margin: 0;
        padding: 20px 75px;
        background-color: lighten($color-black, 90%);
        border: none;
    }

    &-item {
        padding: 0;
    }

    &-link {
        display: block;
        width: 100%;
        padding: 4px 24px;
        clear: both;
        white-space: nowrap;
        border: 0;
    }
}

.dropdown1 {
    $border-width: 2px;

    border: $border-width solid $color-primary-light;
    background-color: $color-white;
        
    &:hover { cursor: pointer; }

    &.open {
        border-color: $color-black;

        & .dropdown-options { max-height: 500px; }
    }
    
    .dropdown-active { text-align: center; }
    
    .dropdown-options {
        max-height: 0;
        overflow: hidden;
        transition: max-height .25s ease-in 0s;
    }

    .dropdown-link {
        @extend .link1;

        padding: 15px 25px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }

    // Custom styles
    &-section {

        &.open {
            .dropdown-active .dropdown-link:after { @extend .fa-caret-up::before; }
        }

        .dropdown-active .dropdown-link:after {
            @extend .fa;
            @extend .fa-caret-down::before;
            margin-left: 20px;
        }

        .dropdown-link { padding: 14px 50px; }
    }
}