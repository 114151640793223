.form-contact {
    background-color: #e5e5e5;
    padding: 25px 30px 0;
    
    form {
        text-align: left;
        
        label {
            margin: 11px 0;
            color: #333;
            font-size: 14px;
            font-weight: 300;
        }

        input {
            height: 43px;
            width: 100%;
            margin-bottom: 20px;
            padding: 10px;

            color: #333;
            background-color: $color-white;
            border: 1px solid #b7b7b7;
        }

        .text {
            border: 1px solid #b7b7b7;
            border-radius: 0;
        }

        textarea {
            height: 175px;
            width: 100%;
            margin-bottom: 20px;
            padding: 10px;

            color: #333;
            background-color: $color-white;
        }

        #msg {
            resize: none;
        }

        .captcha {
            width: 100%;
            border-radius: 3px;
            margin: 0 0 20px;
        }

        input[type="submit"] {
            height: 72px;
            background: #ffa000;
            color: #fff;
            border-radius: 0;
            width: 100%;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
            border: none;

            transition: $transition-base;

            &:hover { background: darken($color-primary-light, 10%); }
        }
        
        .input-name {
            font-size: 15px;
            line-height: 21px;
        }
    }

}