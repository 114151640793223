.newsletter {
    input, select{
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    #news-input {
        width: 100%;
        height: 100%;
        padding: 0 15px;
        border: 1px solid rgba(0,0,0,0.2);
        box-shadow: 0 25px 35px #ccc;
        max-width: 500px;
        height: 50px;
        margin: 20px 0 40px;
    }
    #interests-wrap {
        position: relative;
        .ms-drop.bottom {
            display: none;
        }
        &.open {
            .ms-drop.bottom {
                display: block;
            }
        }
        .title {
            height: 35px;
            width: 100%;
            border: 1px solid rgba(0,0,0,0.2);
            font-size: 16px;
            line-height: 33px;
            text-align: left;
            position: relative;

        }
        .ms-choice {
            display: none;
        }
        .ms-no-results {
            display: none;
        }
        .ms-parent {
            width: 100%;
            position: absolute;
            top: 35px;
            background: $color-white;
            z-index: 9;
            margin: 0;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                padding: 5px;
                border: 1px solid rgba(0,0,0,0.2);
                font-size: 15px;
                li {
                    padding-bottom: 5px;
                    &:nth-last-child(2) {
                        padding-bottom: 0;
                    }
                }
                input {
                    margin-right: 5px;
                    display: inline-block;
                    vertical-align: middle;
                }
                span {
                    vertical-align: middle;
                }
                label {
                    margin: 0;
                }
            }
        }
    }
    #newsletter-title {
       max-width: 660px;
       margin: 0 auto 30px;
       text-align: center;
    }
    .select-box {
        margin-bottom: 30px;
        position: relative;
        option {
            margin: 0;
            padding: 0;
            outline: none;
            border: none;
        }
        &:before {
            content: "";
            z-index: 8;
            position: absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            border: 1px solid rgba(0,0,0,0.2);
            pointer-events: none;
            background-color: transparent;
        }
        &:after {
            @extend .fa;
            content: "\f078";
            position: absolute;
            font-size: 12px;
            color: #7a7a7a;
            top: 0;
            bottom: 0;
            right: 0;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-white;
            vertical-align: middle;
            pointer-events: none;
        }
        select {
           height: 35px;
           width: 100%;
           outline: none;
           border: none;
           padding-left: 5px;
        }
    }
    .select-wrap {
       padding: 0 15px;
       @media(min-width: 768px) {
           padding: 0;
       }
       @media(min-width: 1025px){
           padding: 0 45px;
       }
    }
    #newsletter-list {
       margin: 0;
       padding: 0;
       list-style: none;
       font-size: 15px;
       line-height: 22px;
       li {
           display: inline;
       }
    }
    #additional-info {
       text-align: center;
       font-size: 14px;
       margin-top: 30px;
       padding: 0 15px;
       @media(min-width: 768px) {
           padding: 0;
       }
       @media(min-width: 1025px){
           padding: 0 45px;
       }
    }
    #newsletter-box {
       .prezent {
           margin-top: 0;
           margin-bottom: 30px;
       }
    }
    #button-submit-wrap {
       padding: 0 30px;
       margin-bottom: 30px;
       .submit {
           margin: 0;
       }
    }
    .e-wydania.mt {
       padding-bottom: 60px;
    }
    .avt-info {
       font-size: 14px;
       line-height: 22px;
       margin-bottom: 30px;
       padding: 0 15px;
       @media(min-width: 768px) {
           padding: 0;
       }
       @media(min-width: 1025px){
           padding: 0 45px;
       }
       .intro-text {

           display: inline;
       }
       #button-text {
           cursor: pointer;
           font-weight: 700;

           display: inline;
           &.open {

               display: block;
           }
       }
       #full-text {
           display: none;

           &.open {

               display: inline;
           }
       }
    }

    .newsletter-order {
        text-align: center;
    }
}
div.newsletter {
    input {
        padding: 0 10px;
    }
    background-color: #fff;
    margin-bottom: 75px;

    .top-block {
        padding: 45px 0 30px 0;

        .title {
            text-transform: uppercase;

            font-weight: 900;
            font-size: 16px;
            text-align: center;
        }
    }
    .top-banner {
        background-color: #333;
        color: #fff;
        height: 64px;
        span {
            display: table;
            margin: 0 auto;
            text-transform: uppercase;

            font-weight: 300;
            font-size: 20px;
            padding: 18px 0;
            img {
                width: 110px;
            }
        }
    }
    .newsletter-order {
        text-align: center;
        padding: 0 10px;
        margin-top: 30px;
        h1 {
            margin-bottom: 15px;
        }
        p {
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            margin: 20px 0 25px 0;
        }
        .emailbox {
            height: 50px;
            padding: 0;
            margin: 20px 0 40px 0;
            input {
                width: 100%;
                height: 100%;
                padding: 0 15px;
                border: 1px solid $color-grey-light-1;
                box-shadow: 0 25px 35px #ccc;
            }

        }
    }
    .e-wydania {
        img.cover {
            height: 297px;
            display: table;
            margin: 0 auto 30px;
            box-shadow: 0 25px 35px #ccc;
        }
        .submit{
            border: none;
            margin: 35px 10px 0 10px;
            height: 64px;
            width: 95%;
            background-color: $color-primary-light;
            color: #fff;

            font-weight: 600;
            text-transform: uppercase;
            box-shadow: 0 25px 35px #ccc;

            &:hover {
                background-color: $color-primary-light;
            }
        }
        div.regulamin {
            font-size: 10px;
            padding: 0 10px;
            margin: 35px 0 45px 0;
            color: #838383;
        }
        div.title{
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 40px;
            padding: 0 10px;
        }
        div.labels {
            .label {
                font-weight: 400;
                font-size: 14px;

                strong {
                    font-weight: 600;
                }
            }
            div.prezent {
                margin: 20px 0;
                [type="checkbox"].checkbox:not(:checked), [type="checkbox"].checkbox:checked {
                    position: absolute;
                    left: -9999px;
                }
                [type="checkbox"].checkbox:not(:checked) + label,
                [type="checkbox"].checkbox:checked + label {
                  position: relative;
                  padding-left: 45px;
                  @media(max-width: 575.98px){
                      padding-right: 15px;
                  }
                  cursor: pointer;
                }

                /* checkbox aspect */
                [type="checkbox"].checkbox:not(:checked) + label:before,
                [type="checkbox"].checkbox:checked + label:before {
                  content: '';
                  position: absolute;
                  left: 0; top: 2px;
                  width: 34px;
                  height: 34px;
                  border: 1px solid #b6b6b6;
                  background: #fff;
                }
                /* checked mark aspect */
                [type="checkbox"].checkbox:not(:checked) + label:after,
                [type="checkbox"].checkbox:checked + label:after {
                  content: '\f00c';
                  font-family: 'FontAwesome';
                  position: absolute;
                  top: 10px; left: 6px;
                  font-size: 24px;
                  line-height: 0.8;
                  color: #000;
                  transition: all .2s;
                }
                /* checked mark aspect changes */
                [type="checkbox"].checkbox:not(:checked) + label:after {
                  opacity: 0;
                  transform: scale(0);
                }
                [type="checkbox"].checkbox:checked + label:after {
                  opacity: 1;
                  transform: scale(1);
                }
            }
            div.input-cell {
                padding: 3px 10px;
                label {
                    width: 100%;
                    font-size: 16px;
                }
                input {
                    //margin-right: 30px;
                    float: none;
                    height: 33px;
                    border: 1px solid #b6b6b6;
                    width: 100%;
                }
            }

        }
    }
    .unsubscribe{
        padding-top: 20px;
        .no-padding {
            padding: 0;
        }
        .unsubscribe-form{
            input {
                height: 48px;
                border: 1px solid $color-grey-light-1;
                float: left;
                width: 100%;
                padding: inherit 0;
            }
            input[type="submit"] {
                text-transform: uppercase;
                margin-top: 10px;
            }
        }
        .unsubscribe-text{
            font-size: 14px;
            margin-bottom: 20px;
            text-align: center;
        }
    }
}

@media (min-width: 768px) {
    /* tablet-up */

    div.newsletter {
        div.top-block {
            .title {
                font-size: 30px;
            }
        }
        .e-wydania {
            div.labels {
                div.input-cell {
                    padding: 3px 10px;
                    label {
                        width: auto;
                        font-size: 16px;
                    }
                    input {
                        //margin-right: 30px;
                        float: right;
                        height: 33px;
                        border: 1px solid #b6b6b6;
                        width: 60%;
                    }
                }
            }
        }
        .unsubscribe{
            padding-top: 50px;
            .unsubscribe-form{
                margin-left:8.333%;
                input {
                    height: 48px;
                    border: 1px solid $color-grey-light-1;
                    float: left;
                    width: 100%;
                    padding: inherit 0;
                }
                input[type="submit"] {
                    text-transform: uppercase;
                    width: 30%;
                    margin: 0;
                }
                input[type="email"] {
                    width: 70%;
                }
            }
            .unsubscribe-text {
                text-align: center;
            }
        }
    }
}

@media (min-width: 1025px) {
    /* desktop-up */
    div.newsletter {
        background-color: #fff;
        margin-bottom: 75px;

        .top-block {
            padding: 35px 0;

            .title {
                text-transform: uppercase;

                font-weight: 900;
                font-size: 20px;
                text-align: left;
            }
        }
        .top-banner {
            background-color: #333;
            color: #fff;
            height: 64px;
            span {
                display: table;
                margin: 0 auto;
                text-transform: uppercase;

                font-weight: 300;
                font-size: 20px;
                padding: 18px 0;
                img {
                    width: 110px;
                }
            }
        }
        .newsletter-oreder {
            text-align: center;
            .text-block {
                p {
                    font-size: 14px;
                }
            }
            .emailbox {
                height: 50px;
                padding: 0;
                margin: 20px 0 40px 0;
                input {
                    width: 100%;
                    height: 100%;
                    padding: 0 15px;
                    border: 1px solid $color-grey-light-1;
                    box-shadow: 0 25px 35px #ccc;
                }
            }
        }
        .e-wydania {
            img.cover {
                height: 297px;
                display: table;
                margin: 0 auto;
                box-shadow: 0 25px 35px #ccc;
                margin-left: auto;
                margin-right: 0;
                margin-bottom: 30px;
            }
            .submit{
                border: none;
                margin-top: 35px;
                height: 64px;
                width: 100%;
                background-color: $color-primary-light;
                color: #fff;

                font-weight: 600;
                text-transform: uppercase;
                box-shadow: 0 25px 35px #ccc;
            }
            div.regulamin {
                font-size: 10px;
                padding: 0 80px;
                margin: 60px 0 45px 0;
                color: #838383;
            }
            div.title{
                text-align: center;
                font-size: 28px;
                font-weight: 500;
                margin-bottom: 40px;
            }
            div.labels {
                .label {
                    font-weight: 400;
                    font-size: 14px;
                    margin: 0;
                    padding-right: 45px;
                    strong {
                        font-weight: 600;
                    }
                }
                div.prezent {
                    margin: 20px 0;
                    [type="checkbox"].checkbox:not(:checked), [type="checkbox"].checkbox:checked {
                        position: absolute;
                        left: -9999px;
                    }
                    [type="checkbox"].checkbox:not(:checked) + label,
                    [type="checkbox"].checkbox:checked + label {
                      position: relative;
                      padding-left: 45px;
                      cursor: pointer;
                    }

                    /* checkbox aspect */
                    [type="checkbox"].checkbox:not(:checked) + label:before,
                    [type="checkbox"].checkbox:checked + label:before {
                      content: '';
                      position: absolute;
                      left: 0; top: 2px;
                      width: 34px;
                      height: 34px;
                      border: 1px solid #b6b6b6;
                      background: #fff;
                    }
                    /* checked mark aspect */
                    [type="checkbox"].checkbox:not(:checked) + label:after,
                    [type="checkbox"].checkbox:checked + label:after {
                      content: '\f00c';
                      font-family: 'FontAwesome';
                      position: absolute;
                      top: 10px; left: 6px;
                      font-size: 24px;
                      line-height: 0.8;
                      color: #000;
                      transition: all .2s;
                    }
                    /* checked mark aspect changes */
                    [type="checkbox"].checkbox:not(:checked) + label:after {
                      opacity: 0;
                      transform: scale(0);
                    }
                    [type="checkbox"].checkbox:checked + label:after {
                      opacity: 1;
                      transform: scale(1);
                    }
                }
                div.input-cell {
                    label {
                        width: auto;
                    }
                    padding: 3px 0;
                    input {
                        //margin-right: 30px;
                        float: right;
                        height: 33px;
                        border: 1px solid #b6b6b6;
                        width: 65%;
                    }
                }

            }
        }
        .unsubscribe{
            padding-top: 50px;
            .unsubscribe-form{
                margin-left: 0;
                input {
                    height: 48px;
                    border: 1px solid $color-grey-light-1;
                    float: left;
                    width: 100%;
                    padding: inherit 0;
                }
                input[type="submit"] {
                    text-transform: uppercase;
                    width: 30%;
                    margin: 0;
                }
                input[type="email"] {
                    width: 70%;
                }
            }
            .unsubscribe-text {
                text-align: left;
            }
        }
    }
}
#unsubscribe {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-direction: column;
    @media(min-width: 1200px) {
        flex-direction: row;
    }
    &, * {
        box-sizing: border-box;
    }
    .g-recaptcha {
        margin: 0 0 15px;
        @media(min-width: 1200px) {
            margin: 0 15px;
        }
    }
    .unsubscribe-text {
        margin: 0 auto 15px;
        @media(min-width: 1200px) {
            margin: 0;
        }
    }
    input[type="email"],
    input[type="submit"] {
        height: 76px;
        border-radius: 0;
        border: 1px solid rgba(0,0,0,0.3);
    }
    input[type="submit"] {
        width: 302px;
        @media(min-width: 1200px) {
            width: 120px;
        }
    }
    input[type="email"] {
        width: 302px;
        margin: 0 0 15px;
        @media(min-width: 1200px) {
            border-right: none;
            margin: 0;
        }
    }
}
