.badge {
    margin-bottom: 5px;
    padding: 5px;
    font-size: 13px;
    
    &,
    &:link,
    &:visited {
        color: $color-primary-light;
    }

    &.badge-primary { color: #fff; }
}