.section-recommended {
    article {
        h3 {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }

    &-aside {
        margin-bottom: 60px;

        h2 {
            margin-bottom: 0;
            padding-bottom: 10px;
            font-size: 20px;
            text-transform: uppercase;
        }

        article {
            padding-bottom: 30px;
            margin-bottom: 0;

            h3 {
                margin: 10px 0 0;
                font-size: 16px;
            }
        }
    }
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section.gallery {
    margin-bottom: 30px;

    .title {
        margin: 0;
        padding: 8px 0;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .button {
        display: block;
        padding: 10px 20px;
        background: #f2f2f2;
        border: 2px solid #b8b8b8;
        font-size: 13px;
        color: #333;
        border-radius: 40px;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;

        @include hover-button;
    }

    .box {
        margin: 10px auto 0;
        /*max-width: 300px;*/


        .gallery-item {
            margin: 2px 0 20px;
            display: inline-block;
            text-decoration: none;

            .hover-image {
                @include hover-image;
            }


            .caption {
                font-family: Poppins;
                color: #212121;
                font-size: 13px;

                line-height: 19px;
                text-decoration: none;
                margin-top: 15px;

                .source{
                    font-style: italic;
                }
            }
        }
    }
}

.section-gallery {

    h2 {
        padding: 11px 0;
        margin-bottom: 0;
        font-size: 17px;
        text-transform: uppercase;
    }
}

.complementary {
    &,
    .source { margin-top: 30px; }

    .price {
        background-color: #f2f2f2;
        padding: 15px;
        margin-bottom: 30px;

        .title {
            color: $color-primary-light;
            border-bottom: 2px solid #b8b8b8;
            font-size: 17px;
            font-weight: 700;
            line-height: 1.5;
            text-transform: uppercase;
        }

        .box {
            font-size: 25px;
            font-weight: 600;
        }
    }
    .dist-span {
        font-size: 16px;
    }
}

.section-linked {

    h2.bordered { border-bottom-width: 5px; }

    article {
        border: none;
        margin-bottom: 0;

        &:not(:first-child) { margin-top: 30px; }

        .content-wrapper { max-width: 350px; }
    }
}

.section-article-contact {
    .heading { font-size: 43px; }
    .heading-small {
        font-size: 18px;
        &.bordered { border-width: 1px; }
    }

    .section-article-editorial {
        .heading-small { text-transform: uppercase; }

        .employee {
            &-job { font-weight: 800; }
        }
    }
}

/* ===== Standard styles for article ===== */
h1 {
    color:  #212121;
    font-size: 43px;
    line-height: 43px;
    padding-bottom: 20px;
    border-bottom: 3px solid #000;
    margin: 0;
}

.article {

    h1 {
        margin-bottom: 15px;
        font-size: 40px;
        font-weight: 700;
    }
    .main {
        position: relative;
        height: 100%;

        .top {

            .date-container {
                margin-bottom: 15px;
                font-size: 15px;

                * {
                    display: inline-block;
                    padding-right: 15px;
                }

                .category {
                    color: #ffa000;
                    margin-right: 10px;
                }

                .right {
                    float: right;
                    padding: 0;

                    * {
                        padding-right: 5px;
                    }
                }


                .next {
                    margin-right: 10px;
                    position: relative;

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 3px;
                        bottom: 3px;
                        width: 1px;
                        background-color: $color-grey-light-3;
                    }
                }


            }
        }

        .social-icons-block {
            display: none;
            position: absolute;
            top: 0;
            left: -80px;

            @media (min-width: 1300px) { display: block; }

            &.absolute {
                position: absolute;
                top: auto;
                bottom: 0;
                left: -80px;
            }

            &.fixed {
                position: fixed;
                top: map-get($navbar-shrinked-height, xs);
                bottom: auto;
                left: calc(50% - 664px);
            }
        }

        img {
            max-width: 100%;
        }
    }

    .content {

        .introtext {
            color:  $color-grey-dark-2;
            font-size: 22px;
            padding-bottom: 5px;
            border-bottom: 1px solid #000;
            margin-bottom: 15px;
            margin-top: 15px;

            a {
                color: #ffa000;
            }
        }

        .fulltext {
            color:  $color-grey-dark-2;
            font-size: 18px;
            line-height: 29px;

            @import "../../../subpages/sass/editor/components/old";
            @import "../../../subpages/sass/editor/components/pomiary";
            @import "../../../subpages/sass/editor/components/information";

            a {
                color: #ffa000;
            }

            .img_caption {
                &.right {
                    float: right;
                    margin-left: 30px;
                    @media (max-width: $breakpoint-mobile-max) {
                        float: none;
                        margin-left: auto;
                        display: block;
                    }
                }
                &.left {
                    float: left;
                    margin-right: 30px;
                    @media (max-width: $breakpoint-mobile-max) {
                        float: none;
                        margin-left: auto;
                        display: block;
                    }
                }
                p {
                    font-size: 15px;
                    line-height: 18px;
                    @media (max-width: $breakpoint-mobile-max) {
                        display: block;
                        margin: 0 auto 15px;
                    }
                }

                a {
                    @media (max-width: $breakpoint-mobile-max) {
                        display: block;
                    }
                    img {
                        @media (max-width: $breakpoint-mobile-max) {
                            display: block;
                            margin: 0 auto 15px;
                        }
                    }
                }
                
            }

            
        }

        .more {
            text-align: right;
            padding-top: 10px;
        }
    }

    img {
        max-width: 100%;
    }

    .company-name {
        display: inline-block;
        background-color: $color-primary-light;
        color: $color-white;
        font-weight: 700;
        padding: 0 5px;
    }

    .image-content {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.46;
    }
}
#article-main {
    .main-image {
        .main-image__photo-author {
            text-align: right;
            display: block;
            margin-bottom: 5px;
        }
    }
}



/* ===== Standard styles for article end ===== */


/* ===== Kontakt ===== */

.other-info {
    width: 54.5%;
    padding: 0 15px;
    float: left;
    font-size: 18px;

    .contact-data-title {
        border-bottom: 1px solid rgba(51, 51, 51, 0.5);
        font-weight: 700;
    }
}

.editor {
    margin-bottom: 30px;
    font-size: 18px;

    h2 {
        color:  #333333;
        font-size: 45px;
        border-bottom: 3px solid #000;
        padding-bottom: 5px;
        margin-bottom: 15px;
    }

    .contact-data-title {
        border-bottom: 1px solid rgba(51, 51, 51, 0.5);
        font-weight: 700;
    }

    .person {
        margin-bottom: 15px;

        .tele-info {
            word-wrap: break-word;
        }
    }
}

.clearfix {
    margin-bottom: 30px;
}


@media (max-width: 991px) {
    .form-contact, .other-info {
        width: 100%;
    }

    .form-contact {
        margin-bottom: 15px;
    }
    .other-info {
        padding: 0;

        > div {
          padding: 0;
        }
    }
}

/* ===== Kontakt end ===== */

/* ===== Magazyn ===== */
.article-magazine {
    position: relative;

    h2 {
        color:  #212121;
        font-size: 43px;
        line-height: 43px;
        margin-bottom: 5px;
    }

    .sub-title {
        color:  #212121;
        font-size: 33px;
        line-height: 43px;
        border-bottom: 3px solid #000;
        margin-bottom: 20px;
        padding-bottom: 10px;
    }

    .box {
        border: 1px solid #f0f0f0;
        background-color:  #ffffff;
        padding: 30px 15px;
        position: relative;
        margin-bottom: 30px;

        &::before {
            display: block;
            content: '';
            position: absolute;
            top: 30px;
        }

        &.magazine {

            &::before {
                width: 50px;
                height: 46px;
                background: url(/templates/main/images/sprite.png) -56px -38px no-repeat;
                left: 17px;
            }
        }

        &.e-magazine {

            &::before {
                width: 40px;
                height: 52px;
                background: url(/templates/main/images/sprite.png) -13px -38px no-repeat;
                left: 18px;
                top: 25px;
            }
        }

        .box-title {
            color:  #333333;
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 0;
            text-transform: uppercase;
            padding-left: 50px;
            text-align: center;
        }

        .box-subtitle {
            color:  #767b7f;
            font-size: 13px;
            line-height: 21px;
            padding-left: 50px;
            margin-bottom: 20px;
            text-align: center;
        }

        .box-button {
            background-color: #ffa000;
            display: flex;
            max-width: 280px;
            margin: 0 auto;

            @include transition;
            &.inactive {
                background-color: #fff;
                color: #898989;
                border: 1px solid #898989;
                cursor: inherit;
                .cost {
                    display: none;
                }
                & * {
                    color: #898989;
                }
                &:hover {
                    background-color: #fff;
                    color: #898989;
                }
            }
            &:hover {
                background-color: darken(#ffa000, 10%);
            }
            > * {
                display: inline-block;
                margin: 0;
                padding: 10px;
                color: #fff;
                font-weight: 700;
            }

            .cost {
                border-right: 1px solid rgba(255, 255, 255, 0.2);
                font-size: 16px;
                flex-grow: 0;
            }

            .text {
                padding-top: 12px;
                font-size: 14px;
                flex-grow: 2;
                text-align: center;
            }
        }
    }

    .subscription {
        background-color: #f0f0f0;
        padding: 15px;
        margin-bottom: 30px;

        .content {
            width: 50%;
            color:  #12121a;
            font-size: 14px;
            text-align: center;
            float: left;
            margin-top: 15px;
            text-transform: uppercase;
            font-weight: 700;
        }

        a {
            display: inline-block;
            width: 50%;
            border: 2px solid #ffa000;
            text-align: center;
            padding: 12px 0;
            color: #ffa000;
            font-weight: 700;
            font-size: 14px;
            position: relative;
            @include transition;
            z-index: 2;
            /*border-radius: 30px;*/
            &.inactive {
                background-color: #fff;
                color: #898989;
                border: 1px solid #898989;
                cursor: inherit;
                &:before {
                    display: none;
                }
                &:hover {
                    background-color: #fff;
                    color: #898989;
                }
            }
            &:hover {
                color: #fff;
                background-color: #ffa000;

            }
        }
    }

    .demo {
        border: 1px solid rgba(0,0,0, .2);
        font-size: 14px;
        color: #000;
        text-align: center;
        display: block;
        width: 100%;
        text-transform: uppercase;
        padding: 10px;
        font-weight: 700;
        margin-bottom: 30px;
        /*border-radius: 30px;*/
        position: absolute;
        right: 0;
        top: 0;
        max-width: 300px;
        @include transition;

        &.static {
            position: static;
            max-width: 100%;
        }

        &:hover {
            background-color: rgba(0,0,0, .2);
        }
    }
}

.in-magazine {
    background-color: #f0f0f0;
    padding: 50px 0 60px;

    h3 {
        color:  #000000;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 30px;
    }

    .title {
        color:  #000000;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .article {
        color:  #000000;
        text-align: left;
    }

    .from-editor {
        color:  #000000;
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 30px;

        p {
            margin: 0;
        }
    }
}

@media (max-width: 991px) {

    .article-magazine {

        h1 {
            margin-bottom: 30px;
        }

        img {
            width: 100%;
            margin-bottom: 30px;
        }

        .buy-panel {
            display: none;
        }
    }
}
/* ===== Magazyn end ===== */
