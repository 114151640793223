.article-firm {
    .row {
        border-bottom: 1px solid $color-icon-grey;
    }

    .img-wrapper {
        position: relative;
        width: 180px;
        height: 180px;
        margin: 0 auto 30px;
        padding: 30px;
        border: 1px solid $color-icon-grey;

        @include media-breakpoint-up(lg) {
            width: 205px;
            height: 205px;
            margin: 0 0 30px;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-height: 205px - (30px * 2);
            transform: translate(-50%, -50%);
        }
    }

    .content-wrapper {
        text-align: center;

        @include media-breakpoint-up(md) { text-align: left; }

        h1 {
            padding: 0;
            margin: 0 0 10px;
            border: none;
            font-size: 25px;
            font-weight: 400;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                margin-top: 35px;
                font-size: 40px;
            }
        }
        p {
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 25px;

            .description { margin-top: 30px; }
        }
        .description {
            &.hidden {
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
            }
        }
        #btn-more {
            cursor: pointer;
        }
        .badge {
            margin: 20px 0 30px;
            font-size: 16px;
        }
    }
}

.section-firm-products {
    margin: 70px 0 30px;

    @include media-breakpoint-up(md) { margin-top: 140px; }

    article {
        .img-wrapper {
            background: linear-gradient(to bottom, transparent,rgba($color-black, 0.2));

            // Take image backward, so linear-gradient can be visible
            img {
                position: relative;
                z-index: -1;
            }
        }
        .badge { margin: 20px 0 0; }
        .heading {
            margin: 15px 0;
            font-size: 18px;
        }
    }
}
