$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px
);

// Colors
$primary: #ff9f00;

// Disable global border-radius
$border-radius:    0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$enable-caret: false;

$transition-base: all .2s ease 0s;


// Spacing (margins and paddings)
$spacer: 5px;
$spacers: (
  0: 0,
  1: ($spacer * 1),
  2: ($spacer * 2),
  3: ($spacer * 3),
  4: ($spacer * 4),
  5: ($spacer * 5),
  6: ($spacer * 6)
);

// Bootstrap - required
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

// Bootstrap - optional (however required by the project)
@import '../../../../node_modules/bootstrap/scss/root';
@import '../../../../node_modules/bootstrap/scss/reboot';
@import '../../../../node_modules/bootstrap/scss/type';
@import '../../../../node_modules/bootstrap/scss/media';
@import '../../../../node_modules/bootstrap/scss/grid';
@import '../../../../node_modules/bootstrap/scss/nav';
@import '../../../../node_modules/bootstrap/scss/images';
@import '../../../../node_modules/bootstrap/scss/badge';
@import '../../../../node_modules/bootstrap/scss/dropdown';
@import '../../../../node_modules/bootstrap/scss/utilities';
@import '../../../../node_modules/bootstrap/scss/mixins/transition';
