.social-icons {
    font-size: 0;
    text-align: center;
    
    &.first {
        width: 60px;
        z-index: 10;
        
        .icon {
            width: 100%;
            border-width: 0 0 1px 0;
            
            &.fb {
                &:hover {
                    color: #3b5998;
                }

                &:after {
                    content: "\f09a";
                }
            }

            &.twtr {
                &:hover{
                    color: #00aced;
                }
                &:after {
                    content: "\f099";
                }
            }

            &.pin {
                &:hover{
                color: #ca0034;}
                &:after {
                    content: "\f0d2";
                }
            }

            &.google {
                &:hover{
                color: #dd4b39;}
                &:after {
                    content: "\f0d5";
                }
            }

            &.mail {
                &:hover{
                color: #a60505;

                }
                &:after {
                    content: "\f0e0";
                }
            }
        }
    }
    
    .icon {
        @include transition();
        max-width: 60px;
        width: 20%;
        background: none;
        height: 60px;
        display: inline-block;
        font-size: 25px;
        color: #212121;
        line-height: 60px;
        position: relative;
        vertical-align: middle;
        border: solid #212121;
        border-width: 0 1px 0 0;

        &:after {
            position: absolute;
            font-family: 'FontAwesome';
            display: inline-block;
            top:0;
            left:0;
            width: 100%;
            height: 60px;
        }

        &.fb {
            &:hover {
                color: #3b5998;
            }

            &:after {
                content: "\f09a";
            }
        }

        &.twtr {
            &:hover{
                color: #00aced;
            }
            &:after {
                content: "\f099";
            }
        }

        &.pin {
            &:hover{
            color: #ca0034;}
            &:after {
                content: "\f0d2";
            }
        }

        &.google {
            &:hover{
            color: #dd4b39;}
            &:after {
                content: "\f0d5";
            }
        }

        &.mail {
            &:hover{
            color: #a60505;

            }
            &:after {
                content: "\f0e0";
            }
        }
        &:hover {
            /*color: rgba(255, 255, 255, 0.9);*/
            font-size: 35px;
            text-decoration: none;
        }
        &:last-child {
            border: none;
        }
    }

    &.fixed-icons {
        position: fixed;
        top: 60px;
        left: 50%;
        margin-left: -625px;
    }
}