.magazine-right {
    text-align: center;
    margin-bottom: 30px;
    max-width: 318px;
    margin: 0 auto;
    
    a { display: block; }
    
    .new {
        background-color: #333;
        color: #fff;        
        padding: 0 15px 15px;
        display: block;
        
        .name {
            font-size: 28px;
            margin-bottom: 0;
            
            span {
                font-weight: 700;
            }
        }
        
        .number {
            font-size: 20px;
            opacity: .4;
            margin-bottom: 0;
            text-transform: capitalize;
            display: block;
        }
    }
    
    .buy-now {
        display: block;
        background-color: #fbc02d;
        color: #333;
        padding: 8px 15px;
        font-size: 20px
    }

    .article {

        .uk-images {
            
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}