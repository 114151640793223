.distributor {
    background-color:  #dedede;
    padding: 10px 15px;
    
    .title {
        border-bottom: 2px solid rgba(51, 51, 51, 0.3);
        color:  #ffa000;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        padding-bottom: 7px;
    }
    
    .box {
        color:  #333333;
        font-size: 17px;
    }
}