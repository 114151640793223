.section-main-products {

    // Make last article's border wider
    .row > div:last-child .article-peek { border-bottom-width: 3px; }
}

.section-presentations {
    margin-bottom: 30px;

    article {
        
        h3 {
            font-size: 18px;
            margin-bottom: 15px;
        }

        .img-wrapper {
            display: none;
            @include media-breakpoint-up(md) { display: block; }
        }
    }
}

.section-tutorials {
    margin-bottom: 30px;

    article {
        padding-top: 30px;
    }

    img { margin-bottom: 15px; }
}

// Hide newsletter from menu - start
#menu {
    .item-446 {
        display: none;
    }
}

// Hide newsletter from menu - end


.newsletter-sticky {
    text-align: center;
    width: 260px;
    position: fixed;
    left: 50px;
    bottom: -204px;
    font-family: 'Azo Sans', sans-serif;
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    transition: 0.5s all;
    cursor: pointer;
    &.hide {
        opacity: 0;
        transition: 0.5s all;
    }
    &:hover {
        bottom: 0;
    }
    &.small {
        bottom: -298px;
        transition: 0.5s all;
        &:hover {
            bottom: 0;
        }
    }
    .first-wrapper {
        background-color: #FF9F00;
        position: relative;
    }
    .second-wrapper {
        background-color: #28292A;
        padding-top: 7px;
    }
    &__close {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 3px;
        right: 5px;
        cursor: pointer;
        padding: 1px;
        z-index: 1;
        transition: .5s all;
        &:hover {
            transform: rotate(180deg);
            transition: .5s all;
        }
        &:hover::after {
            background-color: rgba(255,255,255,1);
        }
        &:hover::before {
            background-color: rgba(255,255,255,1);
        }
        &::before,
        &::after {
            position: absolute;
            content: '';
            right: 0;
            top: 7px;
            width: 100%;
            height: 1px; /* cross thickness */
            background-color: rgba(255,255,255,.5);
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
    &__first-title {
        font-size: 16px;
        line-height: 20px;
        color: #1A1B1C;
        padding-top: 6px;
        cursor: pointer;
    }
    &__second-title {
        color: #fff;
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        border-bottom: 2px solid #fff;
        padding-bottom: 5px;
    }
    &__icon {
        background-color: #353637;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 27px auto;
        box-shadow: 0px 0px 40px -11px rgba(0,0,0,0.75);
        svg {
            color: #9A9B9B;
            width: 35px;
        }
    }
    &__description {
        font-size: 11px;
        line-height: 14px;
        color: #fff;
        font-weight: 700;
        padding: 0 20px;
    }
    &__form {
        &__wrapper {
            &__input {
                background-color: #666666;
                padding: 10px 50px;
                color: #fff;
                font-size: 13px;
                text-align: center;
                border: none;
                margin-bottom: 15px;
            }
            &__btn {
                background-color: #FF9F00;
                color: #fff;
                font-size: 13px;
                font-weight: 700;
                line-height: 17px;
                border-radius: 3px;
                border: none;
                padding: 10px 20px;
                margin-bottom: 56px;
                transition: .5s all;
                cursor: pointer;
                &:hover {
                    background-color: lighten(#FF9F00,5%);
                    transition: .5s all;
                }
            }
        }
    }
}

.swiper-main-container {
    .swiper-slide {
        height: 740px;
        @media(max-width:550px){
            height: 540px;
        }
    }
    & > .featured-article {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: auto;
        padding: 60px;
        z-index: 1;
        display: flex;
        align-items: flex-end;
        @media(max-width:550px) {
            padding: 20px;
            article {
                p {
                    text-align: left;
                }
            }
        }
    }
}

.swiper-main-navigation {
    z-index: 2;
    &__left {
        // display: none;
        width: 60px;
        height: 100%;
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
        @media(max-width:550px) {
            width: 30px;
        }
        &::before {
            content: '';
            width: 62px;
            top: 50%;
            left: 15px;
            margin-top: -54px;
            right: 0;
            transform-origin: 0 0;
            transform: rotate(45deg);
            background: #fff;
            position: absolute;
            height: 1px;
            box-shadow: 0 1px 1px rgba(0,0,0,.3);
            @media(max-width:550px) {
                width: 20px;
                left: 5px;
            }
        }
        &::after {
            content: '';
            width: 62px;
            top: 50%;
            left: 15px;
            margin-top: -54px;
            right: 0;
            transform-origin: 0 100%;
            transform: rotate(-45deg);
            background: #fff;
            position: absolute;
            height: 1px;
            box-shadow: 0 1px 1px rgba(0,0,0,.3);
            @media(max-width:550px) {
                width: 20px;
                left: 5px;
            }
        }
    }
    &__right {
        // display: none;
        width: 60px;
        height: 100%;
        z-index: 2;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        @media(max-width:550px) {
            width: 30px;
        }
        &::before {
            content: '';
            width: 62px;
            top: 50%;
            margin-top: -54px;
            right: 15px;
            transform-origin: 100% 0;
            transform: rotate(45deg);
            background: #fff;
            position: absolute;
            height: 1px;
            box-shadow: 0 1px 1px rgba(0,0,0,.3);
            @media(max-width:550px) {
                width: 20px;
                left: 0;
                right: 5px;
            }
        }
        &::after {
            content: '';
            width: 62px;
            top: 50%;
            margin-top: -54px;
            right: 15px;
            transform-origin: 100% 0;
            transform: rotate(-45deg);
            background: #fff;
            position: absolute;
            height: 1px;
            box-shadow: 0 1px 1px rgba(0,0,0,.3);
            @media(max-width:550px) {
                width: 20px;
                left: 0;
                right: 5px;
            }
        }
    }
}