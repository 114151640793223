.yellow-text {
    background: #ffbe00;
    font-weight: bold;
}
.red-text {
    background: #da0000;
    font-weight: bold;
    color: #fff;
}
.black-text {
    background: #000;
    font-weight: bold;
    color: #fff;
}

/* --- Zdjęcia z ramkami --- */

/* Zdjęcia po środku z ramką */
.image-ramka {
    border: 2px solid #ffc301;
    padding: 20px;
}
.image-ramka .title {
    font-weight: bold;
    margin: 14px 0 0;
}
.image-ramka .title + .i-text {
    margin: 0;
}
.image-ramka .i-text {
    margin: 14px 0 0;
}
.image-ramka .i-text>*:last-child {
    margin: 0;
}

/* Zdjęcia po lewej z ramką */
.image-ramka.left {
    width: 350px;
    float: left;
    margin: 0 14px 14px 0;
}

/* Zdjęcia po prawej z ramką */
.image-ramka.right {
    width: 350px;
    float: right;
    margin: 0 0 14px 14px;
}
/* --- Zdjęcia z ramkami End --- */




/* Zdjęcia po środku bez ramki */
.image-no-ramka {
    margin: 0 auto 14px;
}
.image-no-ramka img {
    display: block;
    margin: 0 auto;
}
.image-no-ramka .title {
    margin: 14px 0 0;
    font-weight: bold;
    text-align: center;
}

/* Cytat */

.daneTechniczne {
    background: #dedede;
    padding: 25px;
}