.breadcrumb {
    font-size: 13px;

    &#no-margin {

        ul {
            margin :0;
            padding: 30px 0;
        }
    }

    margin: 0;
    padding: 15px 0;
    list-style: none;

    li {
        display: inline-block;

        &.active { display: none; }
    }
    
}
