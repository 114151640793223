.articles-list {

    > * {

        &:last-of-type {

            > *  {
                margin-bottom: 15px;
            }
        }
    }
    .section-recommended-aside {

        h2 {
            margin-bottom: 30px;
            
            .category-name {
                color: $color-primary-light;
            }
        }
    }

    & > h2 {
        font-size: 26px;
        padding: 15px 2px;
    }

    .title-wrapper {
        position: relative;

        .btn {
            position: absolute;
            top: 0;
            right: 0;
            padding: 15px 20px;
        }
    }

    .section-title {
        color: $color-primary-light;
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    &.recommended {

        article {
            margin-bottom: 30px;
        }
    }

    .img-wrapper {

        img {
            width: 100%;
        }
    }

    article {
        height: 100%;
        border-bottom: 1px solid $color-black;
        padding-bottom: 30px;
        margin-bottom: 15px;

        &.recommend {
            position: relative;

            img {
                margin-bottom: 10px;
                width: 100%;
            }

            &:hover { 
                h3:after { width: 100% }
                .image-wrapper img { transform: scale3d(1.2, 1.2, 1.2); }
            }

            .image-wrapper {
                overflow: hidden;

                img {
                    margin: 0;
                    transition: transform .33s ease-out 0s; 
                }
            }

            h3 {
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -1px;

                    width: 0;
                    height: 4px;

                    background-color: $color-primary-light;
                    transition: width .5s ease-out 0s;
                }
            }

            p {
                margin-top: 10px;
            }
        }

        &.last {
            border-bottom-width: 3px;

            // Only when articles divided into 2 columns (tablet).
            @include media-breakpoint-up(md) {
                border-bottom-width: 1px;
            }

            @include media-breakpoint-up(lg) {
                border-bottom-width: 3px;
            }
        }

        .bg-image {
            width: 100%;
            height: 140px;
            margin-bottom: 15px;
            background-image: linear-gradient(to bottom, transparent, rgba(1, 1, 1, 0.2));
            text-align: center;
            overflow: hidden;

            @include media-breakpoint-up(md) {
                height: 165px;
            }

            @include media-breakpoint-up(lg) {
                height: 260px;
            }

            &.no-gradient { background-image: none; }

            &.img-centered {
                max-height: 260px;

                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }

            img {
                width: auto;
                height: inherit;
            }
        }

        .category {
            padding: 0;
            margin-bottom: 15px;
            color: $color-primary-light;
            font-size: 13px;
            margin-bottom: 5px;
        }

        p {
            font-size: 15px;
            line-height: 1.5;
            text-align: left;

            &:last-of-type { margin-bottom: 0; }
        }

        &.product {
            .bg-image {
                background-image: linear-gradient(to bottom, transparent, rgba(1, 1, 1, 0.2));

                img {
                    position: relative;
                    z-index: -1;
                }
            }
        }

        // Layout - image goes left, content stays right (horizontal)
        &.content-horizontal {
            display: flex;
            flex-wrap: wrap;

            .img-wrapper {
                flex: 0 0 auto;
                align-self: center;
            }

            .content-wrapper {
                flex: 0 1 100%;
                padding-left: 15px;
            }
        }

        h3 {
            font-size: 22px;
            margin-top: 5px;
        }
    }
    

    &.more-from {
        border: none;

        .section-recommended-aside {

            article {
                border: none;
                
                &:hover { 
                    h3:after { width: 100% }
                    .image-wrapper img { transform: scale3d(1.2, 1.2, 1.2); }
                }

                .image-wrapper {
                    overflow: hidden;

                    img {
                        margin: 0;
                        transition: transform .33s ease-out 0s; 
                    }
                }

                h3 {
                    margin-top: 5px;
                    
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;

                        width: 0;
                        height: 4px;

                        background-color: $color-primary-light;
                        transition: width .5s ease-out 0s;
                    }
                }
            }
        }
    }

    &.article-list-linked {
        article {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    .btn.btn-show-more {
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 22px;
            right: 15px;
        }

        @include media-breakpoint-up(lg) {
            position: initial;
            top: unset;
            right: unset;
        }
    }

    .more-linked-items, .more-linked {
        padding: 0 15px;
    }

    .more-linked-items {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        article {
            margin-bottom: 15px;
        }
    }

    .more-linked {
        border: 2px solid #ffa000;
        background-color: #fff;
        height: 50px;
        color: #ffa000;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        margin: 0 15px 25px;
        text-transform: uppercase;
        line-height: 46px;
        cursor: pointer;
        position: relative;
        clear: both;
        width: 100%;

        span {
            z-index: 10;
            position: relative;
            @include transition(color .5s ease 0s);
        }

        &:hover {

            span {
                color: #fff;
            }

            &::after {
                left: 0;
                right: auto;
                width: 100%;
            }
        }

        &::after {
            display: block;
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            background: #ffa000;
            width: 0;
            @include transition(width .5s ease 0s);
        }
    }

    // Smaller articles lists (e.g. events)
    &.article-small {

        article {
            display: flex;
            flex-wrap: nowrap;

            .content-flex {
                flex: 0 1 100%;
                padding-right: 5px;
            }

            .img-flex {
                flex: 0 0 auto;
                align-self: center;
            }

            h3 {
                margin-bottom: 10px;
                font-size: 18px;
            }

            img {
                display: none;
                width: 160px;
                height: auto;

                // Fix bootstrap classes if set
                &.img-fluid { max-width: none !important; }

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }
    }


    // Different layout - image goes left and content goes right
    &.articles-list-small {

        @media (max-width: 1199px) { //XXX: Should be variable!
            &-md-down {

                article {
                    display: flex;
                    flex-wrap: nowrap;

                    .img-wrapper {
                        flex: 0 0 auto;
                        align-self: center;

                        img { max-width: 300px; }
                    }

                    .content-wrapper {
                        flex: 0 1 100%;
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}
