.article {
    &-header {
        border-bottom: 1px solid $color-black;
        font-size: 15px;

        h1 { font-weight: 400; }

        .separator {
            padding: 0 15px;
            &:before { content: "|"; }
        }

        .badge { font-size: inherit; }
        .article-img { padding: 0 15px; }

        p {
            margin-bottom: 0;
            font-size: 22px;
            font-weight: 300;
            line-height: 1.4;
        }
    }

    &-main {
        p {
            margin-bottom: 30px;
            font-size: 16px;
            line-height: 28px;
        }
    }
    .content iframe[name=embedded-content] {
        width: 100%;
        border: none;
    }
}
