.pagination{
    display: flex;
    text-align: center;
    justify-content: space-between;
    font-weight: 700;
    text-transform: uppercase;
    align-items: center;
    border-bottom: 5px solid #333;
    span{
        display:none;
        @include media-breakpoint-up(sm){
            display: inline;
        }
    }
    a,p{
        margin: 0;
        padding: 0;
        border: none !important;
    }
    .inactive, .active{
        font-size: 13px;
    }
    .items{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        a,p,.active,.inactive{
            font-size: 20px;
            text-decoration: none;
        }
        .active,.inactive{
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            @media(min-width: 768px){
                width: 50px;
                height: 50px;
                line-height: 50px;
            }
        }
        .inactive, .active:hover{
            background-color: #ffa000;
            color: #fff;
        }
    }
}