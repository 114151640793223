.list-separated {
    padding: 0;
    font-size: 0;
    list-style: none;

    li {
        display: inline;
        font-size: 16px;

        &:not(:first-child)::before {
            content: "|";
            padding: 0 5px;
        }
    }
}