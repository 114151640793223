.subscribe-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: $subscribe-background;
}

.featured-article + .subscribe-wrap {
    margin-top:  -30px;
}

#subscribe-newsletter {
    padding: 50px 0;
    .title {
        font-size: 37px;
        font-weight: 400;
        line-height: 40px;
        vertical-align: middle;
        text-align: center;
        margin-bottom: 15px;
        padding: 0 15px;
        span {
            font-weight: 700;
            text-transform: uppercase;
        }
        i {
            font-size: 36px;
            color: $color-primary-light;
            vertical-align: middle;
            margin-left: 15px;
        }
    }
    .subtitle {
        text-transform: uppercase;
        text-align: center;
        font-size: 17px;
        margin-bottom: 15px;
        padding: 0 15px;
    }
    form {
        font-size: 0;
        text-align: center;
        input {
            height: 55px;
            border: none;
            outline: none;
            text-align: center;
            font-size: 16px;
            padding: 0 5px;
            width: calc(100% - 30px);
            margin-bottom: 15px;
            @include media-breakpoint-up(sm) {
                max-width: 320px;
                margin-bottom: 0;
            }

            &::placeholder {
                opacity: 1.0;
                color: inherit;
            }

            &::-webkit-input-placeholder {
                opacity: 1.0;
                color: inherit;
            }

            &::-moz-placeholder {
                opacity: 1.0;
                color: inherit;
            }

            &::-ms-input-placeholder {
                opacity: 1.0;
                color: inherit;
            }
        }
        button {
            height: 55px;
            border: none;
            outline: none;
            background-color: $color-primary-light;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
            color: $color-white;
            cursor: pointer;
            width: calc(100% - 30px);
            @include media-breakpoint-up(sm) {
                max-width: 200px;
            }
        }
    }
}
