// Breakpoints (DEPRECATED)
$breakpoint-mobile-min:        576px;
$breakpoint-mobile-max:        767px;
$breakpoint-tablet-min:        768px;
$breakpoint-tablet-max:        1024px;
$breakpoint-desktop-min:       1025px;
$breakpoint-desktop-max:       1139px;
$breakpoint-desktop-large-min: 1140px;

// Colors
$color-white:           #fff;
$color-grey-light-2:    #f2f2f2;
$color-grey-light-3:    #dedede;
$color-grey-light-1:    #c5c6c7;
$color-grey-light-4:    #ababab;
$color-icon-grey:       #7c7c7c;
$color-grey-dark-1:     #333;
$color-grey-dark-2:     #212121;
$color-black:           #000;

$color-primary-light:   #ff9f00;
$color-secondary-light: #f6ca13;

$subscribe-background:  #E4E4E4;

// Navbar
$navbar-enabled: true;
$navbar-height: (
    xs: 50px,
    md: 100px
);
$navbar-shrinked-height: (
    xs: 50px,
    md: 50px
);

$navbar-bg: $color-white;
$navbar-border-width: 1px;
$navbar-border-color: #dbdbdb;
$navbar-border: $navbar-border-width solid $navbar-border-color;
$navbar-nav-border-width: 4px;

$navbar-full-height: (
    xs: map-get($navbar-height, xs) + $navbar-border-width,
    md: map-get($navbar-height, md) + $navbar-border-width
);
$navbar-shrinked-full-height: (
    xs: map-get($navbar-shrinked-height, xs) + $navbar-border-width,
    md: map-get($navbar-shrinked-height, md) + $navbar-border-width
);

// Margins, paddings
$section-margin-top: 30px;
