.list {
    margin-bottom: 30px;
    
    &-nav {
        width: 100%;
        margin-bottom: 30px;
        padding-top: 30px;
        background-color: $color-grey-light-1;

        .menu {
            $menu-item-height: (xs: 46px, md: 80px);
            position: relative;

            &-item {
                align-content: center;
                height: map-get($menu-item-height, xs);
                color: $color-white;
                font-size: 14px;
                font-weight: 700;
                text-align: center;

                @include media-breakpoint-up(sm) {
                    font-size: 16px;
                }

                @include media-breakpoint-up(md) {
                    height: map-get($menu-item-height, md);
                    font-size: 25px;
                    text-align: right;

                    &:first-of-type { text-align: left; }
                }

                @include media-breakpoint-up(lg) {
                    font-size: 33px;
                    
                    &, &:first-of-type { text-align: center; }
                }

                &.active {
                    color: $color-black;
                    background-color: $color-white;
                    border-top: 3px solid $color-primary-light;

                    .menu-link { color: inherit; }
                }
            }

            &-link {
                display: inline-block;
                padding: 9px 0;
                color: $color-white;

                @include media-breakpoint-up(sm) { padding: 9px;  }
                @include media-breakpoint-up(md) { padding: 20px; }
                @include media-breakpoint-up(lg) { padding: 15px; }
            } 

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;

                height: 100%;
                width: round(map-get($menu-item-height, xs) / tan(60deg)) * 2;

                border-color: $color-grey-light-1 transparent;
                border-style: solid;
                border-width: map-get($menu-item-height, xs) round(map-get($menu-item-height, xs) / tan(60deg)) 0;

                transform: translateX(-50%);

                @include media-breakpoint-up(md) {
                    width: round(map-get($menu-item-height, md) / tan(60deg)) * 2;
                    border-width: map-get($menu-item-height, md) round(map-get($menu-item-height, md) / tan(60deg)) 0;
                }
            }
        }

        .submenu {
            display: none;
            flex: 0 0 100%;
            justify-content: space-between;
            border-bottom: 1px solid $color-grey-light-1;

            @include media-breakpoint-up(lg) { display: flex; }

            &-item {
                position: relative;
                font-size: 20px;

                &.featured { font-weight: 700; }
                
                &.active::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -2px;
                    width: 100%;
                    height: 3px;
                    background-color: $color-black;
                }
            }

            &-link {
                display: inline-block;
                padding: 25px 15px;
            }

            &-bg {
                background-color: $color-white;
            }

            // Type submenu - only for firms
            &-type {
                display: none;
                @include media-breakpoint-up(lg) { display: block; }
            }

            // Submenu with dropdowns
            &-md-down {
                padding-top: 35px;

                .fa { margin: 0 10px; }
                .dropdown {
                    margin-bottom: 15px;
                    font-size: 14px;

                    @include media-breakpoint-up(md) { font-size: 18px; }
                }
            }
        }
    }

    h1 {
        margin: 30px 0 60px;
        padding-bottom: 15px;
        
        color:  #212121;
        border-bottom: 3px solid #212121;
        font-size: 45px;
        line-height: 45px;
    }
    
    &.list-magazine {
        position: relative;
        
        h1 {
            margin-bottom: 15px;
        }
        
        
        .clearfix {
            margin-bottom: 80px;
        }
        
        .magazine-year {
            width: 120px;
            background-color: #ff9f00;
            max-height: 40px;
            overflow: hidden;
            color: #fff;
            cursor: pointer;
            margin: 20px 0;
            @include transition;
            display: inline-block;
            z-index: 5;
            font-size: 18px;
            position: absolute;
            
            .magazine-year-select {
                text-align: center;
                float: left;
                width: 100%;
                
                p {
                    padding: 5px;
                    margin: 0;
                }
                a {
                    color: #fff;
                }
            }
            
            .current {
                float: right;
                width: 65%;
                padding-top: 6px;
                padding-right: 15px;
                text-align: right;
                font-weight: 700;
                margin-bottom: 0;
                padding-bottom: 7px;
            }
            
            .year {
                padding-top: 9px;
                padding-left: 15px;
                width: 25%;
                display: inline-block;
                position: relative;
                
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7px 7px 0 7px;
                    border-color: #fff transparent transparent transparent;
                    bottom: -14px;
                    @include transition;
                }
                
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: rgba(255,255,255,0.25);
                    width: 1px;
                    height: 22px;
                    right: -11px;
                }
            }
            
            &.open {
                max-height: 500px;
                background-color: #212121;
                
                .year {
                    
                    &::before {
                        border-width: 0 7px 7px 7px;
                        border-color: transparent transparent #fff transparent;
                    }
                }
            }
        }
        
        .demo {
            border: 1px solid rgba(0,0,0, .2);
            font-size: 14px;
            color: #000;
            text-align: center;
            display: block;
            width: 100%;
            text-transform: uppercase;
            padding: 10px;
            font-weight: 700;
            margin-bottom: 30px;
            /*border-radius: 30px;*/
            position: absolute;
            right: 0;
            top: 0;
            max-width: 300px;
            @include transition;

            @include media-breakpoint-down(xs) {
                display: none;
            }
            
            &:hover {
                background-color: rgba(0,0,0, .2);
            }
        }
    }
    
    .articles {
        
        .image {
            text-align: center;
            
            &.contraint-1-5 {
                @include media-breakpoint-down(md) { min-height: 236px; }                
                @include media-breakpoint-down(lg) { min-height: 207px; }
                @include media-breakpoint-up(lg) { min-height: 261px; }
                
            }
            .img-wrap {
                overflow: hidden;
                backface-visibility: hidden;
                outline: 0;
                transform: translateZ(0) scale(1,1);
                position: relative;
                .img-back {
                    display: none;
                    width: 100%;
                    height: auto;
                    filter: blur(3px) brightness(100%);
                    @media (min-width: $breakpoint-desktop-min) {
                        display: block;
                    }
                }
                picture {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (min-width: $breakpoint-desktop-min) {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }

        > div {
            margin-bottom: 30px;
            
            img {
                margin-bottom: 15px;
            }
            
            a {
                height: 100%;
                color: #333;
                border-bottom: 1px solid #000;
                display: block;

                h2 {
                    font-size: 22px;
                    line-height: 25px;
                    font-weight: 700;
                }

                p {
                    
                    &:last-child {
                        font-size: 15px;
                        line-height: 22px;
                        font-weight: 400;    
                    }
                }
            }
        }
        
        &.magazine {
            
            > div {
            
                a {
                    border: none;
                    height: auto;
                    text-align: center;
                    font-weight: 700;
                }
                
                .magazine-content-link {
                    margin-top: 10px;
                    font-size: 15px;
                }
            }
        }
    }
    
    &.test {
        
        .articles {
            
            > div {
                
                .article {
                    border-top: 1px solid rgba(0,0,0,.2);
                    padding-top: 5px;
                    float: left;
                    margin-bottom: 30px;
                    width: 100%;
                }
                
                .image, .content {
                    float: left;
                    width: 50%;
                    
                    img {
                        margin: 0;
                    }
                }

                .image {
                    padding-right: 15px;
                }

                .content {
                    padding-left: 15px;
                }
                
                a {
                    border: none;
                }
                
                h2 {
                    font-size: 22px;
                    line-height: 25px;
                    display: block;
                }
                
                .category {
                    font-size: 13px;
                    line-height: 22px;
                    font-weight: 700;
                    display: inline-block;
                    
                    > a {
                        color:  #ffa000;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    
    .col-71, .col-29 {
        width: 100%;
        float: left;
        min-height: 1px;
        padding: 0 15px;
    }
}

@media (min-width: 992px) {
    
    .col-71, .col-29 {
        float: left;
        min-height: 1px;
        padding: 0 15px;
    }
    
    .col-71 {
        width: 71%;
    }
    
    .col-29 {
        width: 29%;

        // Utility for floating elements
        .custom.float {
            &.absolute {
                position: absolute;
                top: auto;
                bottom: 0;
            }

            &.fixed {
                position: fixed;
                top: map-get($navbar-shrinked-height, xs);
                bottom: auto;
                
            }
        }
    }
}