.information {
    border-left: 3px solid #ffa000;
    padding: 15px;
    background-color: #ebebeb;
    margin-bottom: 15px;
        
    .title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 30px;
    }
}


// Legacy - keep for backward compatibility
.informacje {
    @extend .information;
    width: 100%;

    td { padding: 15px; }
    td > p:first-child {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 30px;
    }
}
