.section-firms-list {

    .firm {
        $height: (
            xs: 184px,
            sm: 324px,
            md: 203px,
            lg: 184px,
            xl: 223px
        );

        height: 100%;
        margin-bottom: 30px;

        .img-wrapper {
            $padding-vertical: 30px;
            $padding-horizontal: 45px;

            width: 100%;
            height: map-get($height, xs);
            padding: $padding-vertical $padding-horizontal;
            border: 1px solid $color-icon-grey;
            text-align: center;
            white-space: nowrap;

            @include media-breakpoint-up(sm) { height: map-get($height, sm); }
            @include media-breakpoint-up(md) { height: map-get($height, md); }
            @include media-breakpoint-up(lg) { height: map-get($height, lg); }
            @include media-breakpoint-up(xl) { height: map-get($height, xl); }

            img {
                max-width: 100%;
                max-height: map-get($height, xs) - (2 * $padding-vertical);

                @include media-breakpoint-up(sm) { max-height: map-get($height, sm) - (2 * $padding-vertical) };
                @include media-breakpoint-up(md) { max-height: map-get($height, md) - (2 * $padding-vertical) };
                @include media-breakpoint-up(lg) { max-height: map-get($height, lg) - (2 * $padding-vertical) };
                @include media-breakpoint-up(xl) { max-height: map-get($height, xl) - (2 * $padding-vertical) };
            }
        }

        .content-wrapper {
            font-size: 16px;
            text-align: center;

            .heading {
                margin: 25px 0 15px;
                font-size: 25px;
            }
            .categories { color: $color-icon-grey; }
        }

        // Modifier for free firm view
        &.firm-free {

            .content-wrapper {
                position: relative;
                height: map-get($height, xs);
                border: 1px solid $color-icon-grey;
                
                @include media-breakpoint-up(sm) { height: map-get($height, sm); }
                @include media-breakpoint-up(md) { height: map-get($height, md); }
                @include media-breakpoint-up(lg) { height: map-get($height, lg); }
                @include media-breakpoint-up(xl) { height: map-get($height, xl); }
            
                .inner {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    padding: 0 15px;
                    transform: translate(-50%, -50%);

                    .heading { margin-top: 0; }
                }
            }
        }
    }
}

.section-brands-list {
    margin-top: $section-margin-top;
}

.section-brand {
    margin-top: $section-margin-top;

    .firm-header {

        .heading {
            font-size: 45px;
            font-weight: 700;
        }
        
        .badge { margin: 5px 0; }
    }

    &-tests {

        .heading {
            margin-bottom: 50px;
            font-size: 30px;
        }
    }
}