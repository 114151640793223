.articles-gallery {
    overflow-x: auto;
    overflow-y: hidden;

    article {
        position: relative;
        min-width: 225px;
        max-height: 150px;
        background-image: linear-gradient(to bottom, transparent 35%, rgba(33, 33, 33, 1));

        @include media-breakpoint-up(md) {
            min-width: 350px;
            max-height: 318px;
        }

        .content-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 15px 10px;

            @include media-breakpoint-up(md) {
                padding: 30px 20px;
            }

            h2, h3 {
                font-size: 16px;
                line-height: 25px;

                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    line-height: 24px;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 25px;
                    line-height: 34px;
                }
            }
        }

        img {
            position: relative;
            width: 100%;
            height: auto;
            min-width: 225px;
            z-index: -1;

            @include media-breakpoint-up(md) {
                min-width: 350px;
            }
        }
    }
}