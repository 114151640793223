@mixin clearfix {
    &:after {
        display: table;
        content: "";
        clear: both;
    }
}

@mixin hover-image {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:after {
        transition: .3s;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.2);
        transform: rotateX(90deg);
    }

    &:before {
        transition: .3s;
        font-family: 'FontAwesome';
        content:'\f002';
        width: 50px;
        height: 50px;
        font-size: 30px;
        line-height: 50px;
        color: rgba(255, 255, 255, 0.2);
        text-align: center;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        z-index: 2;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.2);
        transform: rotateX(90deg);
    }

    &:hover:after, &:hover:before {
        transform: rotateX(0);
    }
}

@mixin hover-button($bg: rgba(0, 0, 0, 0.1)){
    position: relative;
    overflow: hidden;
    text-decoration: none;

    &:after {
        transition: .3s;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: -100%;
        top: 0;
        background: $bg;
    }

    &:hover:after {
        left: 0;
    }
}