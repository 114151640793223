.source {
    margin-bottom: 30px;
    
    .title {
        text-transform: uppercase;
        color:  #333333;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
    }
    
    .box {
        background-color: #dedede;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 15px;
        
        .image {
            width: 50%;
            float: left;
            
            img {
                width: 100%;
            }
        }
        
        .text {
            width: 50%;
            float: left;
            padding: 0 15px;
        }
        
        .name {
            font-weight: 700;
        }
        
        .number {
            color: #ffa000;
            margin-bottom: 15px;
        }
        
        
        .button {
            background-color: #ffa000;
            color: #fff;
            display: block;
            margin: 0 auto;
            font-size: 13px;
            font-weight: 700;
            padding: 10px 15px;
            text-align: center;
        }
    }
}
