.alphabet {
    &-header {
        background-color: $color-grey-dark-1;
        padding: 15px;

        @include media-breakpoint-up(xl) { padding: 15px 30px; }

        .letters {
            margin: 0;
            font-size: 20px;
            text-transform: uppercase;

            .item:not(:last-child) { 
                margin-right: 5px;
                @include media-breakpoint-up(xl) { margin-right: 9px; }
            }

            .link {
                color: $color-white;
                font-weight: 500;
            }
        }
    }

    .letter-row {
        padding: 20px 0;
        margin-bottom: 0;
        border-bottom: 2px solid $color-grey-light-1;

        .letter {
            float: left;
            width: 25px;

            font-size: 30px;
            line-height: 1;
            text-transform: uppercase;
        }

        .firms {
            font-size: 14px;
            line-height: 1.5;
            text-transform: uppercase;
        }
    }

    .firms a { display: block; }
}
