.firm-header {
    
    .img-wrapper {
        position: relative;
        width: 180px;
        height: 180px;
        margin: 0 auto 30px;
        padding: 30px;
        border: 1px solid $color-icon-grey;

        @include media-breakpoint-up(lg) {
            width: 205px;
            height: 205px;
            margin: 0 0 30px;
        }
        
        img { 
            position: absolute;
            top: 50%;
            left: 50%;
            max-height: 205px - (30px * 2);
            max-width: 205px - (30px * 2);
            transform: translate(-50%, -50%);
        }
    }

    .content-wrapper {
        text-align: center;

        @include media-breakpoint-up(md) { text-align: left; }

        .heading {
            padding: 0;
            margin: 0 0 10px;
            border: none;
            font-size: 25px;
            font-weight: 400;

            @include media-breakpoint-up(lg) {
                margin-top: 35px;
                font-size: 40px;
            }
        }
        p {
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 25px;

            .description { margin-top: 30px; }
        }

        .badge {
            margin: 20px 0 30px;
            font-size: 16px;
        }
    }
}