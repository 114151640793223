/* ===== Pomiary ===== */
.measurement {
    border: 2px solid #ffa000;
    margin-bottom: 15px;
    
    .title, h3 {
        background-color: #ffa000;
        color: #fff;
        font-size: 22px;
        padding: 10px 15px;
        margin: 0 0 15px;
    }
    
    .content {
        padding: 0 15px;
    }
    
    td {
        padding: 0 15px;
        
        h3 {
            margin: 0 -15px 15px;
        }
    }
}
/* ===== Pomiary end ===== */