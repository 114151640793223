.section-header {
    $padding-y: 20px;
    
    position: relative;
    padding: $padding-y 0;
    border-bottom: 3px solid $color-black;

    .heading {
        margin: 0;
        padding: 0;
        border: none;
        color: $color-primary-light;
        font-size: 28px;

        &-case {
            font-size: 45px;
            color: $color-black;
            
            font-weight: 700;
        }
    }

    .button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1001;

        .btn { padding: 15px 50px; }
    }

    .dropdown1 {
        display: none;
        position: absolute;
        right: 0;
        top: $padding-y;

        z-index: 1001;

        @include media-breakpoint-up(sm) { display: block; }
        
        &.product { top: 33px; }
    }
}
