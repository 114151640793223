.search-container {
    background: #fff;

    .searched-expression {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        padding: 35px 0;
    }

    .gs-no-results-result {
        .gs-snippet {
            border: none;
            background: none;
            text-align: center;
            font-size: 35px;
            font-weight: 700;
            margin-bottom: 20px;
            color: #000;
            &::before {
                content: '';
                background-image: url('/templates/main/images/search-error-icon.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                max-width: 100%;
                width: 100%;
                height: 360px;
                display: block;
                margin: 0 auto;
            }
            &::after {
                content: 'Niestety nic nie znaleźliśmy dla szukanej frazy';
                display: block;
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.5px;
            }
        }
    }
    
    .gsc-results {
        .gs-result {
            position: relative;
            min-height: 100px;
            
            .gsc-thumbnail-inside, .gsc-url-top, .gsc-table-cell-snippet-close {
                display: block;
                width: 85%;
                margin-left: auto;
                padding: 0;
            }
            
            .gsc-table-cell-thumbnail {
                position: absolute;
                top: 0;
                left: 0;
                width: 15%;
                height: 100%;
                
                .gs-image-box.gs-web-image-box {
                    width: 100%;
                    height: 100%;
                    padding-right: 12px;
                    text-align: right;

                    img.gs-image {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
        
        .gs-spelling a {
            color: #333333;
            text-decoration: none;
        }
        
        .gs-title {
            height: auto;
            text-decoration: none;

            * {
                text-decoration: none;
            }
        }
        
        .gsc-cursor-box {
            border-bottom: 2px solid #333;
            font: 700 20px Rubik;
            position: relative;
            text-align: center;
            padding-bottom: 6px;

            .gsc-cursor-page {
                color: #333;
                font-size: 17px;
                display: inline-block;
                padding: 2px 8px;
                border-radius: 15px;

                &:hover {
                    background-color: #FF9F00;
                    text-decoration: none;
                }

                &.gsc-cursor-current-page {
                    background-color: #FF9F00;
                    height: 30px;
                    min-width: 30px;
                    color: #fff;
                    margin: 0 5px;
                    padding: 5px 10px 0;
                }
            }
        }
    }
    
    .gsc-search-box-tools {
        text-align: center;
        
        .gsc-search-box {
            td.gsc-input {
                display: inline-block;
                width: 60%;
                padding: 0;

                .gsc-input-box {
                    width: 100%;
                    margin: auto;
                    position: relative;
                    height: auto;
                    border-style: none none solid;

                    .gsib_a {
                        padding: 0;

                        .gsc-input {
                            max-width: none;
                            padding: 5px 25px 5px 10px !important;
                            background-position-x: 5px !important;
                            height: 29px !important;
                            font-weight: bold;
                            font-size: 14px;
                        }
                    }

                    .gsib_b {
                        position: absolute;
                        right: 0;
                        top: 3px;
                        width: auto;
                    }
                }
            }

            .gsc-search-button {
                display: inline-block;
                width: auto;

                .gsc-search-button {
                    padding: 8px 15px;
                    margin: 0 5px;
                }
            }
        }
    }
}

.search {
    .search-container {
        padding: 10px;
        
        .gsc-search-box td.gsc-input {
            padding: 15px 0;
            width: 100%;
        }
        
        .gsc-search-button-v2 svg {
            display: none;
        }
        
        button.gsc-search-button {
            display: block;
            height: 45px;
            background: #FF9F00;
            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;
            color: #fff;
            border: none;
            
            &:before {
                content: 'Wyszukaj';
            }
        }
    }
}