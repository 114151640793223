.featured-article {
    position: relative;
    padding: 12px;
    padding-top: 230px; // XXX: Consider relative top padding (based on VH).
    margin-bottom: 30px;
    background: linear-gradient(to bottom, transparent, rgba(25, 25, 25, 1));

    @include media-breakpoint-up(md) {
        padding: 60px;
        padding-top: 428px;
    }
    .big-img-link {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .bg-img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        z-index: -1;
    }

    .section-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 28px;
        background-color: rgba(0,0,0, 0.8);

        h2 {
            color: $color-primary-light;
            margin-bottom: 0;
            text-align: center;
        }

        .btn-show-more {
            position: absolute;
            top: 50%;
            right: 45px;
            transform: translateY(-50%);
            padding: 15px 20px;
            text-transform: uppercase;
            border: 2px solid $color-primary-light;
        }
    }

    article {
        max-width: none;
        color: $color-white;

        @include media-breakpoint-up(xl) {
            max-width: 540px;
        }

        .badge {
            display: inline-block;
            margin-bottom: 5px;
            padding: 5px;
            font-size: 13px;
        }

        h2 {
            margin-top: 0;
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom-width: 2px;
            font-size: 30px;
        }

        p {
            font-size: 15px;
            line-height: 1.5;
            text-align: center;

            @include media-breakpoint-up(md) {
                text-align: left;
            }
        }
    }

    a:link, a:visited { color: $color-white; }
}
