@font-face {
    font-family: 'FontAwesome';
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'icomoon';
    src: url('../../fonts/icomoon.eot');
    src: url('../../fonts/icomoon.eot#iefix') format('embedded-opentype'),
         url('../../fonts/icomoon.ttf') format('truetype'),
         url('../../fonts/icomoon.woff') format('woff'),
         url('../../fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-produkcja:before     { content: "\e901"; }
  .icon-handel:before        { content: "\e902"; }
  .icon-inne:before          { content: "\e900"; }
  .icon-uslugi:before        { content: "\e903"; }
  .icon-projektowanie:before { content: "\e904"; }

// FontAwesome
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &-bars::before            { content: "\f0c9"; }
    &-caret-down::before      { content: "\f0d7"; }
    &-caret-up::before        { content: "\f0d8"; }
    &-caret-right::before     { content: "\f0da"; }
    &-chevron-left::before    { content: "\f053"; }
    &-chevron-down::before    { content: "\f078"; }
    &-chevron-right::before   { content: "\f054"; }
    &-envelope::before        { content: "\f0e0"; }
    &-location-arrow::before  { content: "\f124"; }
    &-reply::before           { content: "\f112"; }
    &-share-square-o::before  { content: "\f045"; }
    &-search::before          { content: "\f002"; }
    &-times-circle-o::before  { content: "\f05c"; }
    &-envelope-o::before      { content: "\f003"; }
    &-times::before           { content: "\f00d"; }

    // Social media
    &-google-plus::before     { content: "\f0d5"; }
    &-facebook::before        { content: "\f09a"; }
    &-facebook-square::before { content: "\f082"; }
    &-instagram::before       { content: "\f16d"; }
    &-pinterest::before       { content: "\f0d2"; }
    &-twitter::before         { content: "\f099"; }
    &-youtube::before         { content: "\f167"; }
    &-angle-right::before     { content: "\f105"; }
    &-angle-left::before      { content: "\f104"; }
}

body {
    padding-top: map-get($navbar-full-height, xs);
    color: $color-grey-dark-2;
    background-color: $color-white;
    font-family: nimbus-sans, sans-serif;
    font-weight: 400;
    font-size: 16px;

    @include media-breakpoint-up(md) { padding-top: map-get($navbar-full-height, md); }
}

a, a:hover, a:focus, a:active, a:visited {
    color: $color-grey-dark-1;
    text-decoration: none;
    transition: color .25s ease-in 0s;
    cursor: pointer;

    &.link {
        color: $color-icon-grey;

        &:hover,
        &:focus      { color: $color-primary-light; }

        &-primary    { color: $color-primary-light; }
        &-dark       { color: $color-grey-dark-1;   }

        &-color {
            color: inherit;

            &:hover,
            &:focus  { color: $color-primary-light; }
        }
    }
}

.link1 {
    color: $color-black;
    text-decoration: none;
    transition: color .25s ease-in 0s;
    cursor: pointer;

    &:hover,
    &:focus { color: $color-primary-light; }

    &-primary {
        color: $color-primary-light;

        &:hover,
        &:focus { color: $color-black; }
    }

    &-grey {
        color: $color-grey-dark-1;

        &:hover,
        &:focus { color: $color-primary-light; }
    }
}



h1, h2, h3, h4, h5, h6 {
    margin-bottom: 30px;
    color: $color-grey-dark-2;
    border: none;
    font-weight: 700;

    &.bordered {
        padding: 10px 2px;
        border-bottom: 3px solid $color-black;
    }

    &.inverse {
        color: $color-white;
        border-bottom-color: $color-white;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 20px;

        &.bordered { padding: 20px 2px; }
    }
}

h1 {
    @include media-breakpoint-up(xl) {
        font-size: 40px;
    }
}

h2 {
    font-size: 20px;

    @include media-breakpoint-up(md) {
        font-size: 28px;
    };
}

h3 {
    margin-bottom: 15px;
    font-size: 16px;

    @include media-breakpoint-up(md) {
        margin-bottom: 25px;
        font-size: 25px;
    }
}
.link-primary {
    color: $color-primary-light;
}
