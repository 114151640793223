.navbar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    position: fixed;
    top: 0;

    width: 100%;
    height: map-get($navbar-full-height, xs);

    background-color: $navbar-bg;
    border-bottom: $navbar-border;

    z-index: 3001;

    transition: $transition-base;

    @include media-breakpoint-up(md) {
        height: map-get($navbar-full-height, md)
    };

    &-button {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;

        width:  map-get($navbar-full-height, xs);
        height: map-get($navbar-height, xs);

        transition: $transition-base;

        @include media-breakpoint-up(md) {
            width:  map-get($navbar-full-height, md);
            height: map-get($navbar-height, md);
        }

        &:hover { cursor: pointer; }

        &.left {
            margin-right: auto;
            border-right: $navbar-border;
        }
        &.right {
            margin-left: auto;
            border-left: $navbar-border;
        }
    }

    &-wrapper {
        display: flex;
        flex: 0 1 1200px;
        flex-wrap: nowrap;
        justify-content: center;

        @include media-breakpoint-up(md) { justify-content: space-around; }
        @include media-breakpoint-up(xl) { justify-content: space-between; }
    }

    &-brand {
        flex: 0 0 121px;
        align-self: center;
        margin: 0 10px;

        transition: $transition-base;

        @include media-breakpoint-up(md) { flex: 0 0 161px; }

        img {
            width: 100%;
            height: auto;
        }
    }

    &-nav {
        display: none;
        align-items: center;

        height: 100%;
        margin: 0;
        padding: 0;

        font-size: 0;
        line-height: 0;
        list-style: none;

        @include media-breakpoint-up(xl) {
            display: flex;
        }

        .nav-item {
            display: table;
            float: left;
            height: 100%;

            font-size: 17px;
            font-weight: 500;

            line-height: 1;
            letter-spacing: -0.05rem;
            list-style-type: none;

            cursor: pointer;

            // Show dropdown menu on hover
            &:hover {
                & > .nav-link::after { width: 100%; }
                & > .navbar-dropdown { display: block; }
            }

            &.active > .nav-link::after { width: 100%; }
        }

        .nav-link {
            display: table-cell;
            position: relative;
            padding: 0 10px;
            vertical-align: middle;

            @media (min-width: 1366px) { padding: 0 17px; }

            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -$navbar-border-width;

                width: 0;
                height: $navbar-nav-border-width;

                background-color: $color-primary-light;
                transition: width .25s ease-out 0s;
            }
        }
    }

    &-new-issue {
        display: none;
        height: 100%;
        margin: 0 10px;

        @include media-breakpoint-up(md) { display: flex; }

        // Show dropdown on large devices
        @include media-breakpoint-up(lg) {
            &:hover {
                cursor: pointer;

                & > .navbar-dropdown {
                    transform: scale3d(1,1,1);
                    visibility: visible;
                }
            }
        }

        .content-wrapper {
            align-self: center;
            padding: 0 10px;

            @media (min-width: 1366px) { padding: 0 20px 0 10px; }

            p { margin: 0; }
        }

        .img-wrapper {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }

            @include media-breakpoint-up(xl) {
                display: none;
            }

            @media (min-width: map-get($grid-breakpoints, xl) + 56px) {
                display: block;
            }

            img {
                width: auto;
                height: 100%;
            }
        }
    }

    &-dropdown {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;

        margin-top: 1px;
        padding: 25px;

        background-color: $color-white;
        box-shadow: 0 15px 55px -10px rgba($color-black, 0.4);

        text-align: left;
        z-index: 1;
        cursor: auto;

        a:hover, a:focus, a:active { color: $color-primary-light; }

        &.dropdown-submenu {
            &::before {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 0;
                width: 100%;
                height: 0.25 * map-get($navbar-full-height, xs);

                @include media-breakpoint-up(md) {
                    height: 0.25 * map-get($navbar-full-height, md);
                }
            }
        }

        // Search dropdown
        &.dropdown-search {

            .search-close {
                width: auto;
                text-align: center;

                .close-btn {
                    color: #7f7f7f;
                    border: 2px solid #7f7f7f;
                    width: 50px;
                    height: 50px;
                    margin: 0 auto 30px;
                    border-radius: 25px;
                    position: relative;

                    &::before, &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 24px;
                        height: 2px;
                        background-color: #7f7f7f;
                        top: 22px;
                        left: 11px;
                    }

                    &::before {
                        transform: rotate(45deg);
                    }

                    &::after {
                        transform: rotate(-45deg);
                    }

                    &:hover { cursor: pointer; }
                }
            }

            .container { max-width: 400px; }

            form {
                & > input {
                    display: block;
                    width: 100%;

                    margin-top: 35px;
                    padding: 10px 2px;

                    border: 0;
                    border-bottom: 3px solid #333333;

                    font-size: 16px;
                    font-weight: 500;

                    &:focus {
                        border-bottom-color: $color-primary-light;
                    }
                }

                & > button {
                    // @extend .btn;
                    // @extend .btn-primary;

                    display: block;
                    margin: 40px auto 0 auto;
                    padding: 15px 60px;
                    color: $color-white;
                }
            }
        }

        &.dropdown-new-issue {
            display: block;
            padding: 60px 0 28px;

            background-color: #f5f5f5;
            transform-origin: 50% 0;
            visibility: hidden;
            backface-visibility: hidden;

            transform: scale3d(0, 0, 0.1);
            transition: all .5s ease 0s;

            .issue-wrapper {
                max-width: 1060px;
                margin: 0 auto;
                padding: 25px 25px 25px 34px;

                background: #fff;
                box-shadow: 0 40px 70px 0 rgba(0,0,0,0.2);

                text-align: left;
                @include clearfix;

                .left-col {
                    float: left;
                    width: 660px;
                    @include clearfix;
                }

                .title {
                    margin: 0 0 24px;
                    font-size: 35px;
                    color: $color-grey-dark-2;
                }

                .buy-block {
                    position: relative;
                    width: 320px;
                    height: 162px;
                    border: 1px solid #cfcfcf;
                    border-radius: 3px;
                    padding: 24px 16px 18px;
                    font-size: 13px;

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 28px;
                        left: 33px;
                    }

                    &.printed {
                        float: left;

                        &:before {
                            width: 50px;
                            height: 46px;
                            background: url('/templates/main/images/sprite.png') -56px -38px no-repeat;
                        }
                    }

                    &.electronic {
                        float: right;

                        &:before {
                            width: 40px;
                            height: 52px;
                            background: url('/templates/main/images/sprite.png') -13px -38px no-repeat;
                        }
                    }

                    .buy-title {
                        padding: 0 0 0 88px;
                        font-weight: bold;
                        text-transform: uppercase;
                        color: #12121a;
                    }

                    .buy-text {
                        color: #848484;
                        margin: 10px 0 0;
                        padding: 0 0 0 88px;
                        line-height: 16px;
                    }

                    a {
                        display: block;
                        position: relative;
                        margin: 18px 0 0;
                        height: 45px;
                        background: $color-primary-light;
                        border-radius: 2px;
                        line-height: 45px;
                        color: #fff;
                        text-decoration: none;
                        font-size: 13px;
                        font-weight: bold;
                        &.inactive {
                            color: #898989;
                            border: 1px solid #898989;
                            cursor: inherit;
                            background-color: #fff;
                            text-align: center;
                            span {
                                display: none;
                            }
                            &::before {
                                display: none;
                            }
                            &:hover {
                                background-color: #fff;
                            }
                        }
                        &:hover {
                            background: darken($color-primary-light, 10%);
                        }

                        span {
                            display: inline-block;
                            width: 88px;
                            vertical-align: middle;
                            text-align: center;
                            margin: 0 12px 0 0;
                            font-size: 20px;
                            font-weight: 900;
                        }

                        &:before {
                            display: block;
                            position: absolute;
                            content: '';
                            width: 1px;
                            height: 35px;
                            background: darken($color-primary-light, 10%);
                            left: 88px;
                            top: 5px;
                        }
                    }
                }

                .subscription,
                .see-demo {
                    width: 320px;
                    height: 80px;
                    margin: 13px 0 0;
                    background: #f0f0f0;
                    border-radius: 3px;

                    a {
                        display: block;
                        height: 45px;
                        position: relative;
                        border: 1px solid $color-primary-light;
                        border-radius: 3px;
                        background: #fff;
                        text-align: center;
                        text-decoration: none;
                        line-height: 43px;
                        color: $color-primary-light;
                        font-size: 13px;
                        font-weight: bold;
                        &.inactive {
                            background-color: #fff;
                            color: #898989;
                            border: 1px solid #898989;
                            cursor: inherit;
                            &:before {
                                display: none;
                            }
                            &:hover {
                                background-color: #fff;
                                color: #898989;
                            }
                        }
                        span {
                            position: relative;
                            z-index: 1;
                        }

                        &:before {
                            display: block;
                            position: absolute;
                            content: '';
                            top: 0;
                            right: 0;
                            bottom: 0;
                            background: $color-primary-light;
                            width: 0;
                            transition: width .25s ease;
                        }

                        &:hover {
                            color: #fff;

                            &:before {
                                width: 100%;
                                left: 0;
                                right: auto;
                            }
                        }
                    }
                }

                .subscription {
                    float: left;
                    @include clearfix;

                    .sub-text {
                        float: left;
                        margin: 33px 0 0 32px;
                        font-size: 13px;
                        font-weight: bold;
                        color: #12121a;
                        text-transform: uppercase;
                    }

                    a {
                        width: 152px;
                        margin: 17px 19px 0 0;
                        float: right;
                    }
                }

                .see-demo {
                    float: right;

                    a {
                        width: 194px;
                        margin: 17px auto 0;
                    }
                }

                .cover {
                    float: right;
                    display: block;
                    width: calc(100% - 680px);
                    max-width: 300px;
                    margin: -55px 0 0;
                    box-shadow: 0 20px 24px 0 rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    .fa {
        color: $color-icon-grey;
        font-size: 16px;

        @include media-breakpoint-up(md) {
            font-size: 20px;
        }
    }

    // Shrinked navbar
    &-shrinked {
        height: map-get($navbar-shrinked-full-height, xs);

        @include media-breakpoint-up(md) {
            height: map-get($navbar-shrinked-full-height, md);
        }

        .navbar-button {
            width:  map-get($navbar-shrinked-full-height, xs);
            height: map-get($navbar-shrinked-height, xs);

            @include media-breakpoint-up(md) {
                width:  map-get($navbar-shrinked-full-height, md);
                height: map-get($navbar-shrinked-height, md);
            }
        }

        .navbar-brand { flex: 0 0 121px; }

        .navbar-nav {
            .nav-link { font-size: 16px; }
        }

        .navbar-new-issue {
            line-height: 1.2;

            .img-wrapper img { display: none; }

            .badge {
                padding: 3px;
                font-size: 11px;
            }

            p { font-size: 11px; }
        }

        .navbar-dropdown {
            &.dropdown-submenu {
                &::before {
                    height: 0.25 * map-get($navbar-shrinked-full-height, xs);
                }
            }
        }

        .fa { font-size: 16px; }
    }
}

.left-toggle {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 60px);
    height: 100vh;
    max-width: 406px;

    padding: 30px;

    background-color: $color-white;
    box-shadow: 0 20px 20px -5px rgba(0,0,0,0.4);

    text-align: left;
    z-index: 2;
    overflow-y: auto;

    .social {
        margin-bottom: 40px;
        text-align: center;

        a:not(:last-child) { margin-right: 40px; }
    }

    // Left toggle menu
    & > ul#menu-left {
        display: block;
        margin-bottom: 30px;
        padding: 0;
        list-style: none;

        li { list-style-type: none; }

        & > li {
            font-size: 16px;
            font-weight: 700;

            & > span, & > a {
                display:block;
                padding: 18px 5px;
                border-bottom: 2px solid #d78600;
            }

            & > a {
                border-bottom: none;
            }

            // Bootstrap style fix
            & > a:hover {
                background-color: initial;
            }

            // Left toggle submenu
            & > ul {
                padding: 0;

                li {
                    border-bottom: 1px solid #d9d9d9;
                    font-size: 16px;
                    font-weight: 400;

                    a {
                        transition: all .25s ease;

                        &:link, &:visited {
                            display: block;
                            padding: 20px 20px;
                            color: #333;
                        }

                        &:hover, &:focus {
                            padding-left: 40px;
                        }
                    }
                }
            }
        }
    }

    // Left toggle new issue
    .new-issue {
        text-align: center;

        .btn-newsletter {
            display: block;
            padding: 25px;
            margin-bottom: 40px;

            background-color: #f2f2f2;
            border: 1px solid #8c8c8c;
            border-radius: 0;

            font-size: 12px;
            font-weight: 400;
            text-align: center;
            text-transform: uppercase;
        }

        img {
            margin: 0 auto;
            max-width: 160px;
            height: auto;
        }

        .new-issue-label {
            margin: 0 -30px 0 -30px;
            padding: 10px;
            color: $color-white;
            background-color: $color-primary-light;

            p { margin: 0; }

            .header {
                color: #202221;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
            }

            .date {
                color: #78641b; /* CHANGE to RGBA */
                font-size: 14px;
            }

            .btn-purchase {
                display: inline-block;
                margin-top: 10px;
                padding: 0;
                color: $color-white;
                border: 0;
                border-radius: 0;
                font-size: 12px;
                font-weight: 900;
            }
        }
    }

    #exit-btn {
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
    }
}
