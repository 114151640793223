$error_yellow: #ffa000;

.error_header {
    border-bottom: 1px solid #d7d7d7;
    min-height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .logo {
        display: block;
        img {
            width:153px;
        }
    }
}
.error_main{
    text-align: center;
    margin-bottom: 100px;
    .error_info{
        margin-top: 40px;
        font-size: 18px;
        font-weight: 400;
    }
    .error_additional_info{
        margin-top: 40px;
        font-size: 18px;
        font-weight: 400;
        a{
            color: $error_yellow;
        }
    }
    .error_image{
        img{
            @media(max-width: 767px){
                width: 100%;
            }
        }
        .error_code{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            height: 110px;
            font-size: 110px;
            line-height: 110px;
            color: $error_yellow;
        }
    }
    .search{
        font-size: 0;
        margin-top: 40px;
        input, .button_search{
            display: inline-block;
            height: 45px;
            vertical-align: top;
        }
        input{
            font-size: 18px;
            border: none;
            box-shadow: none;
            border: 2px solid black;
            width: 100%;
            @media(min-width: 768px){
                border: none;
                border-bottom: 2px solid black;
                max-width: 400px;
            }
        }
        .button_search{
            width: 100%;
            line-height: 45px;
            text-align: center;
            font-size: 13px;
            background-color: $error_yellow;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            @media(max-width:767px){
                margin-top: 40px;
            }
            @media(min-width: 768px){
                max-width: 165px;
            }
        }
    }
}
