@charset "UTF-8";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ff9f00;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1025px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2.5px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #ff9f00;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #b36f00;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 2.5px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 5px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1025px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-limited, .container-fluid-limited {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1025px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff9f00; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 2.5px;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #ff9f00; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #cc7f00; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 2.5px 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ff9f00; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #ff9f00 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #cc7f00 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ff9f00 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1025px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1025px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1025px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 5px !important; }

.mt-1,
.my-1 {
  margin-top: 5px !important; }

.mr-1,
.mx-1 {
  margin-right: 5px !important; }

.mb-1,
.my-1 {
  margin-bottom: 5px !important; }

.ml-1,
.mx-1 {
  margin-left: 5px !important; }

.m-2 {
  margin: 10px !important; }

.mt-2,
.my-2 {
  margin-top: 10px !important; }

.mr-2,
.mx-2 {
  margin-right: 10px !important; }

.mb-2,
.my-2 {
  margin-bottom: 10px !important; }

.ml-2,
.mx-2 {
  margin-left: 10px !important; }

.m-3 {
  margin: 15px !important; }

.mt-3,
.my-3 {
  margin-top: 15px !important; }

.mr-3,
.mx-3 {
  margin-right: 15px !important; }

.mb-3,
.my-3 {
  margin-bottom: 15px !important; }

.ml-3,
.mx-3 {
  margin-left: 15px !important; }

.m-4 {
  margin: 20px !important; }

.mt-4,
.my-4 {
  margin-top: 20px !important; }

.mr-4,
.mx-4 {
  margin-right: 20px !important; }

.mb-4,
.my-4 {
  margin-bottom: 20px !important; }

.ml-4,
.mx-4 {
  margin-left: 20px !important; }

.m-5 {
  margin: 25px !important; }

.mt-5,
.my-5 {
  margin-top: 25px !important; }

.mr-5,
.mx-5 {
  margin-right: 25px !important; }

.mb-5,
.my-5 {
  margin-bottom: 25px !important; }

.ml-5,
.mx-5 {
  margin-left: 25px !important; }

.m-6 {
  margin: 30px !important; }

.mt-6,
.my-6 {
  margin-top: 30px !important; }

.mr-6,
.mx-6 {
  margin-right: 30px !important; }

.mb-6,
.my-6 {
  margin-bottom: 30px !important; }

.ml-6,
.mx-6 {
  margin-left: 30px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 5px !important; }

.pt-1,
.py-1 {
  padding-top: 5px !important; }

.pr-1,
.px-1 {
  padding-right: 5px !important; }

.pb-1,
.py-1 {
  padding-bottom: 5px !important; }

.pl-1,
.px-1 {
  padding-left: 5px !important; }

.p-2 {
  padding: 10px !important; }

.pt-2,
.py-2 {
  padding-top: 10px !important; }

.pr-2,
.px-2 {
  padding-right: 10px !important; }

.pb-2,
.py-2 {
  padding-bottom: 10px !important; }

.pl-2,
.px-2 {
  padding-left: 10px !important; }

.p-3 {
  padding: 15px !important; }

.pt-3,
.py-3 {
  padding-top: 15px !important; }

.pr-3,
.px-3 {
  padding-right: 15px !important; }

.pb-3,
.py-3 {
  padding-bottom: 15px !important; }

.pl-3,
.px-3 {
  padding-left: 15px !important; }

.p-4 {
  padding: 20px !important; }

.pt-4,
.py-4 {
  padding-top: 20px !important; }

.pr-4,
.px-4 {
  padding-right: 20px !important; }

.pb-4,
.py-4 {
  padding-bottom: 20px !important; }

.pl-4,
.px-4 {
  padding-left: 20px !important; }

.p-5 {
  padding: 25px !important; }

.pt-5,
.py-5 {
  padding-top: 25px !important; }

.pr-5,
.px-5 {
  padding-right: 25px !important; }

.pb-5,
.py-5 {
  padding-bottom: 25px !important; }

.pl-5,
.px-5 {
  padding-left: 25px !important; }

.p-6 {
  padding: 30px !important; }

.pt-6,
.py-6 {
  padding-top: 30px !important; }

.pr-6,
.px-6 {
  padding-right: 30px !important; }

.pb-6,
.py-6 {
  padding-bottom: 30px !important; }

.pl-6,
.px-6 {
  padding-left: 30px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 5px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 5px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 5px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 5px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 5px !important; }
  .m-sm-2 {
    margin: 10px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 10px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 10px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 10px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 10px !important; }
  .m-sm-3 {
    margin: 15px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 15px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 15px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 15px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 15px !important; }
  .m-sm-4 {
    margin: 20px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 20px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 20px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 20px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 20px !important; }
  .m-sm-5 {
    margin: 25px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 25px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 25px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 25px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 25px !important; }
  .m-sm-6 {
    margin: 30px !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 30px !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 30px !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 30px !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 30px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 5px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 5px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 5px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 5px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 5px !important; }
  .p-sm-2 {
    padding: 10px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 10px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 10px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 10px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 10px !important; }
  .p-sm-3 {
    padding: 15px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 15px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 15px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 15px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 15px !important; }
  .p-sm-4 {
    padding: 20px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 20px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 20px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 20px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 20px !important; }
  .p-sm-5 {
    padding: 25px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 25px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 25px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 25px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 25px !important; }
  .p-sm-6 {
    padding: 30px !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 30px !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 30px !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 30px !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 30px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 5px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 5px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 5px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 5px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 5px !important; }
  .m-md-2 {
    margin: 10px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 10px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 10px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 10px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 10px !important; }
  .m-md-3 {
    margin: 15px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 15px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 15px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 15px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 15px !important; }
  .m-md-4 {
    margin: 20px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 20px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 20px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 20px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 20px !important; }
  .m-md-5 {
    margin: 25px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 25px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 25px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 25px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 25px !important; }
  .m-md-6 {
    margin: 30px !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 30px !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 30px !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 30px !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 30px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 5px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 5px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 5px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 5px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 5px !important; }
  .p-md-2 {
    padding: 10px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 10px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 10px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 10px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 10px !important; }
  .p-md-3 {
    padding: 15px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 15px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 15px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 15px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 15px !important; }
  .p-md-4 {
    padding: 20px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 20px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 20px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 20px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 20px !important; }
  .p-md-5 {
    padding: 25px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 25px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 25px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 25px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 25px !important; }
  .p-md-6 {
    padding: 30px !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 30px !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 30px !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 30px !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 30px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1025px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 5px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 5px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 5px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 5px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 5px !important; }
  .m-lg-2 {
    margin: 10px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 10px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 10px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 10px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 10px !important; }
  .m-lg-3 {
    margin: 15px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 15px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 15px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 15px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 15px !important; }
  .m-lg-4 {
    margin: 20px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 20px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 20px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 20px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 20px !important; }
  .m-lg-5 {
    margin: 25px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 25px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 25px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 25px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 25px !important; }
  .m-lg-6 {
    margin: 30px !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 30px !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 30px !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 30px !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 30px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 5px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 5px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 5px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 5px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 5px !important; }
  .p-lg-2 {
    padding: 10px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 10px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 10px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 10px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 10px !important; }
  .p-lg-3 {
    padding: 15px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 15px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 15px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 15px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 15px !important; }
  .p-lg-4 {
    padding: 20px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 20px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 20px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 20px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 20px !important; }
  .p-lg-5 {
    padding: 25px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 25px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 25px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 25px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 25px !important; }
  .p-lg-6 {
    padding: 30px !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 30px !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 30px !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 30px !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 30px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 5px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 5px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 5px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 5px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 5px !important; }
  .m-xl-2 {
    margin: 10px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 10px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 10px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 10px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 10px !important; }
  .m-xl-3 {
    margin: 15px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 15px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 15px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 15px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 15px !important; }
  .m-xl-4 {
    margin: 20px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 20px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 20px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 20px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 20px !important; }
  .m-xl-5 {
    margin: 25px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 25px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 25px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 25px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 25px !important; }
  .m-xl-6 {
    margin: 30px !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 30px !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 30px !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 30px !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 30px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 5px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 5px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 5px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 5px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 5px !important; }
  .p-xl-2 {
    padding: 10px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 10px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 10px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 10px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 10px !important; }
  .p-xl-3 {
    padding: 15px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 15px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 15px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 15px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 15px !important; }
  .p-xl-4 {
    padding: 20px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 20px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 20px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 20px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 20px !important; }
  .p-xl-5 {
    padding: 25px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 25px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 25px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 25px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 25px !important; }
  .p-xl-6 {
    padding: 30px !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 30px !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 30px !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 30px !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 30px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1025px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #ff9f00 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #cc7f00 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@font-face {
  font-family: 'FontAwesome';
  src: url("https://cdn.avt.pl/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("https://cdn.avt.pl/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'icomoon';
  src: url("../../fonts/icomoon.eot");
  src: url("../../fonts/icomoon.eot#iefix") format("embedded-opentype"), url("../../fonts/icomoon.ttf") format("truetype"), url("../../fonts/icomoon.woff") format("woff"), url("../../fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-produkcja:before {
  content: "\e901"; }

.icon-handel:before {
  content: "\e902"; }

.icon-inne:before {
  content: "\e900"; }

.icon-uslugi:before {
  content: "\e903"; }

.icon-projektowanie:before {
  content: "\e904"; }

.fa, .dropdown .toggle-bars::before, .dropdown1-section .dropdown-active .dropdown-link:after, .pasaz-module .pasaz-content .owl-nav .owl-prev::before, .pasaz-module .pasaz-content .owl-nav .owl-next::before, .newsletter .select-box:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .fa-bars::before, .dropdown .toggle-bars::before {
    content: "\f0c9"; }
  .fa-caret-down::before, .dropdown1-section .dropdown-active .dropdown-link:after {
    content: "\f0d7"; }
  .fa-caret-up::before, .dropdown.show .toggle-bars::before, .dropdown1-section.open .dropdown-active .dropdown-link:after {
    content: "\f0d8"; }
  .fa-caret-right::before {
    content: "\f0da"; }
  .fa-chevron-left::before {
    content: "\f053"; }
  .fa-chevron-down::before {
    content: "\f078"; }
  .fa-chevron-right::before {
    content: "\f054"; }
  .fa-envelope::before {
    content: "\f0e0"; }
  .fa-location-arrow::before {
    content: "\f124"; }
  .fa-reply::before {
    content: "\f112"; }
  .fa-share-square-o::before {
    content: "\f045"; }
  .fa-search::before {
    content: "\f002"; }
  .fa-times-circle-o::before {
    content: "\f05c"; }
  .fa-envelope-o::before {
    content: "\f003"; }
  .fa-times::before {
    content: "\f00d"; }
  .fa-google-plus::before {
    content: "\f0d5"; }
  .fa-facebook::before {
    content: "\f09a"; }
  .fa-facebook-square::before {
    content: "\f082"; }
  .fa-instagram::before {
    content: "\f16d"; }
  .fa-pinterest::before {
    content: "\f0d2"; }
  .fa-twitter::before {
    content: "\f099"; }
  .fa-youtube::before {
    content: "\f167"; }
  .fa-angle-right::before, .pasaz-module .pasaz-content .owl-nav .owl-next::before {
    content: "\f105"; }
  .fa-angle-left::before, .pasaz-module .pasaz-content .owl-nav .owl-prev::before {
    content: "\f104"; }

body {
  padding-top: 51px;
  color: #212121;
  background-color: #fff;
  font-family: nimbus-sans, sans-serif;
  font-weight: 400;
  font-size: 16px; }
  @media (min-width: 768px) {
    body {
      padding-top: 101px; } }

a, a:hover, a:focus, a:active, a:visited {
  color: #333;
  text-decoration: none;
  transition: color .25s ease-in 0s;
  cursor: pointer; }
  a.link, a:hover.link, a:focus.link, a:active.link, a:visited.link {
    color: #7c7c7c; }
    a.link:hover, a.link:focus, a:hover.link:hover, a:hover.link:focus, a:focus.link:hover, a:focus.link:focus, a:active.link:hover, a:active.link:focus, a:visited.link:hover, a:visited.link:focus {
      color: #ff9f00; }
    a.link-primary, a:hover.link-primary, a:focus.link-primary, a:active.link-primary, a:visited.link-primary {
      color: #ff9f00; }
    a.link-dark, a:hover.link-dark, a:focus.link-dark, a:active.link-dark, a:visited.link-dark {
      color: #333; }
    a.link-color, a:hover.link-color, a:focus.link-color, a:active.link-color, a:visited.link-color {
      color: inherit; }
      a.link-color:hover, a.link-color:focus, a:hover.link-color:hover, a:hover.link-color:focus, a:focus.link-color:hover, a:focus.link-color:focus, a:active.link-color:hover, a:active.link-color:focus, a:visited.link-color:hover, a:visited.link-color:focus {
        color: #ff9f00; }

.link1, .dropdown1 .dropdown-link {
  color: #000;
  text-decoration: none;
  transition: color .25s ease-in 0s;
  cursor: pointer; }
  .link1:hover, .dropdown1 .dropdown-link:hover, .link1:focus, .dropdown1 .dropdown-link:focus {
    color: #ff9f00; }
  .link1-primary {
    color: #ff9f00; }
    .link1-primary:hover, .link1-primary:focus {
      color: #000; }
  .link1-grey {
    color: #333; }
    .link1-grey:hover, .link1-grey:focus {
      color: #ff9f00; }

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 30px;
  color: #212121;
  border: none;
  font-weight: 700; }
  h1.bordered, h2.bordered, h3.bordered, h4.bordered, h5.bordered, h6.bordered {
    padding: 10px 2px;
    border-bottom: 3px solid #000; }
  h1.inverse, h2.inverse, h3.inverse, h4.inverse, h5.inverse, h6.inverse {
    color: #fff;
    border-bottom-color: #fff; }
  @media (min-width: 768px) {
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 20px; }
      h1.bordered, h2.bordered, h3.bordered, h4.bordered, h5.bordered, h6.bordered {
        padding: 20px 2px; } }

@media (min-width: 1200px) {
  h1 {
    font-size: 40px; } }

h2 {
  font-size: 20px; }
  @media (min-width: 768px) {
    h2 {
      font-size: 28px; } }

h3 {
  margin-bottom: 15px;
  font-size: 16px; }
  @media (min-width: 768px) {
    h3 {
      margin-bottom: 25px;
      font-size: 25px; } }

.link-primary {
  color: #ff9f00; }

.u-show {
  display: block !important; }

.u-hide {
  display: none !important; }

.u-position-static {
  position: static !important; }

.u-position-fixed {
  position: fixed !important; }

.u-margin-none {
  margin: 0 !important; }

.u-margin-top-none {
  margin-top: 0 !important; }

.u-margin-top-small {
  margin-top: 15px !important; }

.u-margin-top-medium {
  margin-top: 30px !important; }

.u-margin-top-big {
  margin-top: 45px !important; }

.u-margin-top-huge {
  margin-top: 166px !important; }

.u-margin-bottom-none {
  margin-bottom: 0 !important; }

.u-margin-bottom-small {
  margin-bottom: 15px !important; }

.u-margin-bottom-medium {
  margin-bottom: 30px !important; }

.u-margin-bottom-big {
  margin-bottom: 45px !important; }

.u-margin-bottom-huge {
  margin-bottom: 60px !important; }

.u-padding-none {
  padding: 0 !important; }

.u-padding-left-none {
  padding-left: 0 !important; }

.u-padding-top-none {
  padding-top: 0 !important; }

.u-padding-top-small {
  padding-top: 15px !important; }

.u-padding-bottom-medium {
  padding-bottom: 30px !important; }

.u-color-primary {
  color: #ff9f00; }

.u-color-secondary {
  color: #f6ca13; }

.u-center-text {
  text-align: center !important; }

.u-sticky {
  position: fixed;
  top: 51px; }

.u-section-margin {
  margin-top: 50px; }

.u-bg-parallax {
  background-attachment: fixed;
  background-size: cover; }

.u-bg {
  background-color: rgba(0, 255, 255, 0.1); }
  .u-bg-1 {
    background-color: rgba(218, 112, 214, 0.1); }

.u-advertisement {
  display: flex;
  margin: 0 auto 30px auto;
  border: 2px solid #ccc; }
  .u-advertisement div {
    display: 0 0 auto;
    width: 100%;
    align-self: center;
    color: #ccc;
    font-size: 36px;
    text-align: center; }

.img-aligner {
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

picture.lazy {
  display: block; }

.container-limited, .container-fluid-limited {
  max-width: 1200px; }

.col-29-f,
.col-71-f {
  position: relative;
  flex: 0 0 71%;
  width: 100%;
  min-height: 1px;
  max-width: 71%;
  padding-left: 15px;
  padding-right: 15px; }

.col-29-f {
  flex: 0 0 29%;
  max-width: 29%; }

.col-71-f {
  flex: 0 0 71%;
  max-width: 71%; }

.navbar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  height: 51px;
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;
  z-index: 3001;
  transition: all 0.2s ease 0s; }
  @media (min-width: 768px) {
    .navbar {
      height: 101px; } }
  .navbar-button {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 50px;
    transition: all 0.2s ease 0s; }
    @media (min-width: 768px) {
      .navbar-button {
        width: 101px;
        height: 100px; } }
    .navbar-button:hover {
      cursor: pointer; }
    .navbar-button.left {
      margin-right: auto;
      border-right: 1px solid #dbdbdb; }
    .navbar-button.right {
      margin-left: auto;
      border-left: 1px solid #dbdbdb; }
  .navbar-wrapper {
    display: flex;
    flex: 0 1 1200px;
    flex-wrap: nowrap;
    justify-content: center; }
    @media (min-width: 768px) {
      .navbar-wrapper {
        justify-content: space-around; } }
    @media (min-width: 1200px) {
      .navbar-wrapper {
        justify-content: space-between; } }
  .navbar-brand {
    flex: 0 0 121px;
    align-self: center;
    margin: 0 10px;
    transition: all 0.2s ease 0s; }
    @media (min-width: 768px) {
      .navbar-brand {
        flex: 0 0 161px; } }
    .navbar-brand img {
      width: 100%;
      height: auto; }
  .navbar-nav {
    display: none;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    list-style: none; }
    @media (min-width: 1200px) {
      .navbar-nav {
        display: flex; } }
    .navbar-nav .nav-item {
      display: table;
      float: left;
      height: 100%;
      font-size: 17px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: -0.05rem;
      list-style-type: none;
      cursor: pointer; }
      .navbar-nav .nav-item:hover > .nav-link::after {
        width: 100%; }
      .navbar-nav .nav-item:hover > .navbar-dropdown {
        display: block; }
      .navbar-nav .nav-item.active > .nav-link::after {
        width: 100%; }
    .navbar-nav .nav-link {
      display: table-cell;
      position: relative;
      padding: 0 10px;
      vertical-align: middle; }
      @media (min-width: 1366px) {
        .navbar-nav .nav-link {
          padding: 0 17px; } }
      .navbar-nav .nav-link::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 0;
        height: 4px;
        background-color: #ff9f00;
        transition: width .25s ease-out 0s; }
  .navbar-new-issue {
    display: none;
    height: 100%;
    margin: 0 10px; }
    @media (min-width: 768px) {
      .navbar-new-issue {
        display: flex; } }
    @media (min-width: 1025px) {
      .navbar-new-issue:hover {
        cursor: pointer; }
        .navbar-new-issue:hover > .navbar-dropdown {
          transform: scale3d(1, 1, 1);
          visibility: visible; } }
    .navbar-new-issue .content-wrapper {
      align-self: center;
      padding: 0 10px; }
      @media (min-width: 1366px) {
        .navbar-new-issue .content-wrapper {
          padding: 0 20px 0 10px; } }
      .navbar-new-issue .content-wrapper p {
        margin: 0; }
    .navbar-new-issue .img-wrapper {
      display: none; }
      @media (min-width: 768px) {
        .navbar-new-issue .img-wrapper {
          display: block; } }
      @media (min-width: 1200px) {
        .navbar-new-issue .img-wrapper {
          display: none; } }
      @media (min-width: 1256px) {
        .navbar-new-issue .img-wrapper {
          display: block; } }
      .navbar-new-issue .img-wrapper img {
        width: auto;
        height: 100%; }
  .navbar-dropdown {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    margin-top: 1px;
    padding: 25px;
    background-color: #fff;
    box-shadow: 0 15px 55px -10px rgba(0, 0, 0, 0.4);
    text-align: left;
    z-index: 1;
    cursor: auto; }
    .navbar-dropdown a:hover, .navbar-dropdown a:focus, .navbar-dropdown a:active {
      color: #ff9f00; }
    .navbar-dropdown.dropdown-submenu::before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 12.75px; }
      @media (min-width: 768px) {
        .navbar-dropdown.dropdown-submenu::before {
          height: 25.25px; } }
    .navbar-dropdown.dropdown-search .search-close {
      width: auto;
      text-align: center; }
      .navbar-dropdown.dropdown-search .search-close .close-btn {
        color: #7f7f7f;
        border: 2px solid #7f7f7f;
        width: 50px;
        height: 50px;
        margin: 0 auto 30px;
        border-radius: 25px;
        position: relative; }
        .navbar-dropdown.dropdown-search .search-close .close-btn::before, .navbar-dropdown.dropdown-search .search-close .close-btn::after {
          content: '';
          display: block;
          position: absolute;
          width: 24px;
          height: 2px;
          background-color: #7f7f7f;
          top: 22px;
          left: 11px; }
        .navbar-dropdown.dropdown-search .search-close .close-btn::before {
          transform: rotate(45deg); }
        .navbar-dropdown.dropdown-search .search-close .close-btn::after {
          transform: rotate(-45deg); }
        .navbar-dropdown.dropdown-search .search-close .close-btn:hover {
          cursor: pointer; }
    .navbar-dropdown.dropdown-search .container {
      max-width: 400px; }
    .navbar-dropdown.dropdown-search form > input {
      display: block;
      width: 100%;
      margin-top: 35px;
      padding: 10px 2px;
      border: 0;
      border-bottom: 3px solid #333333;
      font-size: 16px;
      font-weight: 500; }
      .navbar-dropdown.dropdown-search form > input:focus {
        border-bottom-color: #ff9f00; }
    .navbar-dropdown.dropdown-search form > button {
      display: block;
      margin: 40px auto 0 auto;
      padding: 15px 60px;
      color: #fff; }
    .navbar-dropdown.dropdown-new-issue {
      display: block;
      padding: 60px 0 28px;
      background-color: #f5f5f5;
      transform-origin: 50% 0;
      visibility: hidden;
      backface-visibility: hidden;
      transform: scale3d(0, 0, 0.1);
      transition: all .5s ease 0s; }
      .navbar-dropdown.dropdown-new-issue .issue-wrapper {
        max-width: 1060px;
        margin: 0 auto;
        padding: 25px 25px 25px 34px;
        background: #fff;
        box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.2);
        text-align: left; }
        .navbar-dropdown.dropdown-new-issue .issue-wrapper::after {
          display: block;
          clear: both;
          content: ""; }
        .navbar-dropdown.dropdown-new-issue .issue-wrapper .left-col {
          float: left;
          width: 660px; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .left-col::after {
            display: block;
            clear: both;
            content: ""; }
        .navbar-dropdown.dropdown-new-issue .issue-wrapper .title {
          margin: 0 0 24px;
          font-size: 35px;
          color: #212121; }
        .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block {
          position: relative;
          width: 320px;
          height: 162px;
          border: 1px solid #cfcfcf;
          border-radius: 3px;
          padding: 24px 16px 18px;
          font-size: 13px; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block:before {
            display: block;
            content: '';
            position: absolute;
            top: 28px;
            left: 33px; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block.printed {
            float: left; }
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block.printed:before {
              width: 50px;
              height: 46px;
              background: url("/templates/main/images/sprite.png") -56px -38px no-repeat; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block.electronic {
            float: right; }
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block.electronic:before {
              width: 40px;
              height: 52px;
              background: url("/templates/main/images/sprite.png") -13px -38px no-repeat; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block .buy-title {
            padding: 0 0 0 88px;
            font-weight: bold;
            text-transform: uppercase;
            color: #12121a; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block .buy-text {
            color: #848484;
            margin: 10px 0 0;
            padding: 0 0 0 88px;
            line-height: 16px; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block a {
            display: block;
            position: relative;
            margin: 18px 0 0;
            height: 45px;
            background: #ff9f00;
            border-radius: 2px;
            line-height: 45px;
            color: #fff;
            text-decoration: none;
            font-size: 13px;
            font-weight: bold; }
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block a.inactive {
              color: #898989;
              border: 1px solid #898989;
              cursor: inherit;
              background-color: #fff;
              text-align: center; }
              .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block a.inactive span {
                display: none; }
              .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block a.inactive::before {
                display: none; }
              .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block a.inactive:hover {
                background-color: #fff; }
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block a:hover {
              background: #cc7f00; }
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block a span {
              display: inline-block;
              width: 88px;
              vertical-align: middle;
              text-align: center;
              margin: 0 12px 0 0;
              font-size: 20px;
              font-weight: 900; }
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .buy-block a:before {
              display: block;
              position: absolute;
              content: '';
              width: 1px;
              height: 35px;
              background: #cc7f00;
              left: 88px;
              top: 5px; }
        .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription,
        .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo {
          width: 320px;
          height: 80px;
          margin: 13px 0 0;
          background: #f0f0f0;
          border-radius: 3px; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription a,
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo a {
            display: block;
            height: 45px;
            position: relative;
            border: 1px solid #ff9f00;
            border-radius: 3px;
            background: #fff;
            text-align: center;
            text-decoration: none;
            line-height: 43px;
            color: #ff9f00;
            font-size: 13px;
            font-weight: bold; }
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription a.inactive,
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo a.inactive {
              background-color: #fff;
              color: #898989;
              border: 1px solid #898989;
              cursor: inherit; }
              .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription a.inactive:before,
              .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo a.inactive:before {
                display: none; }
              .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription a.inactive:hover,
              .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo a.inactive:hover {
                background-color: #fff;
                color: #898989; }
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription a span,
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo a span {
              position: relative;
              z-index: 1; }
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription a:before,
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo a:before {
              display: block;
              position: absolute;
              content: '';
              top: 0;
              right: 0;
              bottom: 0;
              background: #ff9f00;
              width: 0;
              transition: width .25s ease; }
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription a:hover,
            .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo a:hover {
              color: #fff; }
              .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription a:hover:before,
              .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo a:hover:before {
                width: 100%;
                left: 0;
                right: auto; }
        .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription {
          float: left; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription::after {
            display: block;
            clear: both;
            content: ""; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription .sub-text {
            float: left;
            margin: 33px 0 0 32px;
            font-size: 13px;
            font-weight: bold;
            color: #12121a;
            text-transform: uppercase; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .subscription a {
            width: 152px;
            margin: 17px 19px 0 0;
            float: right; }
        .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo {
          float: right; }
          .navbar-dropdown.dropdown-new-issue .issue-wrapper .see-demo a {
            width: 194px;
            margin: 17px auto 0; }
        .navbar-dropdown.dropdown-new-issue .issue-wrapper .cover {
          float: right;
          display: block;
          width: calc(100% - 680px);
          max-width: 300px;
          margin: -55px 0 0;
          box-shadow: 0 20px 24px 0 rgba(0, 0, 0, 0.2); }
  .navbar .fa, .navbar .dropdown .toggle-bars::before, .dropdown .navbar .toggle-bars::before, .navbar .dropdown1-section .dropdown-active .dropdown-link:after, .dropdown1-section .dropdown-active .navbar .dropdown-link:after, .navbar .pasaz-module .pasaz-content .owl-nav .owl-prev::before, .pasaz-module .pasaz-content .owl-nav .navbar .owl-prev::before, .navbar .pasaz-module .pasaz-content .owl-nav .owl-next::before, .pasaz-module .pasaz-content .owl-nav .navbar .owl-next::before, .navbar .newsletter .select-box:after, .newsletter .navbar .select-box:after {
    color: #7c7c7c;
    font-size: 16px; }
    @media (min-width: 768px) {
      .navbar .fa, .navbar .dropdown .toggle-bars::before, .dropdown .navbar .toggle-bars::before, .navbar .dropdown1-section .dropdown-active .dropdown-link:after, .dropdown1-section .dropdown-active .navbar .dropdown-link:after, .navbar .pasaz-module .pasaz-content .owl-nav .owl-prev::before, .pasaz-module .pasaz-content .owl-nav .navbar .owl-prev::before, .navbar .pasaz-module .pasaz-content .owl-nav .owl-next::before, .pasaz-module .pasaz-content .owl-nav .navbar .owl-next::before, .navbar .newsletter .select-box:after, .newsletter .navbar .select-box:after {
        font-size: 20px; } }
  .navbar-shrinked {
    height: 51px; }
    @media (min-width: 768px) {
      .navbar-shrinked {
        height: 51px; } }
    .navbar-shrinked .navbar-button {
      width: 51px;
      height: 50px; }
      @media (min-width: 768px) {
        .navbar-shrinked .navbar-button {
          width: 51px;
          height: 50px; } }
    .navbar-shrinked .navbar-brand {
      flex: 0 0 121px; }
    .navbar-shrinked .navbar-nav .nav-link {
      font-size: 16px; }
    .navbar-shrinked .navbar-new-issue {
      line-height: 1.2; }
      .navbar-shrinked .navbar-new-issue .img-wrapper img {
        display: none; }
      .navbar-shrinked .navbar-new-issue .badge {
        padding: 3px;
        font-size: 11px; }
      .navbar-shrinked .navbar-new-issue p {
        font-size: 11px; }
    .navbar-shrinked .navbar-dropdown.dropdown-submenu::before {
      height: 12.75px; }
    .navbar-shrinked .fa, .navbar-shrinked .dropdown .toggle-bars::before, .dropdown .navbar-shrinked .toggle-bars::before, .navbar-shrinked .dropdown1-section .dropdown-active .dropdown-link:after, .dropdown1-section .dropdown-active .navbar-shrinked .dropdown-link:after, .navbar-shrinked .pasaz-module .pasaz-content .owl-nav .owl-prev::before, .pasaz-module .pasaz-content .owl-nav .navbar-shrinked .owl-prev::before, .navbar-shrinked .pasaz-module .pasaz-content .owl-nav .owl-next::before, .pasaz-module .pasaz-content .owl-nav .navbar-shrinked .owl-next::before, .navbar-shrinked .newsletter .select-box:after, .newsletter .navbar-shrinked .select-box:after {
      font-size: 16px; }

.left-toggle {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  height: 100vh;
  max-width: 406px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 20px 20px -5px rgba(0, 0, 0, 0.4);
  text-align: left;
  z-index: 2;
  overflow-y: auto; }
  .left-toggle .social {
    margin-bottom: 40px;
    text-align: center; }
    .left-toggle .social a:not(:last-child) {
      margin-right: 40px; }
  .left-toggle > ul#menu-left {
    display: block;
    margin-bottom: 30px;
    padding: 0;
    list-style: none; }
    .left-toggle > ul#menu-left li {
      list-style-type: none; }
    .left-toggle > ul#menu-left > li {
      font-size: 16px;
      font-weight: 700; }
      .left-toggle > ul#menu-left > li > span, .left-toggle > ul#menu-left > li > a {
        display: block;
        padding: 18px 5px;
        border-bottom: 2px solid #d78600; }
      .left-toggle > ul#menu-left > li > a {
        border-bottom: none; }
      .left-toggle > ul#menu-left > li > a:hover {
        background-color: initial; }
      .left-toggle > ul#menu-left > li > ul {
        padding: 0; }
        .left-toggle > ul#menu-left > li > ul li {
          border-bottom: 1px solid #d9d9d9;
          font-size: 16px;
          font-weight: 400; }
          .left-toggle > ul#menu-left > li > ul li a {
            transition: all .25s ease; }
            .left-toggle > ul#menu-left > li > ul li a:link, .left-toggle > ul#menu-left > li > ul li a:visited {
              display: block;
              padding: 20px 20px;
              color: #333; }
            .left-toggle > ul#menu-left > li > ul li a:hover, .left-toggle > ul#menu-left > li > ul li a:focus {
              padding-left: 40px; }
  .left-toggle .new-issue {
    text-align: center; }
    .left-toggle .new-issue .btn-newsletter {
      display: block;
      padding: 25px;
      margin-bottom: 40px;
      background-color: #f2f2f2;
      border: 1px solid #8c8c8c;
      border-radius: 0;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase; }
    .left-toggle .new-issue img {
      margin: 0 auto;
      max-width: 160px;
      height: auto; }
    .left-toggle .new-issue .new-issue-label {
      margin: 0 -30px 0 -30px;
      padding: 10px;
      color: #fff;
      background-color: #ff9f00; }
      .left-toggle .new-issue .new-issue-label p {
        margin: 0; }
      .left-toggle .new-issue .new-issue-label .header {
        color: #202221;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase; }
      .left-toggle .new-issue .new-issue-label .date {
        color: #78641b;
        /* CHANGE to RGBA */
        font-size: 14px; }
      .left-toggle .new-issue .new-issue-label .btn-purchase {
        display: inline-block;
        margin-top: 10px;
        padding: 0;
        color: #fff;
        border: 0;
        border-radius: 0;
        font-size: 12px;
        font-weight: 900; }
  .left-toggle #exit-btn {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer; }

footer {
  position: relative;
  padding: 60px 0 10px;
  background: #303136;
  line-height: 1;
  z-index: 2001;
  /* Tablet-up */
  /* Desktop-up */ }
  footer .title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff; }
  footer .shortcuts {
    order: 2; }
    @media (min-width: 576px) {
      footer .shortcuts {
        order: 1; } }
  footer .our-services {
    order: 1; }
    @media (min-width: 576px) {
      footer .our-services {
        order: 2; } }
  footer .force-float .row {
    display: block;
    flex-wrap: unset; }
  footer .force-float .col-1,
  footer .force-float .col-2,
  footer .force-float .col-3 {
    flex: none;
    max-width: none; }
  footer .nav {
    display: unset;
    flex-wrap: unset; }
  footer .pull-left {
    float: left !important; }
  footer .pull-right {
    float: right !important; }
  footer .mini-title {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 30px;
    color: #fff;
    cursor: pointer;
    display: block;
    float: left;
    clear: both; }
  footer ul.menu-right, footer .ul-list ul {
    display: block;
    list-style: none;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0;
    overflow: hidden; }
    footer ul.menu-right.hide-list, footer .ul-list ul.hide-list {
      display: none; }
    footer ul.menu-right li + li, footer .ul-list ul li + li {
      margin-top: 10px; }
    footer ul.menu-right li a, footer .ul-list ul li a {
      display: inline;
      padding: 0;
      color: #ababab;
      font-size: 14px;
      transition: color .25s ease; }
      footer ul.menu-right li a:hover, footer ul.menu-right li a:focus, footer .ul-list ul li a:hover, footer .ul-list ul li a:focus {
        color: #d4d4d5;
        text-decoration: none;
        background-color: transparent; }
  footer .ul-list ul {
    padding-left: 15px;
    padding-right: 15px; }
    footer .ul-list ul.col-2, footer .ul-list ul.col-3 {
      margin-top: 10px; }
    footer .ul-list ul li span {
      display: block;
      margin: 30px 0;
      font-size: 14px;
      text-transform: uppercase;
      color: #ffffff; }
    footer .ul-list ul li:first-child span {
      margin-top: 0; }
  footer .footer-line {
    border-top: 3px solid #494a4e;
    padding: 31px 0 0;
    margin-top: 85px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
    footer .footer-line__right {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: #6B6969;
      margin-bottom: 10px; }
      @media (max-width: 500px) {
        footer .footer-line__right {
          max-width: 100%;
          flex: 0 0 100%;
          justify-content: center; } }
      footer .footer-line__right-shop {
        margin-right: 30px;
        color: #AAAAAA; }
      footer .footer-line__right-copyrights a {
        color: #AAAAAA; }
  footer .col-xs-12.pull-left {
    margin-top: 30px; }
  footer .social a {
    display: block;
    float: left;
    width: 26px;
    height: 26px;
    margin: 0 0 0 6px;
    border: 1px solid #ababab;
    color: #ababab;
    border-radius: 2px;
    text-align: center;
    font-size: 16px;
    overflow: hidden;
    padding: 4px; }
    footer .social a i {
      color: inherit; }
    footer .social a:hover .fab, footer .social a:focus .fab {
      color: #d4d4d5;
      transform: rotate(360deg); }
    footer .social a .fab {
      color: #ababab;
      line-height: 24px; }
  footer .footer-logo {
    display: block;
    width: 98px;
    height: 36px;
    margin: 0 0 10px;
    background: url("/templates/main/images/sprite.png") -32px 0 no-repeat; }
  @media (min-width: 768px) {
    footer {
      padding: 60px 0 10px; }
      footer .ul-list ul {
        width: 33.33333333%;
        float: left; }
        footer .ul-list ul.col-2, footer .ul-list ul.col-3 {
          margin-top: 30px; }
      footer .footer-line {
        padding: 23px 0 35px; }
      footer .col-xs-12.pull-left {
        margin-top: 0; }
      footer .footer-logo {
        float: left; } }
  @media (min-width: 1025px) {
    footer {
      padding: 72px 0 10px; }
      footer .footer-line {
        margin-top: 105px; } }

.our-sites {
  margin-top: 30px; }
  .our-sites .page-group {
    margin-bottom: 30px; }
  .our-sites .group-title {
    margin-bottom: 30px;
    color: #fff;
    font-size: 14px;
    line-height: 1.4;
    text-transform: uppercase; }
  .our-sites .group-list {
    padding: 0;
    list-style: none;
    list-style-type: none; }
  .our-sites .group-item {
    padding-top: 5px;
    padding-bottom: 5px; }
    .our-sites .group-item:first-child {
      padding-top: 0px; }
  .our-sites .group-link {
    color: #ababab;
    font-size: 14px; }
    .our-sites .group-link:hover {
      color: #d1d1d1; }

.form-contact {
  background-color: #e5e5e5;
  padding: 25px 30px 0; }
  .form-contact form {
    text-align: left; }
    .form-contact form label {
      margin: 11px 0;
      color: #333;
      font-size: 14px;
      font-weight: 300; }
    .form-contact form input {
      height: 43px;
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
      color: #333;
      background-color: #fff;
      border: 1px solid #b7b7b7; }
    .form-contact form .text {
      border: 1px solid #b7b7b7;
      border-radius: 0; }
    .form-contact form textarea {
      height: 175px;
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
      color: #333;
      background-color: #fff; }
    .form-contact form #msg {
      resize: none; }
    .form-contact form .captcha {
      width: 100%;
      border-radius: 3px;
      margin: 0 0 20px; }
    .form-contact form input[type="submit"] {
      height: 72px;
      background: #ffa000;
      color: #fff;
      border-radius: 0;
      width: 100%;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 600;
      border: none;
      transition: all 0.2s ease 0s; }
      .form-contact form input[type="submit"]:hover {
        background: #cc7f00; }
    .form-contact form .input-name {
      font-size: 15px;
      line-height: 21px; }

.badge {
  margin-bottom: 5px;
  padding: 5px;
  font-size: 13px; }
  .badge, .badge:link, .badge:visited {
    color: #ff9f00; }
  .badge.badge-primary {
    color: #fff; }

.btn {
  display: inline-block;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  text-align: center; }
  .btn-wide {
    width: 100%; }
  .btn-primary {
    padding: 25px 30px;
    border: 2px solid #ff9f00;
    text-transform: uppercase; }
    @media (min-width: 576px) {
      .btn-primary {
        padding: 25px 50px; } }
  .btn-secondary {
    padding: 15px 30px;
    color: #fff;
    background-color: #ff9f00;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.2s ease 0s; }
    .btn-secondary:link, .btn-secondary:visited {
      color: #fff; }

.btn-show-more {
  padding: 5px;
  color: #000;
  border: 0;
  font-size: 14px;
  font-weight: 700; }
  .btn-show-more:hover, .btn-show-more:focus, .btn-show-more:active, .btn-show-more:visited {
    color: #000; }
  .btn-show-more-inverse {
    color: #fff; }
    .btn-show-more-inverse:hover, .btn-show-more-inverse:focus, .btn-show-more-inverse:active, .btn-show-more-inverse:visited {
      color: #fff; }

@media (max-width: 767px) {
  .btn-wide-only-sm {
    display: block;
    width: 100%;
    padding: 20px 10px;
    border: 2px solid #ff9f00;
    background-color: #fff;
    text-transform: uppercase; } }

.dropdown {
  font-family: inherit; }
  .dropdown.show .btn-secondary {
    background-color: #000; }
  .dropdown-menu {
    width: 100%;
    margin: 0;
    padding: 20px 75px;
    background-color: #e6e6e6;
    border: none; }
  .dropdown-item {
    padding: 0; }
  .dropdown-link {
    display: block;
    width: 100%;
    padding: 4px 24px;
    clear: both;
    white-space: nowrap;
    border: 0; }

.dropdown1 {
  border: 2px solid #ff9f00;
  background-color: #fff; }
  .dropdown1:hover {
    cursor: pointer; }
  .dropdown1.open {
    border-color: #000; }
    .dropdown1.open .dropdown-options {
      max-height: 500px; }
  .dropdown1 .dropdown-active {
    text-align: center; }
  .dropdown1 .dropdown-options {
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s ease-in 0s; }
  .dropdown1 .dropdown-link {
    padding: 15px 25px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase; }
  .dropdown1-section .dropdown-active .dropdown-link:after {
    margin-left: 20px; }
  .dropdown1-section .dropdown-link {
    padding: 14px 50px; }

.nav {
  display: block;
  list-style: none; }
  .nav .nav-item {
    list-style-type: none; }
  .nav.nav-menu-h .nav-item {
    display: inline-block; }
    .nav.nav-menu-h .nav-item:not(:last-child) {
      padding-right: 10px; }
  .nav.nav-menu-h .nav-link {
    background-color: #f2f2f2;
    padding: 3px 10px; }
    .nav.nav-menu-h .nav-link:hover, .nav.nav-menu-h .nav-link:focus {
      background-color: #ff9f00;
      color: #fff; }
  .nav.nav-menu-v {
    padding: 25px 35px;
    background-color: #dedede;
    font-size: 20px; }
    .nav.nav-menu-v .heading {
      margin: 0 0 5px 0;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase; }
    .nav.nav-menu-v .nav-link {
      padding: 5px 15px;
      transition: padding-left .25s ease-in-out 0s; }
      .nav.nav-menu-v .nav-link:hover, .nav.nav-menu-v .nav-link:focus {
        padding-left: 30px; }
      .nav.nav-menu-v .nav-link.active {
        color: #ff9f00;
        font-weight: 700; }

.alphabet-header {
  background-color: #333;
  padding: 15px; }
  @media (min-width: 1200px) {
    .alphabet-header {
      padding: 15px 30px; } }
  .alphabet-header .letters {
    margin: 0;
    font-size: 20px;
    text-transform: uppercase; }
    .alphabet-header .letters .item:not(:last-child) {
      margin-right: 5px; }
      @media (min-width: 1200px) {
        .alphabet-header .letters .item:not(:last-child) {
          margin-right: 9px; } }
    .alphabet-header .letters .link {
      color: #fff;
      font-weight: 500; }

.alphabet .letter-row {
  padding: 20px 0;
  margin-bottom: 0;
  border-bottom: 2px solid #c5c6c7; }
  .alphabet .letter-row .letter {
    float: left;
    width: 25px;
    font-size: 30px;
    line-height: 1;
    text-transform: uppercase; }
  .alphabet .letter-row .firms {
    font-size: 14px;
    line-height: 1.5;
    text-transform: uppercase; }

.alphabet .firms a {
  display: block; }

.breadcrumb {
  font-size: 13px;
  margin: 0;
  padding: 15px 0;
  list-style: none; }
  .breadcrumb#no-margin ul {
    margin: 0;
    padding: 30px 0; }
  .breadcrumb li {
    display: inline-block; }
    .breadcrumb li.active {
      display: none; }

.pasaz-module.bottom-rows-12, .pasaz-module.right-column {
  max-width: 100%;
  position: relative;
  margin-bottom: 30px; }
  .pasaz-module.bottom-rows-12 .pasaz-heading, .pasaz-module.right-column .pasaz-heading {
    text-align: left;
    position: relative;
    margin-bottom: 30px; }
    .pasaz-module.bottom-rows-12 .pasaz-heading::before, .pasaz-module.right-column .pasaz-heading::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: #ccc;
      z-index: -1; }
    .pasaz-module.bottom-rows-12 .pasaz-heading img, .pasaz-module.right-column .pasaz-heading img {
      display: block;
      background-color: #fff;
      width: 100%;
      display: block;
      margin: 0 auto;
      max-width: 300px; }
      @media (min-width: 576px) {
        .pasaz-module.bottom-rows-12 .pasaz-heading img, .pasaz-module.right-column .pasaz-heading img {
          margin: 0;
          max-width: 320px;
          padding-right: 20px; } }
  .pasaz-module.bottom-rows-12 .pasaz-product, .pasaz-module.right-column .pasaz-product {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 30px; }
    .pasaz-module.bottom-rows-12 .pasaz-product .scale-image, .pasaz-module.right-column .pasaz-product .scale-image {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center; }
      .pasaz-module.bottom-rows-12 .pasaz-product .scale-image .thumbnail, .pasaz-module.right-column .pasaz-product .scale-image .thumbnail {
        align-self: center;
        width: 100%; }
        .pasaz-module.bottom-rows-12 .pasaz-product .scale-image .thumbnail img, .pasaz-module.right-column .pasaz-product .scale-image .thumbnail img {
          height: auto; }
      .pasaz-module.bottom-rows-12 .pasaz-product .scale-image .titles, .pasaz-module.right-column .pasaz-product .scale-image .titles {
        align-self: flex-end;
        font-size: 14px;
        width: 100%; }
        .pasaz-module.bottom-rows-12 .pasaz-product .scale-image .titles .product-title, .pasaz-module.right-column .pasaz-product .scale-image .titles .product-title {
          font-weight: 700;
          display: block; }
        .pasaz-module.bottom-rows-12 .pasaz-product .scale-image .titles .price, .pasaz-module.right-column .pasaz-product .scale-image .titles .price {
          color: #ffa000;
          font-weight: 700; }

.pasaz-module.right-column {
  margin-top: 35px; }
  @media (min-width: 1025px) {
    .pasaz-module.right-column .pasaz-heading img {
      width: 100%;
      padding: 0; } }

.pasaz-module {
  position: relative;
  max-width: 1040px;
  margin: 0 auto; }
  @media (max-width: 1199.98px) {
    .pasaz-module {
      max-width: 850px; } }
  @media (max-width: 1024.98px) {
    .pasaz-module {
      max-width: 630px; } }
  @media (max-width: 1024.98px) {
    .pasaz-module {
      max-width: calc(100% - 140px); } }
  .pasaz-module .owl-nav.disabled {
    display: none; }
  .pasaz-module .pasaz-heading {
    text-align: center; }
    .pasaz-module .pasaz-heading .lead {
      font-family: 'Georgia';
      font-style: italic;
      font-size: 13px;
      text-align: center;
      position: relative; }
      .pasaz-module .pasaz-heading .lead::before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #ccc;
        top: 50%;
        left: 0; }
      .pasaz-module .pasaz-heading .lead div {
        padding: 0 15px;
        background: #fff;
        z-index: 1;
        display: inline-block;
        position: relative; }
  .pasaz-module .pasaz-content .owl-stage-outer {
    overflow: hidden; }
  .pasaz-module .pasaz-content .owl-item {
    display: inline-block; }
    .pasaz-module .pasaz-content .owl-item img {
      height: auto;
      margin: 0 auto; }
    .pasaz-module .pasaz-content .owl-item .product-title {
      color: #333;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase; }
      @media (max-width: 1024.98px) {
        .pasaz-module .pasaz-content .owl-item .product-title {
          font-size: 10px; } }
    .pasaz-module .pasaz-content .owl-item .price {
      color: #ffa000;
      font-size: 18px;
      font-weight: 700;
      padding-left: 5px; }
      @media (max-width: 1024.98px) {
        .pasaz-module .pasaz-content .owl-item .price {
          font-size: 15px; } }
    .pasaz-module .pasaz-content .owl-item .thumbnail {
      position: relative;
      height: 250px;
      display: flex;
      align-items: center;
      margin: 15px 0 20px;
      padding: 0 10px;
      overflow: hidden; }
    .pasaz-module .pasaz-content .owl-item .titles {
      padding-left: 5px; }
  .pasaz-module .pasaz-content .owl-nav .owl-next, .pasaz-module .pasaz-content .owl-nav .owl-prev {
    cursor: pointer;
    position: absolute;
    color: #c0c0c0;
    right: -68px;
    display: block;
    width: 50px;
    height: 50px;
    top: 50%;
    background: #fff;
    border: 1px solid #c0c0c0;
    border-radius: 3px; }
    .pasaz-module .pasaz-content .owl-nav .owl-next::before, .pasaz-module .pasaz-content .owl-nav .owl-prev::before {
      position: absolute;
      font-size: 30px;
      left: 19px;
      top: 7px; }
  .pasaz-module .pasaz-content .owl-nav .owl-prev {
    left: -68px; }
  .pasaz-module .pasaz-content .owl-nav .owl-next {
    right: -68px; }

#kursy .scale-image {
  padding: 0 15px;
  display: block; }

#kursy .thumbnail {
  padding: 0;
  margin: 0 0 20px; }

#kursy .img-blur {
  filter: blur(7px) brightness(62%);
  position: absolute;
  top: -1000px;
  left: -1000px;
  bottom: -1000px;
  right: -1000px;
  margin: auto;
  height: 100%;
  width: auto; }

#kursy .img-main {
  position: relative;
  z-index: 3; }

#kursy .titles {
  padding: 0; }

#kursy .product-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  min-height: 42px;
  vertical-align: middle;
  text-transform: none;
  margin-bottom: 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center; }

#kursy .product-description {
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  margin-bottom: 13px; }

#kursy .owl-item {
  vertical-align: top;
  width: 234px !important; }

#kursy .price {
  color: #1A1B1C;
  font-size: 13px;
  font-weight: 700;
  padding: 0; }

#kursy-wrap {
  margin-top: 30px; }
  #kursy-wrap .logo {
    margin-bottom: 18px;
    font-size: 29px;
    font-weight: 900;
    text-transform: uppercase; }
  #kursy-wrap .lead {
    margin-bottom: 30px;
    font-family: nimbus-sans, sans-serif;
    font-size: 16px; }
    #kursy-wrap .lead div {
      font-style: normal; }
  #kursy-wrap .pasaz-module {
    max-width: none; }
  #kursy-wrap.kursy-lista {
    margin-bottom: 45px; }
    #kursy-wrap.kursy-lista .pasaz-heading {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin-bottom: 15px; }
      #kursy-wrap.kursy-lista .pasaz-heading .logo {
        background-color: #fff;
        margin: 0;
        padding-right: 15px; }
        #kursy-wrap.kursy-lista .pasaz-heading .logo:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          background-color: rgba(26, 27, 28, 0.33);
          height: 1px;
          margin: auto; }

.market-article {
  background-color: #2B2E34;
  border: 1px solid #707070;
  color: #ffffff;
  margin-top: 35px;
  padding: 15px 0 25px;
  text-align: center; }
  .market-article__top {
    padding: 0 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #707070;
    margin-bottom: 30px; }
    .market-article__top img {
      margin-right: 6px; }
    .market-article__top span {
      font-size: 22px;
      font-weight: 900;
      text-transform: uppercase; }
  .market-article__items {
    padding: 0 30px; }
    @media (min-width: 576px) and (max-width: 1024px) {
      .market-article__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
  .market-article__item {
    margin-bottom: 25px;
    display: block;
    color: #ffffff; }
    @media (min-width: 576px) and (max-width: 1024px) {
      .market-article__item {
        max-width: calc((100% / 3) - 15px); } }
    .market-article__item-image {
      max-width: 210px;
      margin: 0 auto;
      margin-bottom: 15px;
      color: #ffffff; }
      .market-article__item-image img {
        box-shadow: 0 0 33px black; }
    .market-article__item-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 2px;
      color: #ffffff; }
    .market-article__item-price {
      font-size: 13px;
      font-weight: 700;
      opacity: 0.5;
      color: #ffffff; }
  .market-article__bottom {
    padding-top: 20px;
    border-top: 1px solid #707070; }
  .market-article__btn {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #ffffff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 140px;
    background: linear-gradient(45deg, #ff4a4a 0%, #4737bc 100%);
    /* w3c */ }
    .market-article__btn:hover, .market-article__btn:link, .market-article__btn:visited {
      color: #ffffff; }

.kiosk {
  padding: 30px 0 0;
  background: #131313;
  /* Tablet-up */ }
  .kiosk .logo-kiosk {
    display: block;
    width: 150px;
    height: 51px;
    margin: 0 auto;
    background: url("/templates/main/images/sprite.png") -130px 0 no-repeat; }
  .kiosk .sky-carousel {
    border: none;
    width: 100%;
    height: 595px;
    background: transparent;
    z-index: 1; }
    .kiosk .sky-carousel .sky-carousel-container li img {
      width: 280px;
      height: auto; }
    .kiosk .sky-carousel .sc-content-wrapper h2 {
      color: #fff; }
    .kiosk .sky-carousel .sc-price {
      position: absolute;
      text-align: center;
      width: 100%;
      bottom: 6%;
      left: 50%;
      z-index: 4999; }
      .kiosk .sky-carousel .sc-price .sc-price-container {
        position: relative;
        left: -50%;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        padding: 0 20px; }
      .kiosk .sky-carousel .sc-price a {
        display: inline-block;
        background: #e62e30;
        padding: 8px 0;
        border-radius: 8px;
        line-height: 36px;
        color: #fff;
        text-decoration: none;
        text-align: center;
        font-weight: bold; }
        .kiosk .sky-carousel .sc-price a::after {
          display: block;
          clear: both;
          content: ""; }
        .kiosk .sky-carousel .sc-price a span {
          display: block;
          float: left;
          height: 36px; }
          .kiosk .sky-carousel .sc-price a span.p {
            width: 84px;
            font-size: 18px; }
          .kiosk .sky-carousel .sc-price a span.t {
            width: 85px;
            border-left: 1px solid #ec5858;
            font-size: 16px; }
    .kiosk .sky-carousel .sc-nav-button {
      width: 69px;
      height: 69px;
      margin-top: -35px;
      border-radius: 50%; }
      .kiosk .sky-carousel .sc-nav-button.sc-prev {
        background: url("/templates/main/images/sprite.png") -130px -54px no-repeat transparent;
        left: 5px;
        transform-origin: 0 50%; }
      .kiosk .sky-carousel .sc-nav-button.sc-next {
        background: url("/templates/main/images/sprite.png") -200px -54px no-repeat transparent;
        right: 5px;
        transform-origin: 100% 50%; }
      .kiosk .sky-carousel .sc-nav-button:focus {
        outline: none; }
  @media (min-width: 768px) {
    .kiosk .sky-carousel .sc-nav-button.sc-prev {
      left: 50%;
      margin-left: -270px; }
    .kiosk .sky-carousel .sc-nav-button.sc-next {
      right: 50%;
      margin-right: -270px; } }

.no-touch .kiosk .sky-carousel .sc-nav-button:hover {
  background-color: transparent; }

.social-icons {
  font-size: 0;
  text-align: center; }
  .social-icons.first {
    width: 60px;
    z-index: 10; }
    .social-icons.first .icon {
      width: 100%;
      border-width: 0 0 1px 0; }
      .social-icons.first .icon.fb:hover {
        color: #3b5998; }
      .social-icons.first .icon.fb:after {
        content: "\f09a"; }
      .social-icons.first .icon.twtr:hover {
        color: #00aced; }
      .social-icons.first .icon.twtr:after {
        content: "\f099"; }
      .social-icons.first .icon.pin:hover {
        color: #ca0034; }
      .social-icons.first .icon.pin:after {
        content: "\f0d2"; }
      .social-icons.first .icon.google:hover {
        color: #dd4b39; }
      .social-icons.first .icon.google:after {
        content: "\f0d5"; }
      .social-icons.first .icon.mail:hover {
        color: #a60505; }
      .social-icons.first .icon.mail:after {
        content: "\f0e0"; }
  .social-icons .icon {
    transition: all 0.2s ease 0s;
    max-width: 60px;
    width: 20%;
    background: none;
    height: 60px;
    display: inline-block;
    font-size: 25px;
    color: #212121;
    line-height: 60px;
    position: relative;
    vertical-align: middle;
    border: solid #212121;
    border-width: 0 1px 0 0; }
    .social-icons .icon:after {
      position: absolute;
      font-family: 'FontAwesome';
      display: inline-block;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px; }
    .social-icons .icon.fb:hover {
      color: #3b5998; }
    .social-icons .icon.fb:after {
      content: "\f09a"; }
    .social-icons .icon.twtr:hover {
      color: #00aced; }
    .social-icons .icon.twtr:after {
      content: "\f099"; }
    .social-icons .icon.pin:hover {
      color: #ca0034; }
    .social-icons .icon.pin:after {
      content: "\f0d2"; }
    .social-icons .icon.google:hover {
      color: #dd4b39; }
    .social-icons .icon.google:after {
      content: "\f0d5"; }
    .social-icons .icon.mail:hover {
      color: #a60505; }
    .social-icons .icon.mail:after {
      content: "\f0e0"; }
    .social-icons .icon:hover {
      /*color: rgba(255, 255, 255, 0.9);*/
      font-size: 35px;
      text-decoration: none; }
    .social-icons .icon:last-child {
      border: none; }
  .social-icons.fixed-icons {
    position: fixed;
    top: 60px;
    left: 50%;
    margin-left: -625px; }

.article-header {
  border-bottom: 1px solid #000;
  font-size: 15px; }
  .article-header h1 {
    font-weight: 400; }
  .article-header .separator {
    padding: 0 15px; }
    .article-header .separator:before {
      content: "|"; }
  .article-header .badge {
    font-size: inherit; }
  .article-header .article-img {
    padding: 0 15px; }
  .article-header p {
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.4; }

.article-main p {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 28px; }

.article .content iframe[name=embedded-content] {
  width: 100%;
  border: none; }

.article-peek.bordered {
  border-bottom: 1px solid #000; }

.article-peek-horizontal {
  border-top: 1px solid #c5c6c7;
  padding: 10px 0; }
  .article-peek-horizontal:not(:first-child) {
    margin-top: 30px; }
  .article-peek-horizontal::after {
    display: block;
    clear: both;
    content: ""; }
  .article-peek-horizontal .image-wrapper {
    max-width: 500px;
    margin: 0 auto 15px; }
    @media (min-width: 768px) {
      .article-peek-horizontal .image-wrapper {
        float: left;
        width: 250px;
        max-width: none;
        margin-bottom: 0; } }
    @media (min-width: 1200px) {
      .article-peek-horizontal .image-wrapper {
        width: 350px; } }
  .article-peek-horizontal .content-wrapper {
    max-width: 500px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .article-peek-horizontal .content-wrapper {
        float: left;
        max-width: none;
        width: calc(100% - 250px);
        padding: 0 15px; } }
    @media (min-width: 1200px) {
      .article-peek-horizontal .content-wrapper {
        width: calc(100% - 350px); } }
    .article-peek-horizontal .content-wrapper .heading {
      font-size: 25px;
      margin-bottom: 15px; }

.article-peek .image-wrapper {
  position: relative; }
  .article-peek .image-wrapper .helper-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .article-peek .image-wrapper.gradient {
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.2)); }
    .article-peek .image-wrapper.gradient img {
      position: relative;
      z-index: -1; }

.article-peek .content-wrapper .category {
  color: #ff9f00; }

.articles-gallery {
  overflow-x: auto;
  overflow-y: hidden; }
  .articles-gallery article {
    position: relative;
    min-width: 225px;
    max-height: 150px;
    background-image: linear-gradient(to bottom, transparent 35%, #212121); }
    @media (min-width: 768px) {
      .articles-gallery article {
        min-width: 350px;
        max-height: 318px; } }
    .articles-gallery article .content-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 15px 10px; }
      @media (min-width: 768px) {
        .articles-gallery article .content-wrapper {
          padding: 30px 20px; } }
      .articles-gallery article .content-wrapper h2, .articles-gallery article .content-wrapper h3 {
        font-size: 16px;
        line-height: 25px; }
        @media (min-width: 768px) {
          .articles-gallery article .content-wrapper h2, .articles-gallery article .content-wrapper h3 {
            font-size: 18px;
            line-height: 24px; } }
        @media (min-width: 1025px) {
          .articles-gallery article .content-wrapper h2, .articles-gallery article .content-wrapper h3 {
            font-size: 25px;
            line-height: 34px; } }
    .articles-gallery article img {
      position: relative;
      width: 100%;
      height: auto;
      min-width: 225px;
      z-index: -1; }
      @media (min-width: 768px) {
        .articles-gallery article img {
          min-width: 350px; } }

.articles-list > *:last-of-type > * {
  margin-bottom: 15px; }

.articles-list .section-recommended-aside h2 {
  margin-bottom: 30px; }
  .articles-list .section-recommended-aside h2 .category-name {
    color: #ff9f00; }

.articles-list > h2 {
  font-size: 26px;
  padding: 15px 2px; }

.articles-list .title-wrapper {
  position: relative; }
  .articles-list .title-wrapper .btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px 20px; }

.articles-list .section-title {
  color: #ff9f00;
  text-align: center; }
  @media (min-width: 768px) {
    .articles-list .section-title {
      text-align: left; } }

.articles-list.recommended article {
  margin-bottom: 30px; }

.articles-list .img-wrapper img {
  width: 100%; }

.articles-list article {
  height: 100%;
  border-bottom: 1px solid #000;
  padding-bottom: 30px;
  margin-bottom: 15px; }
  .articles-list article.recommend {
    position: relative; }
    .articles-list article.recommend img {
      margin-bottom: 10px;
      width: 100%; }
    .articles-list article.recommend:hover h3:after {
      width: 100%; }
    .articles-list article.recommend:hover .image-wrapper img {
      transform: scale3d(1.2, 1.2, 1.2); }
    .articles-list article.recommend .image-wrapper {
      overflow: hidden; }
      .articles-list article.recommend .image-wrapper img {
        margin: 0;
        transition: transform .33s ease-out 0s; }
    .articles-list article.recommend h3:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      width: 0;
      height: 4px;
      background-color: #ff9f00;
      transition: width .5s ease-out 0s; }
    .articles-list article.recommend p {
      margin-top: 10px; }
  .articles-list article.last {
    border-bottom-width: 3px; }
    @media (min-width: 768px) {
      .articles-list article.last {
        border-bottom-width: 1px; } }
    @media (min-width: 1025px) {
      .articles-list article.last {
        border-bottom-width: 3px; } }
  .articles-list article .bg-image {
    width: 100%;
    height: 140px;
    margin-bottom: 15px;
    background-image: linear-gradient(to bottom, transparent, rgba(1, 1, 1, 0.2));
    text-align: center;
    overflow: hidden; }
    @media (min-width: 768px) {
      .articles-list article .bg-image {
        height: 165px; } }
    @media (min-width: 1025px) {
      .articles-list article .bg-image {
        height: 260px; } }
    .articles-list article .bg-image.no-gradient {
      background-image: none; }
    .articles-list article .bg-image.img-centered {
      max-height: 260px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
    .articles-list article .bg-image img {
      width: auto;
      height: inherit; }
  .articles-list article .category {
    padding: 0;
    margin-bottom: 15px;
    color: #ff9f00;
    font-size: 13px;
    margin-bottom: 5px; }
  .articles-list article p {
    font-size: 15px;
    line-height: 1.5;
    text-align: left; }
    .articles-list article p:last-of-type {
      margin-bottom: 0; }
  .articles-list article.product .bg-image {
    background-image: linear-gradient(to bottom, transparent, rgba(1, 1, 1, 0.2)); }
    .articles-list article.product .bg-image img {
      position: relative;
      z-index: -1; }
  .articles-list article.content-horizontal {
    display: flex;
    flex-wrap: wrap; }
    .articles-list article.content-horizontal .img-wrapper {
      flex: 0 0 auto;
      align-self: center; }
    .articles-list article.content-horizontal .content-wrapper {
      flex: 0 1 100%;
      padding-left: 15px; }
  .articles-list article h3 {
    font-size: 22px;
    margin-top: 5px; }

.articles-list.more-from {
  border: none; }
  .articles-list.more-from .section-recommended-aside article {
    border: none; }
    .articles-list.more-from .section-recommended-aside article:hover h3:after {
      width: 100%; }
    .articles-list.more-from .section-recommended-aside article:hover .image-wrapper img {
      transform: scale3d(1.2, 1.2, 1.2); }
    .articles-list.more-from .section-recommended-aside article .image-wrapper {
      overflow: hidden; }
      .articles-list.more-from .section-recommended-aside article .image-wrapper img {
        margin: 0;
        transition: transform .33s ease-out 0s; }
    .articles-list.more-from .section-recommended-aside article h3 {
      margin-top: 5px; }
      .articles-list.more-from .section-recommended-aside article h3:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 4px;
        background-color: #ff9f00;
        transition: width .5s ease-out 0s; }

.articles-list.article-list-linked article {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none; }

@media (min-width: 768px) {
  .articles-list .btn.btn-show-more {
    position: absolute;
    top: 22px;
    right: 15px; } }

@media (min-width: 1025px) {
  .articles-list .btn.btn-show-more {
    position: initial;
    top: unset;
    right: unset; } }

.articles-list .more-linked-items, .articles-list .more-linked {
  padding: 0 15px; }

.articles-list .more-linked-items {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 0; }
  .articles-list .more-linked-items article {
    margin-bottom: 15px; }

.articles-list .more-linked {
  border: 2px solid #ffa000;
  background-color: #fff;
  height: 50px;
  color: #ffa000;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  margin: 0 15px 25px;
  text-transform: uppercase;
  line-height: 46px;
  cursor: pointer;
  position: relative;
  clear: both;
  width: 100%; }
  .articles-list .more-linked span {
    z-index: 10;
    position: relative;
    transition: color 0.5s ease 0s; }
  .articles-list .more-linked:hover span {
    color: #fff; }
  .articles-list .more-linked:hover::after {
    left: 0;
    right: auto;
    width: 100%; }
  .articles-list .more-linked::after {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    background: #ffa000;
    width: 0;
    transition: width 0.5s ease 0s; }

.articles-list.article-small article {
  display: flex;
  flex-wrap: nowrap; }
  .articles-list.article-small article .content-flex {
    flex: 0 1 100%;
    padding-right: 5px; }
  .articles-list.article-small article .img-flex {
    flex: 0 0 auto;
    align-self: center; }
  .articles-list.article-small article h3 {
    margin-bottom: 10px;
    font-size: 18px; }
  .articles-list.article-small article img {
    display: none;
    width: 160px;
    height: auto; }
    .articles-list.article-small article img.img-fluid {
      max-width: none !important; }
    @media (min-width: 768px) {
      .articles-list.article-small article img {
        display: block; } }

@media (max-width: 1199px) {
  .articles-list.articles-list-small-md-down article {
    display: flex;
    flex-wrap: nowrap; }
    .articles-list.articles-list-small-md-down article .img-wrapper {
      flex: 0 0 auto;
      align-self: center; }
      .articles-list.articles-list-small-md-down article .img-wrapper img {
        max-width: 300px; }
    .articles-list.articles-list-small-md-down article .content-wrapper {
      flex: 0 1 100%;
      padding-left: 15px; } }

.featured-article {
  position: relative;
  padding: 12px;
  padding-top: 230px;
  margin-bottom: 30px;
  background: linear-gradient(to bottom, transparent, #191919); }
  @media (min-width: 768px) {
    .featured-article {
      padding: 60px;
      padding-top: 428px; } }
  .featured-article .big-img-link {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .featured-article .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1; }
  .featured-article .section-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 28px;
    background-color: rgba(0, 0, 0, 0.8); }
    .featured-article .section-title h2 {
      color: #ff9f00;
      margin-bottom: 0;
      text-align: center; }
    .featured-article .section-title .btn-show-more {
      position: absolute;
      top: 50%;
      right: 45px;
      transform: translateY(-50%);
      padding: 15px 20px;
      text-transform: uppercase;
      border: 2px solid #ff9f00; }
  .featured-article article {
    max-width: none;
    color: #fff; }
    @media (min-width: 1200px) {
      .featured-article article {
        max-width: 540px; } }
    .featured-article article .badge {
      display: inline-block;
      margin-bottom: 5px;
      padding: 5px;
      font-size: 13px; }
    .featured-article article h2 {
      margin-top: 0;
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom-width: 2px;
      font-size: 30px; }
    .featured-article article p {
      font-size: 15px;
      line-height: 1.5;
      text-align: center; }
      @media (min-width: 768px) {
        .featured-article article p {
          text-align: left; } }
  .featured-article a:link, .featured-article a:visited {
    color: #fff; }

.firm-header .img-wrapper {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto 30px;
  padding: 30px;
  border: 1px solid #7c7c7c; }
  @media (min-width: 1025px) {
    .firm-header .img-wrapper {
      width: 205px;
      height: 205px;
      margin: 0 0 30px; } }
  .firm-header .img-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 145px;
    max-width: 145px;
    transform: translate(-50%, -50%); }

.firm-header .content-wrapper {
  text-align: center; }
  @media (min-width: 768px) {
    .firm-header .content-wrapper {
      text-align: left; } }
  .firm-header .content-wrapper .heading {
    padding: 0;
    margin: 0 0 10px;
    border: none;
    font-size: 25px;
    font-weight: 400; }
    @media (min-width: 1025px) {
      .firm-header .content-wrapper .heading {
        margin-top: 35px;
        font-size: 40px; } }
  .firm-header .content-wrapper p {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 25px; }
    .firm-header .content-wrapper p .description {
      margin-top: 30px; }
  .firm-header .content-wrapper .badge {
    margin: 20px 0 30px;
    font-size: 16px; }

.gallery .title-wrapper {
  position: relative;
  margin-bottom: 20px; }
  .gallery .title-wrapper .btn {
    position: absolute;
    top: 50%;
    right: 0;
    padding: 6px 10px;
    font-size: 12px;
    transform: translateY(-50%); }

.gallery figure {
  overflow-x: hidden; }
  .gallery figure figcaption {
    margin-top: 20px;
    font-size: 13px;
    line-height: 20px; }

.gallery .btn.btn-wide {
  padding: 6px;
  font-size: 12px; }

.list-separated {
  padding: 0;
  font-size: 0;
  list-style: none; }
  .list-separated li {
    display: inline;
    font-size: 16px; }
    .list-separated li:not(:first-child)::before {
      content: "|";
      padding: 0 5px; }

ul#menu-in-list {
  background-color: #e4e4e4;
  padding: 15px 30px;
  margin-bottom: 30px; }
  ul#menu-in-list .category {
    color: #212121;
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;
    margin-bottom: .6rem; }
  ul#menu-in-list li {
    width: 100%;
    padding: 0px 20px;
    font-size: 18px;
    line-height: 35px; }
    ul#menu-in-list li.current a {
      color: #ffa000;
      font-weight: 700; }

.magazine-right {
  text-align: center;
  margin-bottom: 30px;
  max-width: 318px;
  margin: 0 auto; }
  .magazine-right a {
    display: block; }
  .magazine-right .new {
    background-color: #333;
    color: #fff;
    padding: 0 15px 15px;
    display: block; }
    .magazine-right .new .name {
      font-size: 28px;
      margin-bottom: 0; }
      .magazine-right .new .name span {
        font-weight: 700; }
    .magazine-right .new .number {
      font-size: 20px;
      opacity: .4;
      margin-bottom: 0;
      text-transform: capitalize;
      display: block; }
  .magazine-right .buy-now {
    display: block;
    background-color: #fbc02d;
    color: #333;
    padding: 8px 15px;
    font-size: 20px; }
  .magazine-right .article .uk-images img {
    width: 100%;
    height: auto; }

.source {
  margin-bottom: 30px; }
  .source .title {
    text-transform: uppercase;
    color: #333333;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px; }
  .source .box {
    background-color: #dedede;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 15px; }
    .source .box .image {
      width: 50%;
      float: left; }
      .source .box .image img {
        width: 100%; }
    .source .box .text {
      width: 50%;
      float: left;
      padding: 0 15px; }
    .source .box .name {
      font-weight: 700; }
    .source .box .number {
      color: #ffa000;
      margin-bottom: 15px; }
    .source .box .button {
      background-color: #ffa000;
      color: #fff;
      display: block;
      margin: 0 auto;
      font-size: 13px;
      font-weight: 700;
      padding: 10px 15px;
      text-align: center; }

.recommended {
  margin-bottom: 30px; }
  .recommended .title {
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase; }
  .recommended .item {
    display: block;
    position: relative;
    margin-bottom: 3px; }
    .recommended .item::before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
      z-index: 1; }
    .recommended .item .title {
      position: absolute;
      bottom: 15px;
      left: 15px;
      right: 15px;
      color: #fff;
      font-weight: 700;
      font-size: 15px;
      margin: 0;
      text-transform: none;
      z-index: 2; }
    .recommended .item img {
      width: 100%; }

.distributor {
  background-color: #dedede;
  padding: 10px 15px; }
  .distributor .title {
    border-bottom: 2px solid rgba(51, 51, 51, 0.3);
    color: #ffa000;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    padding-bottom: 7px; }
  .distributor .box {
    color: #333333;
    font-size: 17px; }

.gallery-page {
  margin-top: -2px; }
  .gallery-page .page-info {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 6;
    font-size: 16px;
    font-weight: bold; }
    @media (min-width: 768px) {
      .gallery-page .page-info {
        font-size: 26px; } }
    .gallery-page .page-info .close-page {
      display: block;
      width: 60px;
      height: 60px;
      background: #ebebeb;
      position: relative; }
      @media (min-width: 768px) {
        .gallery-page .page-info .close-page {
          width: 100px;
          height: 100px;
          background: rgba(235, 235, 235, 0.5); } }
      .gallery-page .page-info .close-page:before, .gallery-page .page-info .close-page:after {
        display: block;
        content: "";
        width: 50px;
        height: 5px;
        background: #000;
        position: absolute;
        top: 28px;
        left: 9px;
        border-radius: 5px; }
        @media (min-width: 768px) {
          .gallery-page .page-info .close-page:before, .gallery-page .page-info .close-page:after {
            left: 30px;
            top: 49px; } }
      .gallery-page .page-info .close-page:before {
        transform: rotate(-45deg); }
      .gallery-page .page-info .close-page:after {
        transform: rotate(45deg); }
    .gallery-page .page-info .count {
      text-align: center;
      padding: 10px 0 0;
      background: rgba(255, 255, 255, 0.3);
      font-size: 20px; }
  .gallery-page h1 {
    margin-top: 90px;
    font-size: 18px;
    line-height: 1.2;
    padding: 0;
    border: 0; }
    @media (min-width: 768px) {
      .gallery-page h1 {
        margin-top: 36px;
        font-size: 26px;
        padding-right: 160px; } }
    @media (min-width: 1025px) {
      .gallery-page h1 {
        margin-top: 28px;
        font-size: 38px; } }
  .gallery-page .current-image {
    margin-top: 30px;
    margin-bottom: 16px;
    border-bottom: 1px solid #d6d6d6;
    border-top: 1px solid #d6d6d6;
    padding: 40px 0 22px;
    text-align: center; }
    @media (min-width: 768px) {
      .gallery-page .current-image {
        margin-bottom: 28px; } }
    @media (min-width: 1025px) {
      .gallery-page .current-image {
        margin-top: 40px; } }
    .gallery-page .current-image img {
      margin-left: auto;
      margin-right: auto; }
    .gallery-page .current-image .title {
      font-size: 16px;
      margin-top: 24px;
      line-height: 1.2; }
      @media (min-width: 768px) {
        .gallery-page .current-image .title {
          font-size: 16px;
          margin-top: 24px; } }
    .gallery-page .current-image .source {
      font-size: 16px;
      margin-top: 10px;
      font-style: italic;
      line-height: 1.2; }
  .gallery-page .prev-img, .gallery-page .next-img {
    display: block;
    width: 60px;
    height: 60px;
    text-decoration: none; }
    .gallery-page .prev-img::after, .gallery-page .next-img::after {
      display: block;
      clear: both;
      content: ""; }
    @media (min-width: 768px) {
      .gallery-page .prev-img, .gallery-page .next-img {
        position: fixed;
        top: 50%;
        margin-top: -50px;
        width: 100px;
        height: 100px;
        z-index: 5; } }
    .gallery-page .prev-img span, .gallery-page .next-img span {
      display: block;
      position: relative;
      width: 60px;
      height: 60px;
      padding: 0;
      background: #ebebeb;
      color: #000;
      text-indent: 0; }
      .gallery-page .prev-img span:before, .gallery-page .next-img span:before {
        position: absolute;
        left: 23px;
        top: 50%;
        display: block;
        width: 14px;
        height: 26px;
        margin-top: -13px;
        font-family: 'FontAwesome';
        font-size: 60px;
        line-height: 25px;
        text-align: center;
        transition: 0.3s; }
      @media (min-width: 768px) {
        .gallery-page .prev-img span, .gallery-page .next-img span {
          width: 100px;
          height: 100px;
          margin: 0;
          background: rgba(235, 235, 235, 0.5); }
          .gallery-page .prev-img span:before, .gallery-page .prev-img span:after, .gallery-page .next-img span:before, .gallery-page .next-img span:after {
            left: 43px; } }
    .gallery-page .prev-img.disabled, .gallery-page .next-img.disabled {
      opacity: 0.2;
      cursor: default; }
      .gallery-page .prev-img.disabled :before, .gallery-page .next-img.disabled :before {
        font-size: 48px; }
  .gallery-page .prev-img {
    float: left; }
    @media (min-width: 768px) {
      .gallery-page .prev-img {
        left: 0; } }
    .gallery-page .prev-img span {
      float: left; }
      .gallery-page .prev-img span:before {
        content: "\f104"; }
    .gallery-page .prev-img:hover:not(.disabled) span:before {
      left: 18px; }
      @media (min-width: 768px) {
        .gallery-page .prev-img:hover:not(.disabled) span:before {
          left: 38px; } }
  .gallery-page .next-img {
    float: right; }
    @media (min-width: 768px) {
      .gallery-page .next-img {
        right: 0; } }
    .gallery-page .next-img span {
      float: right; }
      .gallery-page .next-img span:before {
        content: "\f105"; }
    .gallery-page .next-img:hover:not(.disabled) span:before {
      left: 28px; }
      @media (min-width: 768px) {
        .gallery-page .next-img:hover:not(.disabled) span:before {
          left: 48px; } }
  .gallery-page .share-buttons {
    margin-top: 28px;
    text-align: center; }
    @media (min-width: 768px) {
      .gallery-page .share-buttons {
        margin-top: 10px;
        text-align: left; } }
    @media (min-width: 1025px) {
      .gallery-page .share-buttons {
        margin-top: 0; } }
  .gallery-page .download-raw {
    margin: 28px auto 0;
    display: block;
    position: relative;
    width: 172px;
    height: 48px;
    box-shadow: inset 0 0 0 1px #d6d6d6;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    color: #333;
    line-height: 48px; }
    @media (min-width: 768px) {
      .gallery-page .download-raw {
        float: right;
        margin-top: 10px; } }
    @media (min-width: 1025px) {
      .gallery-page .download-raw {
        margin-top: 0; } }
    .gallery-page .download-raw .ico {
      display: inline-block;
      position: relative;
      width: 19px;
      height: 24px;
      margin-left: 19px;
      margin-top: -3px;
      vertical-align: middle; }
      .gallery-page .download-raw .ico:before, .gallery-page .download-raw .ico:after {
        display: block;
        position: absolute;
        content: '';
        width: 19px;
        height: 24px;
        background: url("../../images/sprite.png") -165px -42px no-repeat;
        transition: 0.5s; }
      .gallery-page .download-raw .ico:before {
        z-index: 2; }
      .gallery-page .download-raw .ico:after {
        z-index: 1;
        background-position: -165px -69px;
        opacity: 0; }
    .gallery-page .download-raw .bd {
      display: block;
      position: absolute;
      background: #333;
      z-index: 1;
      transition: 0.5s; }
      .gallery-page .download-raw .bd.bt {
        top: 0;
        left: 0;
        height: 1px;
        width: 0; }
      .gallery-page .download-raw .bd.br {
        top: 0;
        right: 0;
        width: 1px;
        height: 0; }
      .gallery-page .download-raw .bd.bb {
        bottom: 0;
        right: 0;
        height: 1px;
        width: 0; }
      .gallery-page .download-raw .bd.bl {
        bottom: 0;
        left: 0;
        width: 1px;
        height: 0; }
    .gallery-page .download-raw:hover .ico:before {
      opacity: 0; }
    .gallery-page .download-raw:hover .ico:after {
      opacity: 1; }
    .gallery-page .download-raw:hover .bd.bt, .gallery-page .download-raw:hover .bd.bb {
      width: 100%; }
    .gallery-page .download-raw:hover .bd.br, .gallery-page .download-raw:hover .bd.bl {
      height: 100%; }
  .gallery-page .image-list {
    margin-top: 20px; }
    @media (min-width: 768px) {
      .gallery-page .image-list {
        margin-top: 38px; } }
    @media (min-width: 1025px) {
      .gallery-page .image-list {
        margin-top: 50px; } }
    .gallery-page .image-list .row > div {
      margin-bottom: 20px;
      padding: 0 10px; }
      .gallery-page .image-list .row > div:nth-of-type(2n+1) {
        clear: left; }
      @media (min-width: 768px) {
        .gallery-page .image-list .row > div:nth-of-type(2n+1) {
          clear: none; }
        .gallery-page .image-list .row > div:nth-of-type(3n+1) {
          clear: left; } }
      @media (min-width: 1025px) {
        .gallery-page .image-list .row > div:nth-of-type(3n+1) {
          clear: none; }
        .gallery-page .image-list .row > div:nth-of-type(4n+1) {
          clear: left; } }
      .gallery-page .image-list .row > div a {
        display: block;
        position: relative; }
        .gallery-page .image-list .row > div a img {
          transition: 0.3s; }
        .gallery-page .image-list .row > div a:after {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          content: '';
          width: 100%;
          height: 100%;
          /*background: rgba(0, 0, 0, 0.2);*/
          transition: 0.3s; }
        .gallery-page .image-list .row > div a:hover img, .gallery-page .image-list .row > div a.active img {
          -webkit-filter: grayscale(100%);
          /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          opacity: 0.8; }

.pagination {
  display: flex;
  text-align: center;
  justify-content: space-between;
  font-weight: 700;
  text-transform: uppercase;
  align-items: center;
  border-bottom: 5px solid #333; }
  .pagination span {
    display: none; }
    @media (min-width: 576px) {
      .pagination span {
        display: inline; } }
  .pagination a, .pagination p {
    margin: 0;
    padding: 0;
    border: none !important; }
  .pagination .inactive, .pagination .active {
    font-size: 13px; }
  .pagination .items {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .pagination .items a, .pagination .items p, .pagination .items .active, .pagination .items .inactive {
      font-size: 20px;
      text-decoration: none; }
    .pagination .items .active, .pagination .items .inactive {
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center; }
      @media (min-width: 768px) {
        .pagination .items .active, .pagination .items .inactive {
          width: 50px;
          height: 50px;
          line-height: 50px; } }
    .pagination .items .inactive, .pagination .items .active:hover {
      background-color: #ffa000;
      color: #fff; }

.search-container {
  background: #fff; }
  .search-container .searched-expression {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    padding: 35px 0; }
  .search-container .gs-no-results-result .gs-snippet {
    border: none;
    background: none;
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #000; }
    .search-container .gs-no-results-result .gs-snippet::before {
      content: '';
      background-image: url("/templates/main/images/search-error-icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      max-width: 100%;
      width: 100%;
      height: 360px;
      display: block;
      margin: 0 auto; }
    .search-container .gs-no-results-result .gs-snippet::after {
      content: 'Niestety nic nie znaleźliśmy dla szukanej frazy';
      display: block;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px; }
  .search-container .gsc-results .gs-result {
    position: relative;
    min-height: 100px; }
    .search-container .gsc-results .gs-result .gsc-thumbnail-inside, .search-container .gsc-results .gs-result .gsc-url-top, .search-container .gsc-results .gs-result .gsc-table-cell-snippet-close {
      display: block;
      width: 85%;
      margin-left: auto;
      padding: 0; }
    .search-container .gsc-results .gs-result .gsc-table-cell-thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 15%;
      height: 100%; }
      .search-container .gsc-results .gs-result .gsc-table-cell-thumbnail .gs-image-box.gs-web-image-box {
        width: 100%;
        height: 100%;
        padding-right: 12px;
        text-align: right; }
        .search-container .gsc-results .gs-result .gsc-table-cell-thumbnail .gs-image-box.gs-web-image-box img.gs-image {
          max-width: 100%;
          max-height: 100%; }
  .search-container .gsc-results .gs-spelling a {
    color: #333333;
    text-decoration: none; }
  .search-container .gsc-results .gs-title {
    height: auto;
    text-decoration: none; }
    .search-container .gsc-results .gs-title * {
      text-decoration: none; }
  .search-container .gsc-results .gsc-cursor-box {
    border-bottom: 2px solid #333;
    font: 700 20px Rubik;
    position: relative;
    text-align: center;
    padding-bottom: 6px; }
    .search-container .gsc-results .gsc-cursor-box .gsc-cursor-page {
      color: #333;
      font-size: 17px;
      display: inline-block;
      padding: 2px 8px;
      border-radius: 15px; }
      .search-container .gsc-results .gsc-cursor-box .gsc-cursor-page:hover {
        background-color: #FF9F00;
        text-decoration: none; }
      .search-container .gsc-results .gsc-cursor-box .gsc-cursor-page.gsc-cursor-current-page {
        background-color: #FF9F00;
        height: 30px;
        min-width: 30px;
        color: #fff;
        margin: 0 5px;
        padding: 5px 10px 0; }
  .search-container .gsc-search-box-tools {
    text-align: center; }
    .search-container .gsc-search-box-tools .gsc-search-box td.gsc-input {
      display: inline-block;
      width: 60%;
      padding: 0; }
      .search-container .gsc-search-box-tools .gsc-search-box td.gsc-input .gsc-input-box {
        width: 100%;
        margin: auto;
        position: relative;
        height: auto;
        border-style: none none solid; }
        .search-container .gsc-search-box-tools .gsc-search-box td.gsc-input .gsc-input-box .gsib_a {
          padding: 0; }
          .search-container .gsc-search-box-tools .gsc-search-box td.gsc-input .gsc-input-box .gsib_a .gsc-input {
            max-width: none;
            padding: 5px 25px 5px 10px !important;
            background-position-x: 5px !important;
            height: 29px !important;
            font-weight: bold;
            font-size: 14px; }
        .search-container .gsc-search-box-tools .gsc-search-box td.gsc-input .gsc-input-box .gsib_b {
          position: absolute;
          right: 0;
          top: 3px;
          width: auto; }
    .search-container .gsc-search-box-tools .gsc-search-box .gsc-search-button {
      display: inline-block;
      width: auto; }
      .search-container .gsc-search-box-tools .gsc-search-box .gsc-search-button .gsc-search-button {
        padding: 8px 15px;
        margin: 0 5px; }

.search .search-container {
  padding: 10px; }
  .search .search-container .gsc-search-box td.gsc-input {
    padding: 15px 0;
    width: 100%; }
  .search .search-container .gsc-search-button-v2 svg {
    display: none; }
  .search .search-container button.gsc-search-button {
    display: block;
    height: 45px;
    background: #FF9F00;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    border: none; }
    .search .search-container button.gsc-search-button:before {
      content: 'Wyszukaj'; }

.section-header {
  position: relative;
  padding: 20px 0;
  border-bottom: 3px solid #000; }
  .section-header .heading {
    margin: 0;
    padding: 0;
    border: none;
    color: #ff9f00;
    font-size: 28px; }
    .section-header .heading-case {
      font-size: 45px;
      color: #000;
      font-weight: 700; }
  .section-header .button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001; }
    .section-header .button .btn {
      padding: 15px 50px; }
  .section-header .dropdown1 {
    display: none;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 1001; }
    @media (min-width: 576px) {
      .section-header .dropdown1 {
        display: block; } }
    .section-header .dropdown1.product {
      top: 33px; }

.toolbar-magazine {
  border-bottom: 1px solid #333; }
  @media (max-width: 1024.98px) {
    .toolbar-magazine {
      display: none; } }
  .toolbar-magazine .toolbar {
    display: flex;
    justify-content: space-between; }
    .toolbar-magazine .toolbar p {
      display: inline-block;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700; }
      .toolbar-magazine .toolbar p.no-image {
        padding-top: 3px; }
      .toolbar-magazine .toolbar p .inactive {
        opacity: 0.4;
        cursor: inherit; }

.newsletter input, .newsletter select {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.newsletter #news-input {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 25px 35px #ccc;
  max-width: 500px;
  height: 50px;
  margin: 20px 0 40px; }

.newsletter #interests-wrap {
  position: relative; }
  .newsletter #interests-wrap .ms-drop.bottom {
    display: none; }
  .newsletter #interests-wrap.open .ms-drop.bottom {
    display: block; }
  .newsletter #interests-wrap .title {
    height: 35px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 16px;
    line-height: 33px;
    text-align: left;
    position: relative; }
  .newsletter #interests-wrap .ms-choice {
    display: none; }
  .newsletter #interests-wrap .ms-no-results {
    display: none; }
  .newsletter #interests-wrap .ms-parent {
    width: 100%;
    position: absolute;
    top: 35px;
    background: #fff;
    z-index: 9;
    margin: 0; }
    .newsletter #interests-wrap .ms-parent ul {
      margin: 0;
      padding: 0;
      list-style: none;
      padding: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 15px; }
      .newsletter #interests-wrap .ms-parent ul li {
        padding-bottom: 5px; }
        .newsletter #interests-wrap .ms-parent ul li:nth-last-child(2) {
          padding-bottom: 0; }
      .newsletter #interests-wrap .ms-parent ul input {
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle; }
      .newsletter #interests-wrap .ms-parent ul span {
        vertical-align: middle; }
      .newsletter #interests-wrap .ms-parent ul label {
        margin: 0; }

.newsletter #newsletter-title {
  max-width: 660px;
  margin: 0 auto 30px;
  text-align: center; }

.newsletter .select-box {
  margin-bottom: 30px;
  position: relative; }
  .newsletter .select-box option {
    margin: 0;
    padding: 0;
    outline: none;
    border: none; }
  .newsletter .select-box:before {
    content: "";
    z-index: 8;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    pointer-events: none;
    background-color: transparent; }
  .newsletter .select-box:after {
    content: "\f078";
    position: absolute;
    font-size: 12px;
    color: #7a7a7a;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    vertical-align: middle;
    pointer-events: none; }
  .newsletter .select-box select {
    height: 35px;
    width: 100%;
    outline: none;
    border: none;
    padding-left: 5px; }

.newsletter .select-wrap {
  padding: 0 15px; }
  @media (min-width: 768px) {
    .newsletter .select-wrap {
      padding: 0; } }
  @media (min-width: 1025px) {
    .newsletter .select-wrap {
      padding: 0 45px; } }

.newsletter #newsletter-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 15px;
  line-height: 22px; }
  .newsletter #newsletter-list li {
    display: inline; }

.newsletter #additional-info {
  text-align: center;
  font-size: 14px;
  margin-top: 30px;
  padding: 0 15px; }
  @media (min-width: 768px) {
    .newsletter #additional-info {
      padding: 0; } }
  @media (min-width: 1025px) {
    .newsletter #additional-info {
      padding: 0 45px; } }

.newsletter #newsletter-box .prezent {
  margin-top: 0;
  margin-bottom: 30px; }

.newsletter #button-submit-wrap {
  padding: 0 30px;
  margin-bottom: 30px; }
  .newsletter #button-submit-wrap .submit {
    margin: 0; }

.newsletter .e-wydania.mt {
  padding-bottom: 60px; }

.newsletter .avt-info {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 30px;
  padding: 0 15px; }
  @media (min-width: 768px) {
    .newsletter .avt-info {
      padding: 0; } }
  @media (min-width: 1025px) {
    .newsletter .avt-info {
      padding: 0 45px; } }
  .newsletter .avt-info .intro-text {
    display: inline; }
  .newsletter .avt-info #button-text {
    cursor: pointer;
    font-weight: 700;
    display: inline; }
    .newsletter .avt-info #button-text.open {
      display: block; }
  .newsletter .avt-info #full-text {
    display: none; }
    .newsletter .avt-info #full-text.open {
      display: inline; }

.newsletter .newsletter-order {
  text-align: center; }

div.newsletter {
  background-color: #fff;
  margin-bottom: 75px; }
  div.newsletter input {
    padding: 0 10px; }
  div.newsletter .top-block {
    padding: 45px 0 30px 0; }
    div.newsletter .top-block .title {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 16px;
      text-align: center; }
  div.newsletter .top-banner {
    background-color: #333;
    color: #fff;
    height: 64px; }
    div.newsletter .top-banner span {
      display: table;
      margin: 0 auto;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 20px;
      padding: 18px 0; }
      div.newsletter .top-banner span img {
        width: 110px; }
  div.newsletter .newsletter-order {
    text-align: center;
    padding: 0 10px;
    margin-top: 30px; }
    div.newsletter .newsletter-order h1 {
      margin-bottom: 15px; }
    div.newsletter .newsletter-order p {
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      margin: 20px 0 25px 0; }
    div.newsletter .newsletter-order .emailbox {
      height: 50px;
      padding: 0;
      margin: 20px 0 40px 0; }
      div.newsletter .newsletter-order .emailbox input {
        width: 100%;
        height: 100%;
        padding: 0 15px;
        border: 1px solid #c5c6c7;
        box-shadow: 0 25px 35px #ccc; }
  div.newsletter .e-wydania img.cover {
    height: 297px;
    display: table;
    margin: 0 auto 30px;
    box-shadow: 0 25px 35px #ccc; }
  div.newsletter .e-wydania .submit {
    border: none;
    margin: 35px 10px 0 10px;
    height: 64px;
    width: 95%;
    background-color: #ff9f00;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: 0 25px 35px #ccc; }
    div.newsletter .e-wydania .submit:hover {
      background-color: #ff9f00; }
  div.newsletter .e-wydania div.regulamin {
    font-size: 10px;
    padding: 0 10px;
    margin: 35px 0 45px 0;
    color: #838383; }
  div.newsletter .e-wydania div.title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;
    padding: 0 10px; }
  div.newsletter .e-wydania div.labels .label {
    font-weight: 400;
    font-size: 14px; }
    div.newsletter .e-wydania div.labels .label strong {
      font-weight: 600; }
  div.newsletter .e-wydania div.labels div.prezent {
    margin: 20px 0;
    /* checkbox aspect */
    /* checked mark aspect */
    /* checked mark aspect changes */ }
    div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked), div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked {
      position: absolute;
      left: -9999px; }
    div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked) + label,
    div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked + label {
      position: relative;
      padding-left: 45px;
      cursor: pointer; }
      @media (max-width: 575.98px) {
        div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked) + label,
        div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked + label {
          padding-right: 15px; } }
    div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked) + label:before,
    div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 34px;
      height: 34px;
      border: 1px solid #b6b6b6;
      background: #fff; }
    div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked) + label:after,
    div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked + label:after {
      content: '\f00c';
      font-family: 'FontAwesome';
      position: absolute;
      top: 10px;
      left: 6px;
      font-size: 24px;
      line-height: 0.8;
      color: #000;
      transition: all .2s; }
    div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0); }
    div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked + label:after {
      opacity: 1;
      transform: scale(1); }
  div.newsletter .e-wydania div.labels div.input-cell {
    padding: 3px 10px; }
    div.newsletter .e-wydania div.labels div.input-cell label {
      width: 100%;
      font-size: 16px; }
    div.newsletter .e-wydania div.labels div.input-cell input {
      float: none;
      height: 33px;
      border: 1px solid #b6b6b6;
      width: 100%; }
  div.newsletter .unsubscribe {
    padding-top: 20px; }
    div.newsletter .unsubscribe .no-padding {
      padding: 0; }
    div.newsletter .unsubscribe .unsubscribe-form input {
      height: 48px;
      border: 1px solid #c5c6c7;
      float: left;
      width: 100%;
      padding: inherit 0; }
    div.newsletter .unsubscribe .unsubscribe-form input[type="submit"] {
      text-transform: uppercase;
      margin-top: 10px; }
    div.newsletter .unsubscribe .unsubscribe-text {
      font-size: 14px;
      margin-bottom: 20px;
      text-align: center; }

@media (min-width: 768px) {
  /* tablet-up */
  div.newsletter div.top-block .title {
    font-size: 30px; }
  div.newsletter .e-wydania div.labels div.input-cell {
    padding: 3px 10px; }
    div.newsletter .e-wydania div.labels div.input-cell label {
      width: auto;
      font-size: 16px; }
    div.newsletter .e-wydania div.labels div.input-cell input {
      float: right;
      height: 33px;
      border: 1px solid #b6b6b6;
      width: 60%; }
  div.newsletter .unsubscribe {
    padding-top: 50px; }
    div.newsletter .unsubscribe .unsubscribe-form {
      margin-left: 8.333%; }
      div.newsletter .unsubscribe .unsubscribe-form input {
        height: 48px;
        border: 1px solid #c5c6c7;
        float: left;
        width: 100%;
        padding: inherit 0; }
      div.newsletter .unsubscribe .unsubscribe-form input[type="submit"] {
        text-transform: uppercase;
        width: 30%;
        margin: 0; }
      div.newsletter .unsubscribe .unsubscribe-form input[type="email"] {
        width: 70%; }
    div.newsletter .unsubscribe .unsubscribe-text {
      text-align: center; } }

@media (min-width: 1025px) {
  /* desktop-up */
  div.newsletter {
    background-color: #fff;
    margin-bottom: 75px; }
    div.newsletter .top-block {
      padding: 35px 0; }
      div.newsletter .top-block .title {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 20px;
        text-align: left; }
    div.newsletter .top-banner {
      background-color: #333;
      color: #fff;
      height: 64px; }
      div.newsletter .top-banner span {
        display: table;
        margin: 0 auto;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 20px;
        padding: 18px 0; }
        div.newsletter .top-banner span img {
          width: 110px; }
    div.newsletter .newsletter-oreder {
      text-align: center; }
      div.newsletter .newsletter-oreder .text-block p {
        font-size: 14px; }
      div.newsletter .newsletter-oreder .emailbox {
        height: 50px;
        padding: 0;
        margin: 20px 0 40px 0; }
        div.newsletter .newsletter-oreder .emailbox input {
          width: 100%;
          height: 100%;
          padding: 0 15px;
          border: 1px solid #c5c6c7;
          box-shadow: 0 25px 35px #ccc; }
    div.newsletter .e-wydania img.cover {
      height: 297px;
      display: table;
      margin: 0 auto;
      box-shadow: 0 25px 35px #ccc;
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 30px; }
    div.newsletter .e-wydania .submit {
      border: none;
      margin-top: 35px;
      height: 64px;
      width: 100%;
      background-color: #ff9f00;
      color: #fff;
      font-weight: 600;
      text-transform: uppercase;
      box-shadow: 0 25px 35px #ccc; }
    div.newsletter .e-wydania div.regulamin {
      font-size: 10px;
      padding: 0 80px;
      margin: 60px 0 45px 0;
      color: #838383; }
    div.newsletter .e-wydania div.title {
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 40px; }
    div.newsletter .e-wydania div.labels .label {
      font-weight: 400;
      font-size: 14px;
      margin: 0;
      padding-right: 45px; }
      div.newsletter .e-wydania div.labels .label strong {
        font-weight: 600; }
    div.newsletter .e-wydania div.labels div.prezent {
      margin: 20px 0;
      /* checkbox aspect */
      /* checked mark aspect */
      /* checked mark aspect changes */ }
      div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked), div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked {
        position: absolute;
        left: -9999px; }
      div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked) + label,
      div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked + label {
        position: relative;
        padding-left: 45px;
        cursor: pointer; }
      div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked) + label:before,
      div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 34px;
        height: 34px;
        border: 1px solid #b6b6b6;
        background: #fff; }
      div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked) + label:after,
      div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked + label:after {
        content: '\f00c';
        font-family: 'FontAwesome';
        position: absolute;
        top: 10px;
        left: 6px;
        font-size: 24px;
        line-height: 0.8;
        color: #000;
        transition: all .2s; }
      div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0); }
      div.newsletter .e-wydania div.labels div.prezent [type="checkbox"].checkbox:checked + label:after {
        opacity: 1;
        transform: scale(1); }
    div.newsletter .e-wydania div.labels div.input-cell {
      padding: 3px 0; }
      div.newsletter .e-wydania div.labels div.input-cell label {
        width: auto; }
      div.newsletter .e-wydania div.labels div.input-cell input {
        float: right;
        height: 33px;
        border: 1px solid #b6b6b6;
        width: 65%; }
    div.newsletter .unsubscribe {
      padding-top: 50px; }
      div.newsletter .unsubscribe .unsubscribe-form {
        margin-left: 0; }
        div.newsletter .unsubscribe .unsubscribe-form input {
          height: 48px;
          border: 1px solid #c5c6c7;
          float: left;
          width: 100%;
          padding: inherit 0; }
        div.newsletter .unsubscribe .unsubscribe-form input[type="submit"] {
          text-transform: uppercase;
          width: 30%;
          margin: 0; }
        div.newsletter .unsubscribe .unsubscribe-form input[type="email"] {
          width: 70%; }
      div.newsletter .unsubscribe .unsubscribe-text {
        text-align: left; } }

#unsubscribe {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  flex-direction: column; }
  @media (min-width: 1200px) {
    #unsubscribe {
      flex-direction: row; } }
  #unsubscribe, #unsubscribe * {
    box-sizing: border-box; }
  #unsubscribe .g-recaptcha {
    margin: 0 0 15px; }
    @media (min-width: 1200px) {
      #unsubscribe .g-recaptcha {
        margin: 0 15px; } }
  #unsubscribe .unsubscribe-text {
    margin: 0 auto 15px; }
    @media (min-width: 1200px) {
      #unsubscribe .unsubscribe-text {
        margin: 0; } }
  #unsubscribe input[type="email"],
  #unsubscribe input[type="submit"] {
    height: 76px;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.3); }
  #unsubscribe input[type="submit"] {
    width: 302px; }
    @media (min-width: 1200px) {
      #unsubscribe input[type="submit"] {
        width: 120px; } }
  #unsubscribe input[type="email"] {
    width: 302px;
    margin: 0 0 15px; }
    @media (min-width: 1200px) {
      #unsubscribe input[type="email"] {
        border-right: none;
        margin: 0; } }

.subscribe-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #E4E4E4; }

.featured-article + .subscribe-wrap {
  margin-top: -30px; }

#subscribe-newsletter {
  padding: 50px 0; }
  #subscribe-newsletter .title {
    font-size: 37px;
    font-weight: 400;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    margin-bottom: 15px;
    padding: 0 15px; }
    #subscribe-newsletter .title span {
      font-weight: 700;
      text-transform: uppercase; }
    #subscribe-newsletter .title i {
      font-size: 36px;
      color: #ff9f00;
      vertical-align: middle;
      margin-left: 15px; }
  #subscribe-newsletter .subtitle {
    text-transform: uppercase;
    text-align: center;
    font-size: 17px;
    margin-bottom: 15px;
    padding: 0 15px; }
  #subscribe-newsletter form {
    font-size: 0;
    text-align: center; }
    #subscribe-newsletter form input {
      height: 55px;
      border: none;
      outline: none;
      text-align: center;
      font-size: 16px;
      padding: 0 5px;
      width: calc(100% - 30px);
      margin-bottom: 15px; }
      @media (min-width: 576px) {
        #subscribe-newsletter form input {
          max-width: 320px;
          margin-bottom: 0; } }
      #subscribe-newsletter form input::placeholder {
        opacity: 1.0;
        color: inherit; }
      #subscribe-newsletter form input::-webkit-input-placeholder {
        opacity: 1.0;
        color: inherit; }
      #subscribe-newsletter form input::-moz-placeholder {
        opacity: 1.0;
        color: inherit; }
      #subscribe-newsletter form input::-ms-input-placeholder {
        opacity: 1.0;
        color: inherit; }
    #subscribe-newsletter form button {
      height: 55px;
      border: none;
      outline: none;
      background-color: #ff9f00;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
      width: calc(100% - 30px); }
      @media (min-width: 576px) {
        #subscribe-newsletter form button {
          max-width: 200px; } }

.ads-bg {
  position: relative;
  padding: 62px 0;
  text-align: center; }
  .ads-bg:after {
    z-index: -1;
    content: "";
    pointer-events: none;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 128, 128, 0) 100%);
    /* ff3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 128, 128, 0)), color-stop(100%, rgba(0, 0, 0, 0.3)));
    /* safari4+,chrome */
    background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 128, 128, 0) 100%);
    /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 128, 128, 0) 100%);
    /* opera 11.10+ */
    background: -ms-linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 128, 128, 0) 100%);
    /* ie10+ */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 128, 128, 0) 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008080', endColorstr='#000000',GradientType=0 );
    /* ie6-9 */ }

.section-main-products .row > div:last-child .article-peek {
  border-bottom-width: 3px; }

.section-presentations {
  margin-bottom: 30px; }
  .section-presentations article h3 {
    font-size: 18px;
    margin-bottom: 15px; }
  .section-presentations article .img-wrapper {
    display: none; }
    @media (min-width: 768px) {
      .section-presentations article .img-wrapper {
        display: block; } }

.section-tutorials {
  margin-bottom: 30px; }
  .section-tutorials article {
    padding-top: 30px; }
  .section-tutorials img {
    margin-bottom: 15px; }

#menu .item-446 {
  display: none; }

.newsletter-sticky {
  text-align: center;
  width: 260px;
  position: fixed;
  left: 50px;
  bottom: -204px;
  font-family: 'Azo Sans', sans-serif;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.75);
  transition: 0.5s all;
  cursor: pointer; }
  .newsletter-sticky.hide {
    opacity: 0;
    transition: 0.5s all; }
  .newsletter-sticky:hover {
    bottom: 0; }
  .newsletter-sticky.small {
    bottom: -298px;
    transition: 0.5s all; }
    .newsletter-sticky.small:hover {
      bottom: 0; }
  .newsletter-sticky .first-wrapper {
    background-color: #FF9F00;
    position: relative; }
  .newsletter-sticky .second-wrapper {
    background-color: #28292A;
    padding-top: 7px; }
  .newsletter-sticky__close {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 3px;
    right: 5px;
    cursor: pointer;
    padding: 1px;
    z-index: 1;
    transition: .5s all; }
    .newsletter-sticky__close:hover {
      transform: rotate(180deg);
      transition: .5s all; }
    .newsletter-sticky__close:hover::after {
      background-color: white; }
    .newsletter-sticky__close:hover::before {
      background-color: white; }
    .newsletter-sticky__close::before, .newsletter-sticky__close::after {
      position: absolute;
      content: '';
      right: 0;
      top: 7px;
      width: 100%;
      height: 1px;
      /* cross thickness */
      background-color: rgba(255, 255, 255, 0.5); }
    .newsletter-sticky__close::before {
      transform: rotate(45deg); }
    .newsletter-sticky__close::after {
      transform: rotate(-45deg); }
  .newsletter-sticky__first-title {
    font-size: 16px;
    line-height: 20px;
    color: #1A1B1C;
    padding-top: 6px;
    cursor: pointer; }
  .newsletter-sticky__second-title {
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    border-bottom: 2px solid #fff;
    padding-bottom: 5px; }
  .newsletter-sticky__icon {
    background-color: #353637;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 27px auto;
    box-shadow: 0px 0px 40px -11px rgba(0, 0, 0, 0.75); }
    .newsletter-sticky__icon svg {
      color: #9A9B9B;
      width: 35px; }
  .newsletter-sticky__description {
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 700;
    padding: 0 20px; }
  .newsletter-sticky__form__wrapper__input {
    background-color: #666666;
    padding: 10px 50px;
    color: #fff;
    font-size: 13px;
    text-align: center;
    border: none;
    margin-bottom: 15px; }
  .newsletter-sticky__form__wrapper__btn {
    background-color: #FF9F00;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    border-radius: 3px;
    border: none;
    padding: 10px 20px;
    margin-bottom: 56px;
    transition: .5s all;
    cursor: pointer; }
    .newsletter-sticky__form__wrapper__btn:hover {
      background-color: #ffa91a;
      transition: .5s all; }

.swiper-main-container .swiper-slide {
  height: 740px; }
  @media (max-width: 550px) {
    .swiper-main-container .swiper-slide {
      height: 540px; } }

.swiper-main-container > .featured-article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: auto;
  padding: 60px;
  z-index: 1;
  display: flex;
  align-items: flex-end; }
  @media (max-width: 550px) {
    .swiper-main-container > .featured-article {
      padding: 20px; }
      .swiper-main-container > .featured-article article p {
        text-align: left; } }

.swiper-main-navigation {
  z-index: 2; }
  .swiper-main-navigation__left {
    width: 60px;
    height: 100%;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer; }
    @media (max-width: 550px) {
      .swiper-main-navigation__left {
        width: 30px; } }
    .swiper-main-navigation__left::before {
      content: '';
      width: 62px;
      top: 50%;
      left: 15px;
      margin-top: -54px;
      right: 0;
      transform-origin: 0 0;
      transform: rotate(45deg);
      background: #fff;
      position: absolute;
      height: 1px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3); }
      @media (max-width: 550px) {
        .swiper-main-navigation__left::before {
          width: 20px;
          left: 5px; } }
    .swiper-main-navigation__left::after {
      content: '';
      width: 62px;
      top: 50%;
      left: 15px;
      margin-top: -54px;
      right: 0;
      transform-origin: 0 100%;
      transform: rotate(-45deg);
      background: #fff;
      position: absolute;
      height: 1px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3); }
      @media (max-width: 550px) {
        .swiper-main-navigation__left::after {
          width: 20px;
          left: 5px; } }
  .swiper-main-navigation__right {
    width: 60px;
    height: 100%;
    z-index: 2;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer; }
    @media (max-width: 550px) {
      .swiper-main-navigation__right {
        width: 30px; } }
    .swiper-main-navigation__right::before {
      content: '';
      width: 62px;
      top: 50%;
      margin-top: -54px;
      right: 15px;
      transform-origin: 100% 0;
      transform: rotate(45deg);
      background: #fff;
      position: absolute;
      height: 1px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3); }
      @media (max-width: 550px) {
        .swiper-main-navigation__right::before {
          width: 20px;
          left: 0;
          right: 5px; } }
    .swiper-main-navigation__right::after {
      content: '';
      width: 62px;
      top: 50%;
      margin-top: -54px;
      right: 15px;
      transform-origin: 100% 0;
      transform: rotate(-45deg);
      background: #fff;
      position: absolute;
      height: 1px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3); }
      @media (max-width: 550px) {
        .swiper-main-navigation__right::after {
          width: 20px;
          left: 0;
          right: 5px; } }

.section-recommended article h3 {
  font-size: 18px;
  margin-bottom: 20px; }

.section-recommended-aside {
  margin-bottom: 60px; }
  .section-recommended-aside h2 {
    margin-bottom: 0;
    padding-bottom: 10px;
    font-size: 20px;
    text-transform: uppercase; }
  .section-recommended-aside article {
    padding-bottom: 30px;
    margin-bottom: 0; }
    .section-recommended-aside article h3 {
      margin: 10px 0 0;
      font-size: 16px; }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.section.gallery {
  margin-bottom: 30px; }
  .section.gallery .title {
    margin: 0;
    padding: 8px 0;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase; }
  .section.gallery .button {
    display: block;
    padding: 10px 20px;
    background: #f2f2f2;
    border: 2px solid #b8b8b8;
    font-size: 13px;
    color: #333;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    position: relative;
    overflow: hidden;
    text-decoration: none; }
    .section.gallery .button:after {
      transition: .3s;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: -100%;
      top: 0;
      background: rgba(0, 0, 0, 0.1); }
    .section.gallery .button:hover:after {
      left: 0; }
  .section.gallery .box {
    margin: 10px auto 0;
    /*max-width: 300px;*/ }
    .section.gallery .box .gallery-item {
      margin: 2px 0 20px;
      display: inline-block;
      text-decoration: none; }
      .section.gallery .box .gallery-item .hover-image {
        position: relative;
        overflow: hidden;
        cursor: pointer; }
        .section.gallery .box .gallery-item .hover-image:after {
          transition: .3s;
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          background: rgba(0, 0, 0, 0.2);
          transform: rotateX(90deg); }
        .section.gallery .box .gallery-item .hover-image:before {
          transition: .3s;
          font-family: 'FontAwesome';
          content: '\f002';
          width: 50px;
          height: 50px;
          font-size: 30px;
          line-height: 50px;
          color: rgba(255, 255, 255, 0.2);
          text-align: center;
          position: absolute;
          left: calc(50% - 25px);
          top: calc(50% - 25px);
          z-index: 2;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.2);
          transform: rotateX(90deg); }
        .section.gallery .box .gallery-item .hover-image:hover:after, .section.gallery .box .gallery-item .hover-image:hover:before {
          transform: rotateX(0); }
      .section.gallery .box .gallery-item .caption {
        font-family: Poppins;
        color: #212121;
        font-size: 13px;
        line-height: 19px;
        text-decoration: none;
        margin-top: 15px; }
        .section.gallery .box .gallery-item .caption .source {
          font-style: italic; }

.section-gallery h2 {
  padding: 11px 0;
  margin-bottom: 0;
  font-size: 17px;
  text-transform: uppercase; }

.complementary,
.complementary .source {
  margin-top: 30px; }

.complementary .price {
  background-color: #f2f2f2;
  padding: 15px;
  margin-bottom: 30px; }
  .complementary .price .title {
    color: #ff9f00;
    border-bottom: 2px solid #b8b8b8;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase; }
  .complementary .price .box {
    font-size: 25px;
    font-weight: 600; }

.complementary .dist-span {
  font-size: 16px; }

.section-linked h2.bordered {
  border-bottom-width: 5px; }

.section-linked article {
  border: none;
  margin-bottom: 0; }
  .section-linked article:not(:first-child) {
    margin-top: 30px; }
  .section-linked article .content-wrapper {
    max-width: 350px; }

.section-article-contact .heading {
  font-size: 43px; }

.section-article-contact .heading-small {
  font-size: 18px; }
  .section-article-contact .heading-small.bordered {
    border-width: 1px; }

.section-article-contact .section-article-editorial .heading-small {
  text-transform: uppercase; }

.section-article-contact .section-article-editorial .employee-job {
  font-weight: 800; }

/* ===== Standard styles for article ===== */
h1 {
  color: #212121;
  font-size: 43px;
  line-height: 43px;
  padding-bottom: 20px;
  border-bottom: 3px solid #000;
  margin: 0; }

.article h1 {
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 700; }

.article .main {
  position: relative;
  height: 100%; }
  .article .main .top .date-container {
    margin-bottom: 15px;
    font-size: 15px; }
    .article .main .top .date-container * {
      display: inline-block;
      padding-right: 15px; }
    .article .main .top .date-container .category {
      color: #ffa000;
      margin-right: 10px; }
    .article .main .top .date-container .right {
      float: right;
      padding: 0; }
      .article .main .top .date-container .right * {
        padding-right: 5px; }
    .article .main .top .date-container .next {
      margin-right: 10px;
      position: relative; }
      .article .main .top .date-container .next::before {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 3px;
        bottom: 3px;
        width: 1px;
        background-color: #dedede; }
  .article .main .social-icons-block {
    display: none;
    position: absolute;
    top: 0;
    left: -80px; }
    @media (min-width: 1300px) {
      .article .main .social-icons-block {
        display: block; } }
    .article .main .social-icons-block.absolute {
      position: absolute;
      top: auto;
      bottom: 0;
      left: -80px; }
    .article .main .social-icons-block.fixed {
      position: fixed;
      top: 50px;
      bottom: auto;
      left: calc(50% - 664px); }
  .article .main img {
    max-width: 100%; }

.article .content .introtext {
  color: #212121;
  font-size: 22px;
  padding-bottom: 5px;
  border-bottom: 1px solid #000;
  margin-bottom: 15px;
  margin-top: 15px; }
  .article .content .introtext a {
    color: #ffa000; }

.article .content .fulltext {
  color: #212121;
  font-size: 18px;
  line-height: 29px;
  /* --- Zdjęcia z ramkami --- */
  /* Zdjęcia po środku z ramką */
  /* Zdjęcia po lewej z ramką */
  /* Zdjęcia po prawej z ramką */
  /* --- Zdjęcia z ramkami End --- */
  /* Zdjęcia po środku bez ramki */
  /* Cytat */
  /* ===== Pomiary ===== */
  /* ===== Pomiary end ===== */ }
  .article .content .fulltext .yellow-text {
    background: #ffbe00;
    font-weight: bold; }
  .article .content .fulltext .red-text {
    background: #da0000;
    font-weight: bold;
    color: #fff; }
  .article .content .fulltext .black-text {
    background: #000;
    font-weight: bold;
    color: #fff; }
  .article .content .fulltext .image-ramka {
    border: 2px solid #ffc301;
    padding: 20px; }
  .article .content .fulltext .image-ramka .title {
    font-weight: bold;
    margin: 14px 0 0; }
  .article .content .fulltext .image-ramka .title + .i-text {
    margin: 0; }
  .article .content .fulltext .image-ramka .i-text {
    margin: 14px 0 0; }
  .article .content .fulltext .image-ramka .i-text > *:last-child {
    margin: 0; }
  .article .content .fulltext .image-ramka.left {
    width: 350px;
    float: left;
    margin: 0 14px 14px 0; }
  .article .content .fulltext .image-ramka.right {
    width: 350px;
    float: right;
    margin: 0 0 14px 14px; }
  .article .content .fulltext .image-no-ramka {
    margin: 0 auto 14px; }
  .article .content .fulltext .image-no-ramka img {
    display: block;
    margin: 0 auto; }
  .article .content .fulltext .image-no-ramka .title {
    margin: 14px 0 0;
    font-weight: bold;
    text-align: center; }
  .article .content .fulltext .daneTechniczne {
    background: #dedede;
    padding: 25px; }
  .article .content .fulltext .measurement {
    border: 2px solid #ffa000;
    margin-bottom: 15px; }
    .article .content .fulltext .measurement .title, .article .content .fulltext .measurement h3 {
      background-color: #ffa000;
      color: #fff;
      font-size: 22px;
      padding: 10px 15px;
      margin: 0 0 15px; }
    .article .content .fulltext .measurement .content {
      padding: 0 15px; }
    .article .content .fulltext .measurement td {
      padding: 0 15px; }
      .article .content .fulltext .measurement td h3 {
        margin: 0 -15px 15px; }
  .article .content .fulltext .information, .article .content .fulltext .informacje {
    border-left: 3px solid #ffa000;
    padding: 15px;
    background-color: #ebebeb;
    margin-bottom: 15px; }
    .article .content .fulltext .information .title, .article .content .fulltext .informacje .title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 30px; }
  .article .content .fulltext .informacje {
    width: 100%; }
    .article .content .fulltext .informacje td {
      padding: 15px; }
    .article .content .fulltext .informacje td > p:first-child {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 30px; }
  .article .content .fulltext a {
    color: #ffa000; }
  .article .content .fulltext .img_caption.right {
    float: right;
    margin-left: 30px; }
    @media (max-width: 767px) {
      .article .content .fulltext .img_caption.right {
        float: none;
        margin-left: auto;
        display: block; } }
  .article .content .fulltext .img_caption.left {
    float: left;
    margin-right: 30px; }
    @media (max-width: 767px) {
      .article .content .fulltext .img_caption.left {
        float: none;
        margin-left: auto;
        display: block; } }
  .article .content .fulltext .img_caption p {
    font-size: 15px;
    line-height: 18px; }
    @media (max-width: 767px) {
      .article .content .fulltext .img_caption p {
        display: block;
        margin: 0 auto 15px; } }
  @media (max-width: 767px) {
    .article .content .fulltext .img_caption a {
      display: block; } }
  @media (max-width: 767px) {
    .article .content .fulltext .img_caption a img {
      display: block;
      margin: 0 auto 15px; } }

.article .content .more {
  text-align: right;
  padding-top: 10px; }

.article img {
  max-width: 100%; }

.article .company-name {
  display: inline-block;
  background-color: #ff9f00;
  color: #fff;
  font-weight: 700;
  padding: 0 5px; }

.article .image-content {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.46; }

#article-main .main-image .main-image__photo-author {
  text-align: right;
  display: block;
  margin-bottom: 5px; }

/* ===== Standard styles for article end ===== */
/* ===== Kontakt ===== */
.other-info {
  width: 54.5%;
  padding: 0 15px;
  float: left;
  font-size: 18px; }
  .other-info .contact-data-title {
    border-bottom: 1px solid rgba(51, 51, 51, 0.5);
    font-weight: 700; }

.editor {
  margin-bottom: 30px;
  font-size: 18px; }
  .editor h2 {
    color: #333333;
    font-size: 45px;
    border-bottom: 3px solid #000;
    padding-bottom: 5px;
    margin-bottom: 15px; }
  .editor .contact-data-title {
    border-bottom: 1px solid rgba(51, 51, 51, 0.5);
    font-weight: 700; }
  .editor .person {
    margin-bottom: 15px; }
    .editor .person .tele-info {
      word-wrap: break-word; }

.clearfix {
  margin-bottom: 30px; }

@media (max-width: 991px) {
  .form-contact, .other-info {
    width: 100%; }
  .form-contact {
    margin-bottom: 15px; }
  .other-info {
    padding: 0; }
    .other-info > div {
      padding: 0; } }

/* ===== Kontakt end ===== */
/* ===== Magazyn ===== */
.article-magazine {
  position: relative; }
  .article-magazine h2 {
    color: #212121;
    font-size: 43px;
    line-height: 43px;
    margin-bottom: 5px; }
  .article-magazine .sub-title {
    color: #212121;
    font-size: 33px;
    line-height: 43px;
    border-bottom: 3px solid #000;
    margin-bottom: 20px;
    padding-bottom: 10px; }
  .article-magazine .box {
    border: 1px solid #f0f0f0;
    background-color: #ffffff;
    padding: 30px 15px;
    position: relative;
    margin-bottom: 30px; }
    .article-magazine .box::before {
      display: block;
      content: '';
      position: absolute;
      top: 30px; }
    .article-magazine .box.magazine::before {
      width: 50px;
      height: 46px;
      background: url(/templates/main/images/sprite.png) -56px -38px no-repeat;
      left: 17px; }
    .article-magazine .box.e-magazine::before {
      width: 40px;
      height: 52px;
      background: url(/templates/main/images/sprite.png) -13px -38px no-repeat;
      left: 18px;
      top: 25px; }
    .article-magazine .box .box-title {
      color: #333333;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 0;
      text-transform: uppercase;
      padding-left: 50px;
      text-align: center; }
    .article-magazine .box .box-subtitle {
      color: #767b7f;
      font-size: 13px;
      line-height: 21px;
      padding-left: 50px;
      margin-bottom: 20px;
      text-align: center; }
    .article-magazine .box .box-button {
      background-color: #ffa000;
      display: flex;
      max-width: 280px;
      margin: 0 auto;
      transition: all 0.2s ease 0s; }
      .article-magazine .box .box-button.inactive {
        background-color: #fff;
        color: #898989;
        border: 1px solid #898989;
        cursor: inherit; }
        .article-magazine .box .box-button.inactive .cost {
          display: none; }
        .article-magazine .box .box-button.inactive * {
          color: #898989; }
        .article-magazine .box .box-button.inactive:hover {
          background-color: #fff;
          color: #898989; }
      .article-magazine .box .box-button:hover {
        background-color: #cc8000; }
      .article-magazine .box .box-button > * {
        display: inline-block;
        margin: 0;
        padding: 10px;
        color: #fff;
        font-weight: 700; }
      .article-magazine .box .box-button .cost {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        font-size: 16px;
        flex-grow: 0; }
      .article-magazine .box .box-button .text {
        padding-top: 12px;
        font-size: 14px;
        flex-grow: 2;
        text-align: center; }
  .article-magazine .subscription {
    background-color: #f0f0f0;
    padding: 15px;
    margin-bottom: 30px; }
    .article-magazine .subscription .content {
      width: 50%;
      color: #12121a;
      font-size: 14px;
      text-align: center;
      float: left;
      margin-top: 15px;
      text-transform: uppercase;
      font-weight: 700; }
    .article-magazine .subscription a {
      display: inline-block;
      width: 50%;
      border: 2px solid #ffa000;
      text-align: center;
      padding: 12px 0;
      color: #ffa000;
      font-weight: 700;
      font-size: 14px;
      position: relative;
      transition: all 0.2s ease 0s;
      z-index: 2;
      /*border-radius: 30px;*/ }
      .article-magazine .subscription a.inactive {
        background-color: #fff;
        color: #898989;
        border: 1px solid #898989;
        cursor: inherit; }
        .article-magazine .subscription a.inactive:before {
          display: none; }
        .article-magazine .subscription a.inactive:hover {
          background-color: #fff;
          color: #898989; }
      .article-magazine .subscription a:hover {
        color: #fff;
        background-color: #ffa000; }
  .article-magazine .demo {
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 14px;
    color: #000;
    text-align: center;
    display: block;
    width: 100%;
    text-transform: uppercase;
    padding: 10px;
    font-weight: 700;
    margin-bottom: 30px;
    /*border-radius: 30px;*/
    position: absolute;
    right: 0;
    top: 0;
    max-width: 300px;
    transition: all 0.2s ease 0s; }
    .article-magazine .demo.static {
      position: static;
      max-width: 100%; }
    .article-magazine .demo:hover {
      background-color: rgba(0, 0, 0, 0.2); }

.in-magazine {
  background-color: #f0f0f0;
  padding: 50px 0 60px; }
  .in-magazine h3 {
    color: #000000;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px; }
  .in-magazine .title {
    color: #000000;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase; }
  .in-magazine .article {
    color: #000000;
    text-align: left; }
  .in-magazine .from-editor {
    color: #000000;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 30px; }
    .in-magazine .from-editor p {
      margin: 0; }

@media (max-width: 991px) {
  .article-magazine h1 {
    margin-bottom: 30px; }
  .article-magazine img {
    width: 100%;
    margin-bottom: 30px; }
  .article-magazine .buy-panel {
    display: none; } }

/* ===== Magazyn end ===== */
.list {
  margin-bottom: 30px; }
  .list-nav {
    width: 100%;
    margin-bottom: 30px;
    padding-top: 30px;
    background-color: #c5c6c7; }
    .list-nav .menu {
      position: relative; }
      .list-nav .menu-item {
        align-content: center;
        height: 46px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        text-align: center; }
        @media (min-width: 576px) {
          .list-nav .menu-item {
            font-size: 16px; } }
        @media (min-width: 768px) {
          .list-nav .menu-item {
            height: 80px;
            font-size: 25px;
            text-align: right; }
            .list-nav .menu-item:first-of-type {
              text-align: left; } }
        @media (min-width: 1025px) {
          .list-nav .menu-item {
            font-size: 33px; }
            .list-nav .menu-item, .list-nav .menu-item:first-of-type {
              text-align: center; } }
        .list-nav .menu-item.active {
          color: #000;
          background-color: #fff;
          border-top: 3px solid #ff9f00; }
          .list-nav .menu-item.active .menu-link {
            color: inherit; }
      .list-nav .menu-link {
        display: inline-block;
        padding: 9px 0;
        color: #fff; }
        @media (min-width: 576px) {
          .list-nav .menu-link {
            padding: 9px; } }
        @media (min-width: 768px) {
          .list-nav .menu-link {
            padding: 20px; } }
        @media (min-width: 1025px) {
          .list-nav .menu-link {
            padding: 15px; } }
      .list-nav .menu::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 54px;
        border-color: #c5c6c7 transparent;
        border-style: solid;
        border-width: 46px 27px 0;
        transform: translateX(-50%); }
        @media (min-width: 768px) {
          .list-nav .menu::after {
            width: 92px;
            border-width: 80px 46px 0; } }
    .list-nav .submenu {
      display: none;
      flex: 0 0 100%;
      justify-content: space-between;
      border-bottom: 1px solid #c5c6c7; }
      @media (min-width: 1025px) {
        .list-nav .submenu {
          display: flex; } }
      .list-nav .submenu-item {
        position: relative;
        font-size: 20px; }
        .list-nav .submenu-item.featured {
          font-weight: 700; }
        .list-nav .submenu-item.active::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 3px;
          background-color: #000; }
      .list-nav .submenu-link {
        display: inline-block;
        padding: 25px 15px; }
      .list-nav .submenu-bg {
        background-color: #fff; }
      .list-nav .submenu-type {
        display: none; }
        @media (min-width: 1025px) {
          .list-nav .submenu-type {
            display: block; } }
      .list-nav .submenu-md-down {
        padding-top: 35px; }
        .list-nav .submenu-md-down .fa, .list-nav .submenu-md-down .dropdown .toggle-bars::before, .dropdown .list-nav .submenu-md-down .toggle-bars::before, .list-nav .submenu-md-down .dropdown1-section .dropdown-active .dropdown-link:after, .dropdown1-section .dropdown-active .list-nav .submenu-md-down .dropdown-link:after, .list-nav .submenu-md-down .pasaz-module .pasaz-content .owl-nav .owl-prev::before, .pasaz-module .pasaz-content .owl-nav .list-nav .submenu-md-down .owl-prev::before, .list-nav .submenu-md-down .pasaz-module .pasaz-content .owl-nav .owl-next::before, .pasaz-module .pasaz-content .owl-nav .list-nav .submenu-md-down .owl-next::before, .list-nav .submenu-md-down .newsletter .select-box:after, .newsletter .list-nav .submenu-md-down .select-box:after {
          margin: 0 10px; }
        .list-nav .submenu-md-down .dropdown {
          margin-bottom: 15px;
          font-size: 14px; }
          @media (min-width: 768px) {
            .list-nav .submenu-md-down .dropdown {
              font-size: 18px; } }
  .list h1 {
    margin: 30px 0 60px;
    padding-bottom: 15px;
    color: #212121;
    border-bottom: 3px solid #212121;
    font-size: 45px;
    line-height: 45px; }
  .list.list-magazine {
    position: relative; }
    .list.list-magazine h1 {
      margin-bottom: 15px; }
    .list.list-magazine .clearfix {
      margin-bottom: 80px; }
    .list.list-magazine .magazine-year {
      width: 120px;
      background-color: #ff9f00;
      max-height: 40px;
      overflow: hidden;
      color: #fff;
      cursor: pointer;
      margin: 20px 0;
      transition: all 0.2s ease 0s;
      display: inline-block;
      z-index: 5;
      font-size: 18px;
      position: absolute; }
      .list.list-magazine .magazine-year .magazine-year-select {
        text-align: center;
        float: left;
        width: 100%; }
        .list.list-magazine .magazine-year .magazine-year-select p {
          padding: 5px;
          margin: 0; }
        .list.list-magazine .magazine-year .magazine-year-select a {
          color: #fff; }
      .list.list-magazine .magazine-year .current {
        float: right;
        width: 65%;
        padding-top: 6px;
        padding-right: 15px;
        text-align: right;
        font-weight: 700;
        margin-bottom: 0;
        padding-bottom: 7px; }
      .list.list-magazine .magazine-year .year {
        padding-top: 9px;
        padding-left: 15px;
        width: 25%;
        display: inline-block;
        position: relative; }
        .list.list-magazine .magazine-year .year::before {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 7px 0 7px;
          border-color: #fff transparent transparent transparent;
          bottom: -14px;
          transition: all 0.2s ease 0s; }
        .list.list-magazine .magazine-year .year::after {
          content: '';
          display: block;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.25);
          width: 1px;
          height: 22px;
          right: -11px; }
      .list.list-magazine .magazine-year.open {
        max-height: 500px;
        background-color: #212121; }
        .list.list-magazine .magazine-year.open .year::before {
          border-width: 0 7px 7px 7px;
          border-color: transparent transparent #fff transparent; }
    .list.list-magazine .demo {
      border: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 14px;
      color: #000;
      text-align: center;
      display: block;
      width: 100%;
      text-transform: uppercase;
      padding: 10px;
      font-weight: 700;
      margin-bottom: 30px;
      /*border-radius: 30px;*/
      position: absolute;
      right: 0;
      top: 0;
      max-width: 300px;
      transition: all 0.2s ease 0s; }
      @media (max-width: 575.98px) {
        .list.list-magazine .demo {
          display: none; } }
      .list.list-magazine .demo:hover {
        background-color: rgba(0, 0, 0, 0.2); }
  .list .articles .image {
    text-align: center; }
    @media (max-width: 1024.98px) {
      .list .articles .image.contraint-1-5 {
        min-height: 236px; } }
    @media (max-width: 1199.98px) {
      .list .articles .image.contraint-1-5 {
        min-height: 207px; } }
    @media (min-width: 1025px) {
      .list .articles .image.contraint-1-5 {
        min-height: 261px; } }
    .list .articles .image .img-wrap {
      overflow: hidden;
      backface-visibility: hidden;
      outline: 0;
      transform: translateZ(0) scale(1, 1);
      position: relative; }
      .list .articles .image .img-wrap .img-back {
        display: none;
        width: 100%;
        height: auto;
        filter: blur(3px) brightness(100%); }
        @media (min-width: 1025px) {
          .list .articles .image .img-wrap .img-back {
            display: block; } }
      .list .articles .image .img-wrap picture {
        display: flex;
        align-items: center;
        justify-content: center; }
        @media (min-width: 1025px) {
          .list .articles .image .img-wrap picture {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0; } }
  .list .articles > div {
    margin-bottom: 30px; }
    .list .articles > div img {
      margin-bottom: 15px; }
    .list .articles > div a {
      height: 100%;
      color: #333;
      border-bottom: 1px solid #000;
      display: block; }
      .list .articles > div a h2 {
        font-size: 22px;
        line-height: 25px;
        font-weight: 700; }
      .list .articles > div a p:last-child {
        font-size: 15px;
        line-height: 22px;
        font-weight: 400; }
  .list .articles.magazine > div a {
    border: none;
    height: auto;
    text-align: center;
    font-weight: 700; }
  .list .articles.magazine > div .magazine-content-link {
    margin-top: 10px;
    font-size: 15px; }
  .list.test .articles > div .article {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 5px;
    float: left;
    margin-bottom: 30px;
    width: 100%; }
  .list.test .articles > div .image, .list.test .articles > div .content {
    float: left;
    width: 50%; }
    .list.test .articles > div .image img, .list.test .articles > div .content img {
      margin: 0; }
  .list.test .articles > div .image {
    padding-right: 15px; }
  .list.test .articles > div .content {
    padding-left: 15px; }
  .list.test .articles > div a {
    border: none; }
  .list.test .articles > div h2 {
    font-size: 22px;
    line-height: 25px;
    display: block; }
  .list.test .articles > div .category {
    font-size: 13px;
    line-height: 22px;
    font-weight: 700;
    display: inline-block; }
    .list.test .articles > div .category > a {
      color: #ffa000; }

@media (max-width: 991px) {
  .col-71, .col-29 {
    width: 100%;
    float: left;
    min-height: 1px;
    padding: 0 15px; } }

@media (min-width: 992px) {
  .col-71, .col-29 {
    float: left;
    min-height: 1px;
    padding: 0 15px; }
  .col-71 {
    width: 71%; }
  .col-29 {
    width: 29%; }
    .col-29 .custom.float.absolute {
      position: absolute;
      top: auto;
      bottom: 0; }
    .col-29 .custom.float.fixed {
      position: fixed;
      top: 50px;
      bottom: auto; } }

.article-firm .row {
  border-bottom: 1px solid #7c7c7c; }

.article-firm .img-wrapper {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto 30px;
  padding: 30px;
  border: 1px solid #7c7c7c; }
  @media (min-width: 1025px) {
    .article-firm .img-wrapper {
      width: 205px;
      height: 205px;
      margin: 0 0 30px; } }
  .article-firm .img-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 145px;
    transform: translate(-50%, -50%); }

.article-firm .content-wrapper {
  text-align: center; }
  @media (min-width: 768px) {
    .article-firm .content-wrapper {
      text-align: left; } }
  .article-firm .content-wrapper h1 {
    padding: 0;
    margin: 0 0 10px;
    border: none;
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase; }
    @media (min-width: 1025px) {
      .article-firm .content-wrapper h1 {
        margin-top: 35px;
        font-size: 40px; } }
  .article-firm .content-wrapper p {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 25px; }
    .article-firm .content-wrapper p .description {
      margin-top: 30px; }
  .article-firm .content-wrapper .description.hidden {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden; }
  .article-firm .content-wrapper #btn-more {
    cursor: pointer; }
  .article-firm .content-wrapper .badge {
    margin: 20px 0 30px;
    font-size: 16px; }

.section-firm-products {
  margin: 70px 0 30px; }
  @media (min-width: 768px) {
    .section-firm-products {
      margin-top: 140px; } }
  .section-firm-products article .img-wrapper {
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.2)); }
    .section-firm-products article .img-wrapper img {
      position: relative;
      z-index: -1; }
  .section-firm-products article .badge {
    margin: 20px 0 0; }
  .section-firm-products article .heading {
    margin: 15px 0;
    font-size: 18px; }

.section-firms-list .firm {
  height: 100%;
  margin-bottom: 30px; }
  .section-firms-list .firm .img-wrapper {
    width: 100%;
    height: 184px;
    padding: 30px 45px;
    border: 1px solid #7c7c7c;
    text-align: center;
    white-space: nowrap; }
    @media (min-width: 576px) {
      .section-firms-list .firm .img-wrapper {
        height: 324px; } }
    @media (min-width: 768px) {
      .section-firms-list .firm .img-wrapper {
        height: 203px; } }
    @media (min-width: 1025px) {
      .section-firms-list .firm .img-wrapper {
        height: 184px; } }
    @media (min-width: 1200px) {
      .section-firms-list .firm .img-wrapper {
        height: 223px; } }
    .section-firms-list .firm .img-wrapper img {
      max-width: 100%;
      max-height: 124px; }
      @media (min-width: 576px) {
        .section-firms-list .firm .img-wrapper img {
          max-height: 264px; } }
      @media (min-width: 768px) {
        .section-firms-list .firm .img-wrapper img {
          max-height: 143px; } }
      @media (min-width: 1025px) {
        .section-firms-list .firm .img-wrapper img {
          max-height: 124px; } }
      @media (min-width: 1200px) {
        .section-firms-list .firm .img-wrapper img {
          max-height: 163px; } }
  .section-firms-list .firm .content-wrapper {
    font-size: 16px;
    text-align: center; }
    .section-firms-list .firm .content-wrapper .heading {
      margin: 25px 0 15px;
      font-size: 25px; }
    .section-firms-list .firm .content-wrapper .categories {
      color: #7c7c7c; }
  .section-firms-list .firm.firm-free .content-wrapper {
    position: relative;
    height: 184px;
    border: 1px solid #7c7c7c; }
    @media (min-width: 576px) {
      .section-firms-list .firm.firm-free .content-wrapper {
        height: 324px; } }
    @media (min-width: 768px) {
      .section-firms-list .firm.firm-free .content-wrapper {
        height: 203px; } }
    @media (min-width: 1025px) {
      .section-firms-list .firm.firm-free .content-wrapper {
        height: 184px; } }
    @media (min-width: 1200px) {
      .section-firms-list .firm.firm-free .content-wrapper {
        height: 223px; } }
    .section-firms-list .firm.firm-free .content-wrapper .inner {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      padding: 0 15px;
      transform: translate(-50%, -50%); }
      .section-firms-list .firm.firm-free .content-wrapper .inner .heading {
        margin-top: 0; }

.section-brands-list {
  margin-top: 30px; }

.section-brand {
  margin-top: 30px; }
  .section-brand .firm-header .heading {
    font-size: 45px;
    font-weight: 700; }
  .section-brand .firm-header .badge {
    margin: 5px 0; }
  .section-brand-tests .heading {
    margin-bottom: 50px;
    font-size: 30px; }

.error_header {
  border-bottom: 1px solid #d7d7d7;
  min-height: 105px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .error_header .logo {
    display: block; }
    .error_header .logo img {
      width: 153px; }

.error_main {
  text-align: center;
  margin-bottom: 100px; }
  .error_main .error_info {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 400; }
  .error_main .error_additional_info {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 400; }
    .error_main .error_additional_info a {
      color: #ffa000; }
  @media (max-width: 767px) {
    .error_main .error_image img {
      width: 100%; } }
  .error_main .error_image .error_code {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 110px;
    font-size: 110px;
    line-height: 110px;
    color: #ffa000; }
  .error_main .search {
    font-size: 0;
    margin-top: 40px; }
    .error_main .search input, .error_main .search .button_search {
      display: inline-block;
      height: 45px;
      vertical-align: top; }
    .error_main .search input {
      font-size: 18px;
      border: none;
      box-shadow: none;
      border: 2px solid black;
      width: 100%; }
      @media (min-width: 768px) {
        .error_main .search input {
          border: none;
          border-bottom: 2px solid black;
          max-width: 400px; } }
    .error_main .search .button_search {
      width: 100%;
      line-height: 45px;
      text-align: center;
      font-size: 13px;
      background-color: #ffa000;
      color: #fff;
      text-transform: uppercase;
      font-weight: 700; }
      @media (max-width: 767px) {
        .error_main .search .button_search {
          margin-top: 40px; } }
      @media (min-width: 768px) {
        .error_main .search .button_search {
          max-width: 165px; } }
