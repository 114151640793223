.kiosk {
    padding: 30px 0 0;
    background: #131313;
    
    .logo-kiosk {
        display: block;
        width: 150px;
        height: 51px;
        margin: 0 auto;
        background: url("/templates/main/images/sprite.png") -130px 0 no-repeat;
    }
    
    .sky-carousel {
        border: none;
        width: 100%;
        height: 595px;
        background: transparent;
        z-index: 1;
        
        .sky-carousel-container li img {
            width: 280px;
            height: auto;
        }
        
        .sc-content-wrapper h2 {
            color: $color-white;
        }
        
        .sc-price {
            position: absolute;
            text-align: center;
            width: 100%;
            bottom: 6%;
            left: 50%;
            z-index: 4999;
            
            .sc-price-container {
                position: relative;
                left: -50%;
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
                padding: 0 20px;
            }
            
            a {
                display: inline-block;
                background: #e62e30;
                padding: 8px 0;
                border-radius: 8px;
                line-height: 36px;
                color: $color-white;
                text-decoration: none;
                text-align: center;
                font-weight: bold;
                
                @include clearfix;
                
                span {
                    display: block;
                    float: left;
                    height: 36px;
                    
                    &.p {
                        width: 84px;
                        font-size: 18px;
                    }
                    
                    &.t {
                        width: 85px;
                        border-left: 1px solid #ec5858;
                        font-size: 16px;
                    }
                }
            }
        }
        
        .sc-nav-button {
            width: 69px;
            height: 69px;
            margin-top: -35px;
            border-radius: 50%;
            
            &.sc-prev {
                background: url('/templates/main/images/sprite.png') -130px -54px no-repeat transparent; 
                left: 5px;
                transform-origin: 0 50%;
            }
            
            &.sc-next {
                background: url('/templates/main/images/sprite.png') -200px -54px no-repeat transparent; 
                right: 5px;
                transform-origin: 100% 50%;
            }
            
            &:focus {
                outline: none;
            }
        }
    }

    /* Tablet-up */
    @include media-breakpoint-up(md) {

        .sky-carousel {
            .sc-nav-button {
                &.sc-prev {
                    left: 50%;
                    margin-left: -270px;
                }

                &.sc-next {
                    right: 50%;
                    margin-right: -270px;
                }
            }
        }
    }
}

.no-touch .kiosk .sky-carousel .sc-nav-button {
    &:hover {
        background-color: transparent;
    }
}