.recommended {
    margin-bottom: 30px;
    
    .title {
        color:  #333333;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
    }
    
    .item {
        display: block;
        position: relative;
        margin-bottom: 3px;
        
        &::before {
            content: '';
            display: block;
            position:absolute;
            height: 100%;
            width: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);
            z-index: 1;
        }
        
        .title {
            position: absolute;
            bottom: 15px;
            left: 15px;
            right: 15px;
            color: #fff;
            font-weight: 700;
            font-size: 15px;
            margin: 0;
            text-transform: none;
            z-index: 2;
        }
        
        img {
            width: 100%;
        }
    }
}
