.container-limited {
    @extend .container-fluid;
    max-width: 1200px;
}

// Backward compatibility
.container-fluid-limited {
    @extend .container-limited;
}

.col-29-f,
.col-71-f {
    position: relative;
    flex: 0 0 71%;
    width: 100%;
    min-height: 1px;
    max-width: 71%;
    padding-left: 15px;
    padding-right: 15px;
}

.col-29-f {
    flex: 0 0 29%;
    max-width: 29%;
}

.col-71-f {
    flex: 0 0 71%;
    max-width: 71%;
}
