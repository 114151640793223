.pasaz-module {
    position: relative;
    max-width: 1040px;
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
        max-width: 850px;
    }

    @include media-breakpoint-down(md) {
        max-width: 630px;
    }

    @include media-breakpoint-down(md) {
        max-width: calc(100% - 140px);
    }
    .owl-nav.disabled {
        display: none;
    }
    .pasaz-heading {
        text-align: center;

        .lead {
            font-family: 'Georgia';
            font-style: italic;
            font-size: 13px;
            text-align: center;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                background-color: #ccc;
                top: 50%;
                left: 0;
            }

            div {
                padding: 0 15px;
                background: #fff;
                z-index: 1;
                display: inline-block;
                position: relative;
            }
        }
    }
    .pasaz-content {

        .owl-stage-outer {
            overflow: hidden;
        }

        .owl-item {
            display: inline-block;

            img {
                height: auto;
                margin: 0 auto;
            }

            .product-title {
                color: #333;
                font-size: 13px;
                font-weight: 700;
                text-transform: uppercase;

                @include media-breakpoint-down(md) {
                    font-size: 10px;
                }
            }

            .price {
                color: #ffa000;
                font-size: 18px;
                font-weight: 700;
                padding-left: 5px;

                @include media-breakpoint-down(md) {
                    font-size: 15px;
                }
            }

            .thumbnail {
                position: relative;
                height: 250px;
                display: flex;
                align-items: center;
                margin: 15px 0 20px;
                padding: 0 10px;
                overflow: hidden;
            }

            .titles {
                padding-left: 5px;
            }
        }

        .owl-nav {

            .owl-next, .owl-prev {
                cursor: pointer;
                position: absolute;
                color: #c0c0c0;
                right: -68px;
                display: block;
                width: 50px;
                height: 50px;
                top: 50%;
                background: #fff;
                border: 1px solid #c0c0c0;
                border-radius: 3px;

                &::before {
                    position: absolute;
                    font-size: 30px;
                    left: 19px;
                    top: 7px;
                }
            }

            .owl-prev {
                left: -68px;

                &::before {
                    @extend .fa;
                    @extend .fa-angle-left;
                }
            }

            .owl-next {
                right: -68px;

                &::before {
                    @extend .fa;
                    @extend .fa-angle-right;
                }
            }
        }
    }
}
#kursy {
    .scale-image {
        padding: 0 15px;
        display: block;
    }
    .thumbnail {
        padding: 0;
        margin: 0 0 20px;
    }
    .img-blur {
        filter: blur(7px) brightness(62%);
        position: absolute;
        top: -1000px;
        left: -1000px;
        bottom: -1000px;
        right: -1000px;
        margin: auto;
        height: 100%;
        width: auto;
    }
    .img-main {
        position: relative;
        z-index: 3;
    }
    .titles {
        padding: 0;
    }
    .product-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        min-height: 42px;
        vertical-align: middle;
        text-transform: none;
        margin-bottom: 9px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
    .product-description {
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        margin-bottom: 13px;
    }
    .owl-item {
        vertical-align: top;
        width: 234px !important;
    }
    .price {
        color: #1A1B1C;
        font-size: 13px;
        font-weight: 700;
        padding: 0;
    }
}
#kursy-wrap {
    margin-top: 30px;
    .logo {
        margin-bottom: 18px;
        font-size: 29px;
        font-weight: 900;
        text-transform: uppercase;
    }
    .lead {
        margin-bottom: 30px;
        font-family: nimbus-sans, sans-serif;
        font-size: 16px;
        div {
            font-style: normal;
        }
    }
    .pasaz-module {
        max-width: none;
    }
    &.kursy-lista {
        margin-bottom: 45px;
        .pasaz-heading {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            margin-bottom: 15px;
            .logo {
                background-color: #fff;
                margin: 0;
                padding-right: 15px;
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    background-color: rgba(26,27,28,0.33);
                    height: 1px;
                    margin: auto;
                }
            }
        }
    }
}
.market-article {
    background-color: #2B2E34;
    border: 1px solid #707070;
    color: #ffffff;
    margin-top: 35px;
    padding: 15px 0 25px;
    text-align: center;
    // @media(max-width: $breakpoint-mobile-max) {
    //     max-width: 320px;
    //     margin: 35px auto 0;
    // }
    &__top {
        padding: 0 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid #707070;
        margin-bottom: 30px;
        img {
            margin-right: 6px;
        }
        span {
            font-size: 22px;
            font-weight: 900;
            text-transform: uppercase;
        }
    }
    &__items {
        padding: 0 30px;
        @media(min-width: $breakpoint-mobile-min) and (max-width: $breakpoint-tablet-max) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    &__item {
        margin-bottom: 25px;
        display: block;
        color: #ffffff;
        @media(min-width: $breakpoint-mobile-min) and (max-width: $breakpoint-tablet-max) {
            max-width: calc((100% / 3) - 15px);
        }
        &-image {
            max-width: 210px;
            margin: 0 auto;
            margin-bottom: 15px;
            color: #ffffff;
            img {
                box-shadow: 0 0 33px rgb(0,0,0);
            }
        }
        &-title {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 2px;
            color: #ffffff;
        }
        &-price {
            font-size: 13px;
            font-weight: 700;
            opacity: 0.5;
            color: #ffffff;
        }
    }
    &__bottom {
        padding-top: 20px;
        border-top: 1px solid #707070;
    }
    &__btn {
        width: 100%;
        max-width: 240px;
        margin: 0 auto;
        height: 63px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        color: #ffffff;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 140px;
        background: linear-gradient(45deg, rgba(255,74,74,1) 0%, rgba(71,55,188,1) 100%); /* w3c */
        &:hover, &:link, &:visited {
            color: #ffffff;
        }
    }
}
