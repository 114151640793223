.u-show { display: block !important; }
.u-hide { display: none !important; }

.u-position-static      { position: static !important; }
.u-position-fixed       { position: fixed !important; }

.u-margin-none          { margin: 0 !important; }

.u-margin-top-none      { margin-top: 0 !important; }
.u-margin-top-small     { margin-top: 15px !important; }
.u-margin-top-medium    { margin-top: 30px !important; }
.u-margin-top-big       { margin-top: 45px !important; }
.u-margin-top-huge      { margin-top: 166px !important; } //XXX: due to navbar margin issue

.u-margin-bottom-none   { margin-bottom: 0 !important; }
.u-margin-bottom-small  { margin-bottom: 15px !important; }
.u-margin-bottom-medium { margin-bottom: 30px !important; }
.u-margin-bottom-big    { margin-bottom: 45px !important; }
.u-margin-bottom-huge   { margin-bottom: 60px !important; }

.u-padding-none          { padding: 0 !important; }
.u-padding-left-none     { padding-left: 0 !important; }
.u-padding-top-none      { padding-top: 0 !important; }
.u-padding-top-small     { padding-top: 15px !important; }
.u-padding-bottom-medium { padding-bottom: 30px !important; }

.u-color-primary   { color: $color-primary-light; }
.u-color-secondary { color: $color-secondary-light; }

.u-center-text { text-align: center !important; }

.u-sticky {
    position: fixed;

    @if $navbar-enabled {
        top: 0 + map-get($navbar-shrinked-full-height, xs);
    } @else {
        top: 0;
    }
}

.u-section-margin {
    margin-top: 50px;
}

.u-bg-parallax {
    background-attachment: fixed;
    background-size: cover;
}

.u-bg {
    background-color: rgba(0, 255, 255, 0.1);

    &-1 {
        background-color: rgba(218,112,214, 0.1);
    }
}

.u-advertisement {
    display: flex;
    margin: 0 auto 30px auto;
    border: 2px solid #ccc;
    
    div {
        display: 0 0 auto;
        width: 100%;
        align-self: center;
        color: #ccc;
        font-size: 36px;
        text-align: center;
    }
}

.img-aligner {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

picture.lazy {
    display: block;
}