.btn {
    display: inline-block;
    color: $color-black;
    font-size: 14px;
    font-weight: 700;
    text-align: center;

    &-wide { width: 100%; }

    &-primary {
        padding: 25px 30px;
        border: 2px solid $color-primary-light;
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
            padding: 25px 50px;
        }
    }

    &-secondary {
        padding: 15px 30px;
        color: $color-white;
        background-color: $color-primary-light;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;

        transition: $transition-base;

        &:link, &:visited { color: $color-white; }
    }
}

.btn-show-more {
    padding: 5px;

    color: $color-black;
    border: 0;

    font-size: 14px;
    font-weight: 700;

    &:hover, &:focus, &:active, &:visited {
        color: $color-black;
    }

    &-inverse { 
        color: $color-white;

        &:hover, &:focus, &:active, &:visited {
            color: $color-white;
        }
    }
}

@media (max-width: $breakpoint-mobile-max) { //XXX: Deprecated
    .btn-wide-only-sm {
        display: block;
        width: 100%;
        padding: 20px 10px;

        border: 2px solid $color-primary-light;
        background-color: $color-white;

        text-transform: uppercase;
    }
}