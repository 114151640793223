.pasaz-module.bottom-rows-12, .pasaz-module.right-column {
    max-width: 100%;
    position: relative;
    margin-bottom: 30px;

    .pasaz-heading {
        text-align: left;
        position: relative;
        margin-bottom: 30px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background: #ccc;
            z-index: -1;
        }

        img {
            display: block;
            background-color: $color-white;
            width: 100%;
            display: block;
            margin: 0 auto;
            max-width: 300px;
            @include media-breakpoint-up(sm) {
                margin: 0;
                max-width: 320px;
                padding-right: 20px;
            }
        }
    }

    .pasaz-product {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin-bottom: 30px;


        .scale-image {
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;

            .thumbnail {
                align-self: center;
                width: 100%;

                img {
                    height: auto;
                }
            }

            .titles {
                align-self: flex-end;
                font-size: 14px;
                width: 100%;

                .product-title {
                    font-weight: 700;
                    display: block;
                }

                .price {
                    color: #ffa000;
                    font-weight: 700;
                }
            }
        }
    }
}

.pasaz-module.right-column {
    margin-top: 35px;
    .pasaz-heading {
        img {
            @include media-breakpoint-up(lg) {
                width: 100%;
                padding: 0;
            }
        }
    }
}
