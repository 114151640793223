.article-peek {

    &.bordered { border-bottom: 1px solid $color-black; }

    &-horizontal {
        border-top: 1px solid $color-grey-light-1;
        padding: 10px 0;
        
        &:not(:first-child) { margin-top: 30px; }
        
        @include clearfix;
        
        .image-wrapper {
            max-width: 500px;
            margin: 0 auto 15px;

            @include media-breakpoint-up(md) {
                float: left;
                width: 250px;
                max-width: none;
                margin-bottom: 0;
            }

            @include media-breakpoint-up(xl) { width: 350px; }
        }
    
        .content-wrapper {
            max-width: 500px;
            margin: 0 auto;

            @include media-breakpoint-up(md) {
                float: left;
                max-width: none;
                width: calc(100% - 250px);
                padding: 0 15px;
            }

            @include media-breakpoint-up(xl) { width: calc(100% - 350px); }

            .heading {
                font-size: 25px;
                margin-bottom: 15px;
            }
        }
    }

    .image-wrapper {
        position: relative;

        .helper-link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &.gradient { 
            background-image: linear-gradient(to bottom, transparent, rgba($color-black, 0.2));

            img {
                position: relative;
                z-index: -1;
            }
        }
    }

    .content-wrapper {
        .category { color: $color-primary-light; }
    }
}